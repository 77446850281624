import React, { useMemo } from "react"
import { Chart } from "react-charts"
import useWindowSize from '../../hooks/windowsize.hook'

export default function Char({ data }) {

  const windowSize = useWindowSize()

  const series = useMemo(
    () => ({
      type: "bar"
    }),
    []
  )
  const tooltip = React.useMemo(
    () => ({
      align: "auto",
      anchor: "center"
    }),
    []
  )

  const axes = useMemo(
    () => [
      { primary: true, type: "ordinal", position: "bottom" },
      { position: "left", type: "linear", stacked: false, show: true }
    ],
    []
  )

  return (
    <>
      <div className="char-body">
        {windowSize ? <div style={{
          height: 560, width: `${windowSize.width >= 320 && windowSize.width < 410 ? (data[0].data.length < 4 ? 100 : data[0].data.length * 100 / 3) :
              windowSize.width >= 410 && windowSize.width <= 640 ? (data[0].data.length < 6 ? 100 : data[0].data.length * 100 / 5) :
                windowSize.width > 640 && windowSize.width <= 1100 ? (data[0].data.length < 8 ? 100 : data[0].data.length * 100 / 9) :
                  windowSize.width > 1100 ? (data[0].data.length < 12 ? 100 : data[0].data.length * 10) : 100}%`
        }}>
          <Chart data={data} series={series} axes={axes} tooltip={tooltip} />
        </div> : null}
      </div>
    </>
  );
}
