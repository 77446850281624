import React, { useState, useEffect, useContext } from 'react'
import { LanguageContext } from '../context/LangContext'

import './Counter.css'

export const Counter = ({counter = 0, onChange, min, max, message, startTransaction}) => {

    const [count, setCount] = useState(Number(counter))
    const [l] = useContext(LanguageContext)
    const inputHandler = (count) => {

        if (count < min) {
            message(`${l.errors.counterMin} ${min}`)
        } else if (count > max ) {
            message(`${l.errors.counterMax} ${max}`)
            onChange(max)
        } else {
            console.log('set')
            onChange(count)
        }
    }

    const onKeyDown = (e) => {
        if(e.key === 'Enter') {
            console.log(counter);
            console.log(onChange);
            console.log(min);
            console.log(max);
            console.log(message);
            console.log(startTransaction);
            startTransaction();
        }
    }

    const increment = () => {
        // console.log(count)
        if (count >= max) {

            message(`${l.errors.counterMax} ${max}`)
            setCount(max)
        }
        else if(+parseInt(count) === +parseInt(max)) {
            onChange(+(Number(count) + 0.01).toFixed(2));
        } else {
            onChange(Number(count) + 1)
        }
    }

    const decrement = () => {
        if (count <= min) {
            message(`${l.errors.counterMin} ${min}`)
        }
        else if(+parseInt(count) === +parseInt(max) && count > +parseInt(count)) {
            onChange(+(Number(count) - 0.01).toFixed(2));
        } else {
            onChange(Number(count) - 1)
        }
    }

    useEffect(() => {
        setCount(counter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter])

    return (
        <div className="counter-body">
            <div className="counter-input-area">
                <input
                    className="counter-input"
                    type="number"
                    onChange={e=>inputHandler(e.target.value)}
                    value={count}
                    onKeyDown={onKeyDown}
                />
            </div>
            <div className="counter-button-area">
                <button className="counter-btn" onClick={increment}>+</button>
                <button className="counter-btn" onClick={decrement}>&#8210;</button>
            </div>
        </div>
    )
}
