import React, { useEffect, useState, useContext } from 'react';
import './MapOptions.css'
import { LanguageContext } from '../../context/LangContext'
import work from './icons/work.png'
import alert from './icons/alert.png'
import connected from './icons/connected.png'
import reserved from './icons/reserved.png'
import busy from './icons/busy.png'
import btn from './arrowbtn.png'

const MapOptions = ({ options, setOptions }) => {

    const [l] = useContext(LanguageContext)

    const [show, setShow] = useState(false)

    const [checkbox, setCheckbox] = useState({
        statusFree: false,
        Type2: true,
        J1772: true
    })

    useEffect(() => {
        if (checkbox.Type2) {
            setOptions({
                ...options,
                typeType2: "Type2"
            })
        } else {
            setOptions({
                ...options,
                typeType2: null
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkbox.Type2])

    useEffect(() => {
        if (checkbox.statusFree) {
            setOptions({
                ...options,
                free: 0
            })
        } else {
            setOptions({
                ...options,
                free: undefined
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkbox.statusFree])

    useEffect(() => {
        if (checkbox.J1772) {
            setOptions({
                ...options,
                typeJ1772: "J1772"
            })
        } else {
            setOptions({
                ...options,
                typeJ1772: null
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkbox.J1772])

    const HandleChange = (e) => {
        setCheckbox({ ...checkbox, [e.target.name]: e.target.checked })
    }
    const HandleChangeo = (e) => {
        setOptions({ ...options, [e.target.name]: e.target.checked })
    }

    return (
        <div className='map-options-blocks'>
            <button className="mo-btn" onClick={() => setShow(!show)} style={{ position: show ? "unset" : "absolute" }}>
                <img className="mo-btn-img" src={btn} alt="btn" style={{ transform: show ? "rotate(180deg)" : null }} />
            </button>
            <div className={show ? "mo-hide-show" : "mo-hide-show mo-show"} >
                <div className='mo-block'>
                    <div className='mo-block-header'>
                        {l.mapOptions.notation}
                    </div>
                    <div className='mo-block-body'>
                        <div className='mo-block-body-text'><span><img src={work} alt="work" className='mo-block-image' /></span>{l.mapOptions.work}</div>
                        <div className='mo-block-body-text'><span><img src={busy} alt="work" className='mo-block-image' /></span>{l.mapOptions.busy}</div>
                        <div className='mo-block-body-text'><span><img src={connected} alt="work" className='mo-block-image' /></span>{l.mapOptions.connected}</div>
                        <div className='mo-block-body-text'><span><img src={reserved} alt="work" className='mo-block-image' /></span>{l.mapOptions.reserved}</div>
                        <div className='mo-block-body-text'><span><img src={alert} alt="work" className='mo-block-image' /></span>{l.mapOptions.alert}</div>
                    </div>
                </div>
                <div className='mo-block'>
                    <div className='mo-block-header'>
                        {l.mapOptions.status}
                    </div>
                    <div className='mo-block-body'>
                        <div className='mo-block-body-text'>
                            <span>
                                <input className='mo-block-inbut' checked={checkbox.statusFree} name="statusFree" onChange={HandleChange} type="checkbox">
                                </input>
                            </span>{l.mapOptions.statusFree}</div>
                        <div className='mo-block-body-text'>
                            <span>
                                <input className='mo-block-inbut' checked={options.work} name="work" onChange={HandleChangeo} type="checkbox">
                                </input>
                            </span>{l.mapOptions.statusEmpty}</div>
                    </div>
                </div>
                <div className='mo-block'>
                    <div className='mo-block-header'>
                        {l.mapOptions.port}
                    </div>

                    <div className='mo-block-body'>
                        <div className='mo-block-body-text'>
                            <span>
                                <input className='mo-block-inbut' checked={checkbox.Type2} name="Type2" onChange={HandleChange} type="checkbox" />
                            </span>
                                Type2
                            </div>
                        <div className='mo-block-body-text'>
                            <span>
                                <input className='mo-block-inbut' checked={checkbox.J1772} name="J1772" onChange={HandleChange} type="checkbox" />
                            </span>
                                J1772
                            </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MapOptions
