// noinspection JSUnresolvedVariable

import React, { useContext, useState, useEffect } from 'react'
import './Use.css'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { API } from '../../api'

import Type1service from './images/Type1service.png'
import Type1busy from './images/Type1busy.png'
import Type1work from './images/Type1work.png'
import Type1alert from './images/Type1alert.png'
import Type1reserved from './images/Type1reserved.png'
import Type1connected from './images/Type1connected.png'

import Type2service from './images/Type2service.png'
import Type2busy from './images/Type2busy.png'
import Type2work from './images/Type2work.png'
import Type2alert from './images/Type2alert.png'
import Type2reserved from './images/Type2reserved.png'
import Type2connected from './images/Type2connected.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { MarkerContext } from '../../context/MarkerContext'
import { AuthContext } from '../../context/AuthContext'
import { Loaderr } from '../../components/Loaderr'
import { LanguageContext } from '../../context/LangContext'

const statusColor = new Map([
    ['work', "#41a350"],
    ['service', "#A3A6AB"],
    ['busy', "#00B0E6"],
    ['alert', "#FF3549"],
    ['connected', "#E5BB12"],
    ['reserved', "#C416FF"],
])

const statusImageType1 = new Map([
    ['work', Type1work],
    ['service', Type1service],
    ['busy', Type1busy],
    ['alert', Type1alert],
    ['connected', Type1connected],
    ['reserved', Type1reserved],
])

const statusImageType2 = new Map([
    ['work', Type2work],
    ['service', Type2service],
    ['busy', Type2busy],
    ['alert', Type2alert],
    ['connected', Type2connected],
    ['reserved', Type2reserved],
])

export const Use = ({ setUse, props }) => {

    const [l] = useContext(LanguageContext)
    const { token } = useContext(AuthContext)
    // loading, 
    const { request, error, clearError } = useHttp()
    const [state, setState] = useState()
    const [disable, setDisable] = useState(false)
    const [markers] = useContext(MarkerContext)
    const message = useMessage()
    const [mes] = useState(null)

    useEffect(() => {
        message(mes)
        message(error)
        clearError()
    }, [error, message, clearError, mes])

    useEffect(() => {
        window.M.updateTextFields()
    }, [])

    useEffect(() => {
        if (props !== undefined && markers !== undefined) {
            markers.forEach(key => {
                if (key.id === props.chargePointId) {
                    setState(key)
                }
            })
        }
    }, [markers, props])

    const stopCharge = async () => {
        try {
            setDisable(true)
            const data = await request(`${API}/end`, token, 'POST', '')
            if (data.userAction.shouldBeFinished) {
                setDisable(false)
            }
        } catch (e) {
        }
    }
 
    if (props && state) {

        // noinspection JSUnresolvedVariable
        return (
            <>
                <div className="station-prop">
                    <div className="station-prob-bg" onClick={() => { setUse(false) }}/>
                    <div className='station-body charge-body'>
                        <button className='use-close-btn' onClick={() => { setUse(false) }}>
                            <FontAwesomeIcon className='close-btn' icon={faTimes} />
                        </button>
                        <div className='use-charge'>
                            <div className='use-charge-left'>

                                <div className="station-opt">
                                    <div className="station-connector-bold">{l.use.port}</div>
                                    <div className="station-connector-text">&nbsp;&nbsp;&nbsp;&nbsp;{props.connectorId}</div>
                                </div>
                                <div className="station-opt">
                                    <div className="station-connector-bold">{l.use.type}</div>
                                    <div className="station-connector-text">{state.connectors[props.connectorId - 1].type}</div>
                                </div>
                            </div>
                            <div className="use-charge-border">
                                <div
                                    className='use-charge-centre'
                                    style={{ borderColor: statusColor.get(state.connectors[props.connectorId - 1].status) }}>
                                    <div className='use-charge-connector'>
                                        {state.connectors[props.connectorId - 1].type === "J1772" ?
                                            <img className='charge-img' src={statusImageType1.get(state.connectors[props.connectorId - 1].status)} alt='reserve-img' /> :
                                            state.connectors[props.connectorId - 1].type === "Type2" ?
                                                <img className='charge-img' src={statusImageType2.get(state.connectors[props.connectorId - 1].status)} alt='reserve-img' /> :
                                                <img className='charge-img' src={Type2service} alt='reserve-img' />
                                        }
                                    </div>
                                    <div className='use-charge-counter'>
                                        {props.userAction.currentTime} {l.use.min}</div>
                                </div>
                            </div>
                            <div className='use-charge-right'>
                                <div className="station-opt">
                                    <div className="station-connector-bold">{l.use.power}</div>
                                    <div className="station-connector-text">{state.connectors[props.connectorId - 1].power}</div>
                                </div>
                                <div className="station-opt">
                                    <div className="station-connector-bold">{l.use.tarif}</div>
                                    <div className="station-connector-text"
                                        style={{ cursor: 'help' }}
                                        title="заряд">{l.use.tariffCharge}<br /><strong>{state.connectors[props.connectorId - 1].tariff.charge.toFixed(2)}</strong> {l.points}/{l.use.kVt}</div>
                                    <div className="station-connector-text"
                                        style={{ cursor: 'help' }}
                                        title="резервация"> {l.use.tariffReserve} <br /><strong>{state.connectors[props.connectorId - 1].tariff.reserve.toFixed(2)}</strong> {l.points}/{l.use.min}</div>
                                </div>
                            </div>
                        </div>
                        {props.type === "charge" ?
                            <div className='use-btn-block'>
                                <div className='use-btn-text'>{Math.round((props.userAction.totalEnergy - (Math.round(props.userAction.remainedEnergy * 100) / 100)) * 100) / 100} {l.use.kVt}</div>
                                <div className='use-btn-calk'>{Math.floor(props.userAction.remainedEnergy * 100) / 100} / {props.userAction.totalEnergy}</div>
                            </div> :
                            props.type === "reserve" ?
                                <div className='use-btn-block'>
                                    <div className='use-btn-calk'>{props.userAction.remainedTime} / {props.userAction.totalTime}</div>
                                </div> :
                                props.type === "time_charge" ?
                                    <div className='use-btn-block'>
                                        <div className='use-btn-text'>{Math.round(props.userAction.currentEnergy * 100) / 100} {l.use.kVt}</div>
                                        <div className='use-btn-calk'>{props.userAction.remainedTime} / {props.userAction.totalTime}</div>
                                    </div> :
                                    <div />}

                        {!props.userAction.shouldBeFinished ? <button disabled={disable} style={disable ? { backgroundColor: "gray" } : null} className='station-body-button use-btn' onClick={() => { stopCharge().then() }}>{l.use.endButton}</button> : <div className='use-after-btn'>{l.use.end}</div>}
                        {props.userAction.shouldBeFinished && <button className='station-body-button use-btn' onClick={() => { setUse(false) }}>{l.use.closeButton}</button>}
                        <div className='use-info'>
                            <div className='station-body-head'>
                                <div className="station-title">{state.properties.tittle} </div>
                                <div className="station-info">
                                    <div className="station-workTime">
                                        <div className="station-connector-bold">{l.use.workTime}</div>
                                        <div className="station-connector-text">{state.properties.workTime}</div>
                                    </div>
                                    <div className="station-address">
                                        <div className="station-connector-bold">{l.use.address}</div>
                                        <div className="station-connector-text">{state.properties.address}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return <Loaderr />
}
