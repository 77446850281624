import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import {API} from "../../api";
import {useHttp} from "../../hooks/http.hook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlusSquare, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {LanguageContext} from "../../context/LangContext";
import { useMessage } from './../../hooks/message.hook';

export const Organizations = () => {

    const [l] = useContext(LanguageContext);
    const {organizations, setOrganizations, token } = useContext(AuthContext);
    const { request } = useHttp();
    const [dellModal, setDellModal] = useState(null);
    const [addModal, setAddModal] = useState(false);
    const [newOrganizationName, setNewOrganizationName] = useState('');
    const [update, setUpdate] = useState(true);
    const [editId, setEditId] = useState(null);
    const [editName, setEditName] = useState(undefined);
    const {footerContainerHeight, footerBarHeight} = useContext(AuthContext);
    const message = useMessage()
    const loadOrganizations = async () => {
        return await request(`${API}/organization/getAll`, token, 'GET');
    }

    useEffect(() => {
        if (update) {
            loadOrganizations().then(data => {
                setOrganizations(data);
            });
            setUpdate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    const onNewOrganizationNameChange = (e) => {
        setNewOrganizationName(e.target.value);
    }

    const renamedOrganizationNameChange = (e) => {
        setEditName(e.target.value);
    }

    const addOrganization = async () => {

        request(`${API}/organization/create?name=${newOrganizationName}`, token, 'POST').then(() => {
            setUpdate(true);
            setAddModal(false);
            message(l.organizations.added);
        }).catch(() => {
            message(l.organizations.notAdded);
        });

    }

    const delOrganization = async (id) => {
        request(`${API}/organization/delete?id=${id}`, token, 'Delete').then(() => {
            setUpdate(true);
            setDellModal(null);
            message(l.organizations.deleted);
        }).catch(() => {
            message(l.organizations.notDeleted);
        });
    };

    const editOrganization = () => {
        request(`${API}/organization/rename?id=${editId}&name=${editName}`, token, 'PUT').then(() => {
            setUpdate(true);
            setEditId(null);
            setEditName(null);
            message(l.organizations.updated);
        }).catch(() => {
            message(l.organizations.notUpdated);
        });
    };

    return (footerContainerHeight && footerBarHeight ?
        <div style={{minHeight: "calc(100vh - " + (footerContainerHeight + footerBarHeight + 201) + "px)", paddingTop: "200px"}}>
            <button style={{position: "static", marginBottom: "20px"}} className="tariff-add-btn" onClick={() => { setAddModal(true) }}><FontAwesomeIcon icon={faPlusSquare} size="lg" /> {l.tariff.add}</button>
        <table>
            <thead>
            <tr>
                <th>id</th>
                <th>name</th>
            </tr>
            </thead>
            {organizations ? organizations.map((key, i) => {
                return (<tbody key={i}><tr>
                    <td>{key.id}</td>
                    <td>{key.name}</td>
                    <td><button
                        className="table-body-btn"
                        onClick={() => setDellModal(key.id)}>
                        <FontAwesomeIcon icon={faTrashAlt} size="lg" /></button></td>
                    <td><button className="table-body-btn"
                                onClick={() => { setEditId(key.id); setEditName(key.name) }}>
                        <FontAwesomeIcon icon={faEdit} size="lg" /></button></td>
                </tr></tbody>);
            }) : <></>}
        </table>

            {dellModal !== undefined && dellModal !== null ? <>

                <div className="stations-modal">
                    <div style={{height: "100px"}} className="stations-modal-body">
                        <div className="stations-modal-dell-text">
                            {l.organizations.deleteQuestion}
                        </div>
                        <div className="stations-modal-dell">
                            <button style={{ backgroundColor: "#FF3549" }} className="stations-modal-dell-btn" onClick={() => { delOrganization(dellModal).then() }}>{l.stations.ok}</button>
                            <button className="stations-modal-dell-btn" onClick={() => setDellModal(null)}>{l.stations.cancel}</button>
                        </div>
                    </div>
                </div>
            </> : null}

            {addModal ? <div className="tariffs-modal">
                <div className="tariffs-modal-body tariff-page-input">
                    <div className="tariffs-modal-box">
                        <input
                            name="charge"
                            placeholder={l.organizations.name}
                            onChange={onNewOrganizationNameChange}
                            className="tariff-page-input-item"/>
                    </div>
                    <div className="tariff-page-input-buttons">
                        <button onClick={addOrganization} className="tariff-page-input-button">{l.tariff.add}</button>
                        <button onClick={() => {
                            setAddModal(false)
                        }} className="tariff-page-input-button" style={{ backgroundColor: "rgb(255, 53, 73)" }}>{l.tariff.cancel}</button>
                    </div>
                </div>
            </div> : null}

            {editId !== undefined && editId !== null ? <div className="tariffs-modal">
                <div className="tariffs-modal-body tariff-page-input">
                    <div className="tariffs-modal-box">
                        <input
                            name="charge"
                            placeholder={l.organizations.name}
                            value={editName}
                            onChange={renamedOrganizationNameChange}
                            className="tariff-page-input-item"/>
                    </div>
                    <div className="tariff-page-input-buttons">
                        <button onClick={editOrganization} className="tariff-page-input-button">{l.organizations.rename}</button>
                        <button onClick={() => {
                            setEditId(undefined)
                        }} className="tariff-page-input-button" style={{ backgroundColor: "rgb(255, 53, 73)" }}>{l.tariff.cancel}</button>
                    </div>
                </div>
            </div> : null}

    </div> : null)

}
