import React, { useEffect, useState, useContext } from 'react';
import './Nav.css';
// Link,
import { useLocation } from "react-router-dom";
import { NavLink } from 'react-router-dom'
import { SwipeMenu } from './SwipeMenu';
import logoInvert from './images/logoinvert.png'
import logoText from './images/logoCharge.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faAngleUp, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../context/AuthContext'
import { Language } from '../Language';
import { LanguageContext } from '../../context/LangContext'
import mapIcon from './images/map.svg';

export const Nav = () => {

    const [l] = useContext(LanguageContext)
    let location = useLocation()
    let path = location.pathname
    const { token, id, isOnline } = useContext(AuthContext)
    const [isActive, setIsActive] = useState(false)
    const [upBtn, setUpBtn] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0);
    let logo = logoText;
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const setPath = () => {
        localStorage.setItem("path", JSON.stringify("/user"))
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        };
    }, []);

    const toup = () => {
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        if (path !== '/') {
            setIsActive(true)
        }
        if (path === '/') {
            if (scrollPosition >= 380) {
                setIsActive(true)
            }
            if (scrollPosition < 380) {
                setIsActive(false)
            }
        }
        if (scrollPosition > 800) {
            setUpBtn(true)
        } else {
            setUpBtn(false)
        }

    }, [scrollPosition, path])

    if(window.screen.width < 500) {
        logo = logoInvert;
    }


    return (
        <div className={`navbar ${isActive && 'navbar-active'}`}>
            <div className='nav-items'>
                <div className='nav-logo'>
                    <NavLink to='/' onClick={path==='/'?()=>window.scrollTo(0,0):()=>{}}>
                        <img style={{padding:"0px 15px",transaction:".4s"}} className='logoimage' src={logo} alt='logo-img' />
                    </NavLink>
                </div>
                <div className='nav-menu'>

                    {token ?
                        <div>
                            <NavLink to={'/map'} style={{marginRight: '10px'}}>
                                <img src={mapIcon} alt={'map'} height={21} width={21}/>
                            </NavLink>
                            <NavLink to={`/user/${id}`}><span className='menu-item menu-item-p'>
                                <FontAwesomeIcon style={isOnline?{color:"#A31642"}:{color:'red'}} title={isOnline?"online":"ofline"} icon={faUser} size="lg" />
                            </span>
                            </NavLink>
                            <NavLink to={`/user/${id}`}><span className='menu-item menu-item-text menu-item-p'>
                                &nbsp;
                            {l.lang === 'en' ? `${l.navbar.navTextLog}` : `${l.navbar.navTextLog}`}
                            </span>
                            </NavLink>
                        </div> :
                        <NavLink to="/login" onClick={setPath}><div className='menu-item menu-item-p'>
                            <FontAwesomeIcon style={{color:"gray"}} icon={faUser} size="lg" /> &nbsp; {l.navbar.navTextNoLog}</div>
                        </NavLink>
                    }
                    <div className='menu-item menu-item-phone'>{l.navbar.support} +38 (095) 521-59-12</div>
                    <a href="https://radmirtech.com.ua/product-category_energy/elektrozapravki/">
                        <div className='menu-item item-border langmenu menu-item-ico' >
                            <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                        </div>
                    </a>
                    <div className='menu-item item-border langmenu'><Language /></div>

                    <div className='menu-item item-border'><SwipeMenu /></div>

                </div>
            </div>
            {upBtn ? <div className='up-btn' onClick={toup}>
                <FontAwesomeIcon icon={faAngleUp} size="lg" />
            </div> : <></>}
        </div>
    );
}



