import { useState, useCallback, useEffect } from 'react'

const storageName = 'userData'
const roleStatus = 'role'

export const useAuth = (session) => {

    const [id, setId] = useState(null)
    const [token, setToken] = useState(null)
    const [emailConfirmed, setEmailConfirmed] = useState(null)
    const [phoneConfirmed, setPhoneConfirmed] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [email, setEmail] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [markAndModel, setMarkAndModel] = useState(null)
    const [ready, setReady] = useState(false)
    const [role, setRole] = useState(null)
    const [ss, setSs] = useState(session)

    useEffect(() => {
        setSs(session)
    }, [session])

    const login = useCallback((data, role, status = ss) => {

        setId(data.id)
        setPhoneNumber(data.phoneNumber)
        setEmail(data.email)
        setFirstName(data.firstName)
        setLastName(data.lastName)
        setMarkAndModel(data.markAndModel)
        setToken(data.token)
        setEmailConfirmed(data.emailConfirmed)
        setPhoneConfirmed(data.phoneConfirmed)

        let roleValue = data.role;
        if (!roleValue) {
            roleValue = role
        }
        setRole(roleValue);

        if (status) {
            sessionStorage.setItem(storageName, JSON.stringify({
                phoneNumber: data.phoneNumber,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                markAndModel: data.markAndModel,
                id: data.id,
                token: data.token,
                emailConfirmed: data.emailConfirmed,
                phoneConfirmed: data.phoneConfirmed,
            }))
            sessionStorage.setItem(roleStatus, JSON.stringify({ role: roleValue }))
        } else {
            localStorage.setItem(storageName, JSON.stringify({
                phoneNumber: data.phoneNumber,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                markAndModel: data.markAndModel,
                id: data.id,
                token: data.token,
                emailConfirmed: data.emailConfirmed,
                phoneConfirmed: data.phoneConfirmed,
            }))
            localStorage.setItem(roleStatus, JSON.stringify({ role: roleValue }))
        }

    }, [ss])

    const logout = useCallback(() => {
        setPhoneNumber(null)
        setEmail(null)
        setFirstName(null)
        setLastName(null)
        setMarkAndModel(null)
        setToken(null)
        setEmailConfirmed(null)
        setPhoneConfirmed(null)
        setId(null)
        setRole(null)

        localStorage.removeItem(storageName)
        localStorage.removeItem(roleStatus)
        sessionStorage.removeItem(storageName)
        sessionStorage.removeItem(roleStatus)
    }, [])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName))
        let role = localStorage.getItem(roleStatus);
        try {
            role = JSON.parse(role)
        } catch (ex) {
            console.log(ex);
        }
        const timedata = JSON.parse(sessionStorage.getItem(storageName))
        const timerole = JSON.parse(sessionStorage.getItem(roleStatus))

        if (data && data.token && role) {
            login(data, role.role)
        } else {
            localStorage.removeItem(storageName)
            localStorage.removeItem(roleStatus)
        }
        if (timedata && timedata.token && timerole.role) {
            setSs(true)
            login(timedata, timerole.role)
        } else {
            sessionStorage.removeItem(storageName)
            sessionStorage.removeItem(roleStatus)
        }
        setReady(true)

    }, [login])

    return {
        id,
        token,
        emailConfirmed,
        phoneConfirmed,
        phoneNumber,
        email,
        firstName,
        lastName,
        markAndModel,
        login,
        logout,
        ready,
        role
    }
}
