import { createContext } from 'react'

function noop() {console.log('hi') }

export const AuthContext = createContext({

    id: null,
    role: null,
    emailConfirmed: null,
    phoneConfirmed: false,
    phoneNumber: null,
    email: null,
    firstName: null,
    lastName: null,
    markAndModel: null,
    token: null,
    loginFunction: noop,
    logout: noop,
    isAuthenticated: false,
    session: false,
    isOnline: null,
    organization: null,
    organizationId: null,
    setOrganization: null,
    setOrganizationId: null,
    organizations: [],
    setOrganizations: null,
    startDate: null,
    setStartDate: null,
    endDate: null,
    setEndDate: null,
    footerContainerHeight: null,
    footerBarHeight: null,
    setLogin: null,
    setPassword: null,
    login: null,
    password: null
})
