import React from 'react'
import './Loaderr.css'

export const Loaderr = () => (
    <div className="loaderr">
        <div className="square" ></div>
        <div className="square"></div>
        <div className="square last"></div>
        <div className="square clear"></div>
        <div className="square"></div>
        <div className="square last"></div>
        <div className="square clear"></div>
        <div className="square "></div>
        <div className="square last"></div>
    </div>
)
