import React, { useState, useEffect, useContext } from 'react';
import { NavLink} from 'react-router-dom';
import { useMessage } from '../hooks/message.hook';
import { useHttp } from '../hooks/http.hook'
import jwt from 'jsonwebtoken'
import { API } from '../api'
import PhoneInput from 'react-phone-input-2'
import './style/React-telinput.css'
import './style/RegistrationPage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import ReactCodeInput from 'react-verification-code-input'
import { LanguageContext } from '../context/LangContext'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const RegistrationPage = () => {

    let dataForm;

   if(JSON.parse(localStorage.getItem('dataRegistrationPage'))) {
        dataForm = {...JSON.parse(localStorage.getItem('dataRegistrationPage'))}
   }
   else{
        dataForm = {        
            phoneNumber: null,
            email: "",
            password: "",
            repeatPassword: "",
            firstName: "",
            lastName: "",
            markAndModel: "",
            contract: "",
        }
   }

    const message = useMessage()
    const [mes, setMes] = useState(null)
    const [l] = useContext(LanguageContext)
    const { loading, request, error, clearError } = useHttp()
    const [status, setStatus] = useState(false)
    const [passwordShow, setPasswordShow] = useState(false)
    const [passwordRepShow, setPasswordRepShow] = useState(false)
    const [confirmation, setConfirmation] = useState('')
    const [confirmonComplete, setConfirmonComplete] = useState(null)
    const [confirmstatus, setConfirmstatus] = useState(false)
    const { executeRecaptcha } = useGoogleReCaptcha()
    const [form, setForm] = useState({...dataForm})

    useEffect(() => {
        window.M.updateTextFields()
        document.title = `${l.pages.registrationPage}`
    })

    let jtoken = jwt.sign(form, 'chargePoint', { algorithm: 'HS512' })

    useEffect(() => {
        message(mes)
        message(error)
        setMes(null)
        clearError()
    }, [error, mes, message, clearError])

    const changeHandler = event => {
        if(event.target.name === 'contract') {
            setForm({ ...form, [event.target.name]: event.target.checked })
            return '';
        }
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const keyHandler = (event) => {
        if (event.key === "Enter") {
            let inputs = Array.from(document.querySelectorAll('.columns input'));
            let currentInput = inputs.findIndex((input) =>  event.target === input)
            if(currentInput === (inputs.length - 1)){
                getToken()
                return '';
            }
            inputs[currentInput + 1].focus()
        }
        if(event.type === 'click') getToken()
    }

    function validate(email) {
        // eslint-disable-next-line no-useless-escape
        let ema = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return ema.test(email);
    }

    // TODO use it or delete it
    const check = (email, password, repeatPassword, phoneNumber, firstName, lastName, markAndModel, contract) => {
        if (!email) {
            setMes(l.registrationPage.validation1)
            return false
        }
        if (!validate(email)) {
            setMes(l.registrationPage.validation2)
            return false
        }
        if (!password) {
            setMes(l.registrationPage.validation3)
            return false
        }
        if (password.length < 8) {
            setMes(l.registrationPage.validation4)
            return false
        }
        if (!repeatPassword) {
            setMes(l.registrationPage.validation5)
            return false
        }
        if (password !== repeatPassword) {
            setMes(l.registrationPage.validation6)
            return false
        }
        if (!phoneNumber) {
            setMes(l.registrationPage.validation7)
            return false
        }
        if (phoneNumber.length < 12) {
            setMes(l.registrationPage.validation8)
            return false
        }
        if (!firstName) {
            setMes(l.registrationPage.validation9)
            return false
        }
        if (firstName.length <= 3) {
            setMes(l.registrationPage.validation10)
            return false
        }
        if (!lastName) {
            setMes(l.registrationPage.validation11)
            return false;
        }
        if(firstName.length > 30) {
            setMes(l.registrationPage.validation13)
            return false;
        }
        if(lastName.length > 30) {
            setMes(l.registrationPage.validation14)
            return false;
        }
        if (!markAndModel) {
            setMes(l.registrationPage.validation15)
            return false
        }
        if (!contract) {
            setMes(l.registrationPage.validation16)
            return false
        }
        setMes(null)
        return true
    }

    const getToken = () => {
        executeRecaptcha("registration").then((value) => {
            registrationHandler(value)
        }, (reason) => {
            setMes("captcha error", reason)
            console.log("can't get captcha, you are robot!", "err" + reason)
        })
    }

    const registrationHandler = async (capcha) => {
        if (check(
            form.email,
            form.password,
            form.repeatPassword,
            form.phoneNumber,
            form.firstName,
            form.lastName,
            form.markAndModel,
            form.contract,
        )) {
            try {
                const data = await request(`${API}/register`, null, 'POST', {
                    token: jtoken,
                    captchaResponse: capcha
                })
                localStorage.removeItem('dataRegistrationPage')
                setMes(l.toast[data.toast])
                setStatus(true)
            } catch (e) {
                setStatus(false)
                setMes(l.errors[e])
            }
        }
    }

    const confirm = async () => {
        if (confirmation.length === 4) {
            try {
                const data = await request(`${API}/activate`, null, 'POST', { "code": confirmation })
                setMes(l.toast[data.toast])
                setConfirmonComplete(false)
                setConfirmstatus(true)
            } catch (e) {
                setMes(l.errors[e])
                setConfirmstatus(false)
            }
        }
    }

    const confirmecomplite = () => {
        setConfirmonComplete(true)
    }

    useEffect(() => {
        if (confirmation.length === 4 && confirmonComplete) {
            confirm(confirmation)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmation, confirmonComplete])

    useEffect(() =>{
        localStorage.setItem('dataRegistrationPage' , JSON.stringify(form))
    }, [form])

    if (status) {
        return (
            <div className="afterRegBlock">
                <div className="afterRegBlock-tittle">{l.registrationPage.sms}</div>
                <div>
                    <ReactCodeInput
                        fields={4}
                        type={'number'}
                        onChange={(num) => { setConfirmation(num) }}
                        onComplete={confirmecomplite}
                    />
                </div>
                {confirmstatus ?
                    <div className="afterRegBlock-text">{l.registrationPage.phoneAcc}</div> :
                    null}
                <NavLink className="afterRegBlock-text" to='/'>{l.registrationPage.main} </NavLink>
                <NavLink className="afterRegBlock-text" to='/login'>{l.registrationPage.login} </NavLink>

            </div>
        )
    }

    return (
        <>
            <div className="registratin">
                <div className="cont">
                    <div className="wrap-login">
                        <div className="login-form" >
                            <div className="login-form-title">
                                <NavLink className="login-form-title-link" to='/login'><FontAwesomeIcon icon={faArrowLeft} size="lg" /></NavLink>
                                {l.registrationPage.tittle}
                            </div>
                            <div className='columns'>
                                <div className='column'>
                                    <div className="wrap-input" data-validate="Email is required">
                                        <input
                                            className="input-area"
                                            type="text"
                                            name="email"
                                            placeholder={l.registrationPage.email}
                                            value={form.email}
                                            onChange={changeHandler}
                                            onKeyPress={keyHandler}
                                            required
                                        />
                                        <span className="focus-input" />
                                    </div>
                                    <div className="wrap-input" data-validate="Password is required">
                                        <button className='showpassword-btn' onClick={() => setPasswordShow(!passwordShow)}>
                                            <FontAwesomeIcon icon={passwordShow ? faEye : faEyeSlash} size="lg" />
                                        </button>
                                        <input
                                            className="input-area"
                                            type={passwordShow ? "text" : "password"}
                                            name="password"
                                            placeholder={l.registrationPage.password}
                                            value={form.password}
                                            onChange={changeHandler}
                                            onKeyPress={keyHandler}
                                            required
                                        />
                                        <span className="focus-input" />
                                    </div>

                                    <div className="wrap-input" data-validate="Password is required">
                                        <button className='showpassword-btn' onClick={() => setPasswordRepShow(!passwordRepShow)}>
                                            <FontAwesomeIcon icon={passwordRepShow ? faEye : faEyeSlash} size="lg" />
                                        </button>
                                        <input
                                            className="input-area"
                                            type={passwordRepShow ? "text" : "password"}
                                            name="repeatPassword"
                                            placeholder={l.registrationPage.repeatPassword}
                                            value={form.repeatPassword}
                                            onChange={changeHandler}
                                            onKeyPress={keyHandler}
                                            required
                                        />
                                        <span className="focus-input" />
                                    </div>
                                    <div className="wrap-input" data-validate="Mark and model is required">
                                        <input
                                            className="input-area"
                                            type="text"
                                            name="markAndModel"
                                            placeholder={l.registrationPage.markAndModel}
                                            value={form.markAndModel}
                                            onChange={changeHandler}
                                            onKeyPress={keyHandler}
                                            required
                                        />
                                        <span className="focus-input" />
                                    </div>
                                </div>

                                <div className='column'>
                                    <div className="wrap-input" data-validate="name is required">
                                        <input
                                            className="input-area"
                                            type="text"
                                            name="firstName"
                                            placeholder={l.registrationPage.name}
                                            value={form.firstName}
                                            onChange={changeHandler}
                                            onKeyPress={keyHandler}
                                            required
                                        />
                                        <span className="focus-input" />
                                    </div>

                                    <div className="wrap-input" data-validate="Email is required">
                                        <input
                                            className="input-area"
                                            type="text"
                                            name="lastName"
                                            placeholder={l.registrationPage.surname}
                                            value={form.lastName}
                                            onChange={changeHandler}
                                            onKeyPress={keyHandler}
                                            required
                                        />
                                        <span className="focus-input" />
                                    </div>

                                    <PhoneInput
                                      inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true
                                      }}
                                        containerClass="wrap-input"
                                        inputClass="input-area"
                                        onlyCountries={['by', 'ru', 'ua']}
                                        country={'ua'}
                                        value={form.phoneNumber}
                                        onKeyDown={keyHandler}
                                        onChange={phoneNumber => setForm({ ...form, "phoneNumber": phoneNumber })}
                                    />
                                    <span className="focus-input" />
                                    <div className="wrap-input wrap-data-input wrap-data-input-text hide-mob" style={{ border: "none" }} data-validate="birthdate is required">

                                    </div>

                                </div>

                            </div>

                            <div className="btns">
                                      <div className='contract'>
                                          <input type='checkbox' onChange={changeHandler} name='contract'/ >
                                          {l.registrationPage.checkboxStart} 
                                          <NavLink to={'/contract'} rel="noreferrer" >
                                              {l.registrationPage.checkboxMiddle}
                                            </NavLink> {l.registrationPage.checkboxEnd}
                                      </div>
                                <button
                                    className="button-login button-reg pulse"
                                    onClick={keyHandler}
                                    disabled={loading}
                                >
                                    {l.registrationPage.button}
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
