// noinspection JSUnresolvedFunction

import React, { useEffect, useState, useContext, useRef } from 'react'
import { useMessage } from '../hooks/message.hook'
import { useHttp } from '../hooks/http.hook'
import { API } from '../api'
import './style/Users.css'
import { LanguageContext } from '../context/LangContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faCheckCircle, faEdit, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import './style/UsersPage.css'
import { Link } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import nodata from './style/images/nodata.png'
import useOnClickOutside from '../hooks/useOnClickOutside.hook';
import PhoneInput from "react-phone-input-2";

export const UsersPage = () => {

    const { token, role, organizations, setOrganizations } = useContext(AuthContext)
    const [users, setUsers] = useState()
    const [l] = useContext(LanguageContext)
    const [mes, setMes] = useState(null)
    const { request, error, clearError } = useHttp()
    const [pages, setPages] = useState()
    const [currentPage, setCurrentPage] = useState()
    const [page, setPage] = useState(JSON.parse(localStorage.getItem('userPage')))
    const [pageArr, setPageArr] = useState()
    const [edit, setEdit] = useState()
    const [update, setUpdate] = useState(false)
    const message = useMessage()
    const arr = []
    const [phone, setPhone] = useState("380")

    let inputArea;

    let interval = setInterval(() => {

        if(!inputArea) {
            inputArea = document.getElementsByClassName('input-area')[0];
        } else {
            clearInterval(interval);
            inputArea.placeholder = '+380 (50) 143 30 36'
        }

    }, 0);

    const loadOrganizations = async () => {
        return await request(`${API}/organization/getAll`, token, 'GET');
    }

    useEffect(() => {
        document.title = `Users`
        localStorage.setItem("path", JSON.stringify("/users"))
    })

    useEffect(() => {
        for (let i = 1; i <= pages; i++) {
            arr.push(i)
        }
        if (arr.length > 1) {
            setPageArr(arr)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages])

    useEffect(() => {
        message(mes)
        message(error)
        setMes(null)
        clearError()
    }, [error, mes, message, clearError])

    useEffect(() => {
        window.M.updateTextFields()
    }, [])

    useEffect(() => {
        if (update === true) {
            getUsers(page).then()
        }
        setUpdate(false)

        if(role === 'admin') {
            loadOrganizations().then(data => {
                setOrganizations(data);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    const getUsers = async (page = 1) => {
        try {
            let data;
            if (phone) {
                data = await request(`${API}/get/${page}/users?phone=${phone}`, token)
            } else {
                data = await request(`${API}/get/${page}/users`, token)
            }
            setPages(data.pageAmount)
            setCurrentPage(data.currentPage)
            setUsers(data.info)
        } catch (e) {
            setMes(l.errors[e])
            if (e === "pageNotFound") {
                setPage(1)
                localStorage.setItem('userPage', '1')
            }
        }
    }

    useEffect(() => {
        if (page === null) {
            getUsers(1).then()
            setPage(1)
            localStorage.setItem('userPage', '1')
        }
        if (page > pages) {
            getUsers(1).then()
            setPage(1)
            localStorage.setItem('userPage', '1')
        }
        if (page) {
            getUsers(page).then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, phone])

    const goPage = (i) => {
        setPage(i + 1)
        localStorage.setItem('userPage', i + 1)
        window.scrollTo(0, 0)
    }

    const onPhoneChange = (phoneNumber) => {
        setPhone(phoneNumber);
    }

    const deleteUser = async (user) => {
        try{
            await request(`${API}/delete/user?userId=${user.id}`, token, 'DELETE');
            loadOrganizations().then(data => {
                setOrganizations(data);
            });
        } 
        catch(e) {
            message(e)
        }
    }

    return (

        users ?
            <div className="users-block">

                <div className="wrapPhoneInput">
                <PhoneInput
                    inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true
                    }}
                    containerClass="wrap-input"
                    inputClass="input-area"
                    onlyCountries={['by', 'ru', 'ua']}
                    country={'ua'}
                    value={phone}
                    onChange={onPhoneChange}
                />
                </div>

                <div className="users-body">
                    <table className="stations-table">
                        {users ? <thead className="stations-table-head">
                            <tr>
                                {/*<th>{l.usersPage.id}</th>*/}
                                <th>{l.usersPage.firstName}</th>
                                <th>{l.usersPage.lastName}</th>
                                <th>{l.usersPage.email}</th>
                                <th>{l.usersPage.phone}</th>
                                <th>{l.usersPage.money}</th>
                                <th>{l.usersPage.role}</th>
                                <th>{l.usersPage.organization}</th>
                            </tr>
                        </thead> : null}
                        <tbody className="stations-table-body">
                            {users ? users.map((key, i) => (
                                <tr className="stations-table-body-block" key={key.id} >
                                    {/*<td className="stations-table-body-item" aria-label="id">{key.id}</td>*/}
                                    <td className="stations-table-body-item" aria-label={l.usersPage.firstName}>{key.firstName}</td>
                                    <td className="stations-table-body-item" aria-label={l.usersPage.lastName}>{key.lastName}</td>
                                    <td className="stations-table-body-item" aria-label={l.usersPage.email}>{key.email}
                                        <FontAwesomeIcon
                                            title={key.emailConfirmed ? "email confirmed" : "email not confirmed"}
                                            className='email-confirmation'
                                            alt='email-confirmation'
                                            icon={key.emailConfirmed ? faCheckCircle : faTimesCircle}
                                            style={key.emailConfirmed ? { color: "green" } : { color: "red" }}
                                            size="lg" />
                                    </td>
                                    <td className="stations-table-body-item" aria-label={l.usersPage.phone}>{key.phoneNumber}</td>
                                    <td className="stations-table-body-item" aria-label={l.usersPage.money}>{key.money.toFixed(2)}</td>
                                    <td className="stations-table-body-item" aria-label={l.usersPage.role}>{key.role}</td>
                                    <td className="stations-table-body-item" aria-label={l.usersPage.organization}>{key.organizationName}</td>
                                    <td className="stations-table-body-item">
                                        <div className="stations-table-body-btns">
                                            <button className="table-body-btn"
                                                onClick={() => { setEdit(users[i]) }}>
                                                <FontAwesomeIcon icon={faEdit} size="lg" />
                                            </button>
                                            <Link to={`userStatistics/${key.id}`} className="table-body-btn">
                                                <FontAwesomeIcon icon={faChartBar} size="lg" />
                                            </Link>
                                            <button className="table-body-btn"
                                                onClick={() => { deleteUser(users[i]) }}>
                                                <FontAwesomeIcon 
                                                    icon={faTimesCircle} 
                                                    size="lg" 
                                                    style={{ color: "red" }}
                                                    title={"Delete user"}
                                                    />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>
                {edit ? <UsersEdit edit={edit} data={edit} setEdit={setEdit} setUpdate={setUpdate} organizations={organizations} /> : null}

                <div className='userPage-pagination'>
                    <div className='userPage-nums'>
                        {pageArr ? pageArr.map((key, i) => (
                            key === 1 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='userPage-num'>{key}</button> :
                                key === 1 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='userPage-num picked'>{key}</button> :
                                    key === 2 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='userPage-num'>{key}</button> :
                                        key === 2 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='userPage-num picked'>{key}</button> :
                                            currentPage === key ?
                                                <button key={i} onClick={() => { }} className='userPage-num picked'>{key}</button> :
                                                key === currentPage - 3 ? <div key={i}>...</div> :
                                                    key === currentPage + 3 && key !== pageArr.length - 1 && key !== pageArr.length ? <div key={i}>...</div> :
                                                        key === pageArr.length - 1 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='userPage-num'>{key}</button> :
                                                            key === pageArr.length - 1 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='userPage-num picked'>{key}</button> :
                                                                key === pageArr.length && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='userPage-num'>{key}</button> :
                                                                    key === pageArr.length && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='userPage-num picked'>{key}</button> :
                                                                        key > currentPage - 3 && currentPage + 3 > key ?
                                                                            <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='userPage-num'>{key}</button> : <div key={i}/>
                        )) : null}
                    </div>
                </div>
            </div>
            : <div className="users-block">
                <div className="loader-body">
                    <img className="loader-body-img" src={nodata} alt='nodata' />
                </div>
            </div>
    )
}


const UsersEdit = ({ edit, data, setEdit, setUpdate, organizations }) => {

    const { id, token, role } = useContext(AuthContext)
    const [l] = useContext(LanguageContext)
    const { request, error, clearError } = useHttp()
    const [mes, setMes] = useState(null)
    const message = useMessage()
    const editFrame = useRef()

    useEffect(() => {
        message(mes)
        message(error)
        setMes(null)
        clearError()
        setMes(null)
    }, [error, mes, message, clearError])

    useEffect(() => {
        window.M.updateTextFields()
    }, [])

    const [editState, setEditState] = useState({
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        money: data.money,
        emailConfirmed: data.emailConfirmed,
        markAndModel: data.markAndModel,
        role: data.role,
        organizationId: data.organizationId
    })

    const handleEdit = (e) => {
        const { name, value } = e.target
        setEditState({ ...editState, [name]: value })
    }

    const handleEditCheckbox = (e) => {
        const { name, checked } = e.target
        setEditState({ ...editState, [name]: checked })
    }

    const SaveUser = async () => {
        try {
            if(!editState.markAndModel) {
                editState.markAndModel = '';
            }
            const data = await request(`${API}/user/update`, token, 'PUT', editState)
            setMes(l.toast[data.toast])
            if (editState.id === id) {
                // localStorage.setItem("role", JSON.stringify({ role: editState.role }))
                window.location.reload()
            }
            setUpdate(true)
            setEdit()
        } catch (e) {
            setMes(l.errors[e])
        }
    }

    useOnClickOutside(editFrame, () => {
        if (edit) {
            setEdit(false)
        }
    });


    return (
        <div className="stations-modal">
            <div className="stations-mob-body" ref={editFrame}>
                <div className="users-modal-body">
                    <button className="stations-modal-close-btn" onClick={() => { setEdit() }}><FontAwesomeIcon icon={faTimes} size="lg" /></button>
                    <div className="users-edit-block">
                        <div>
                            <div className="stations-modal-input-head">{l.usersPage.firstName}</div>
                            <input className="stations-modal-input" value={editState.firstName} name="firstName" onChange={handleEdit} />
                        </div>
                        <div className="stations-modal-input-head">
                            <div>{l.usersPage.lastName}</div>
                            <input className="stations-modal-input" value={editState.lastName} name="lastName" onChange={handleEdit} />
                        </div>
                        { role === 'operator' ? '' : <div className="stations-modal-input-head">
                            <div>{l.usersPage.money}</div>
                            <input className="stations-modal-input" value={editState.money} name="money" onChange={handleEdit} />
                        </div>}
                        <div className="users-modal-markAndModel-email">
                            <input type="checkbox" name="emailConfirmed" checked={editState.emailConfirmed} onChange={handleEditCheckbox} />
                            <div className="stations-modal-input-head">
                                <div>{l.usersPage.emailConf}</div>
                            </div>
                        </div>
                        <div className="users-role-input">
                            <br />
                            <div className="stations-modal-input-head">{l.usersPage.role}</div>
                            <select style={{cursor: 'pointer'}} className="users-select" value={editState.role} name="role" onChange={handleEdit}>
                                <option value="user">{l.usersPage.selectUser}</option>
                                { role === 'admin' ? <option value="admin">{l.usersPage.selectAdmin}</option> : <></>}
                                <option value="operator">{l.usersPage.selectOperator}</option>
                            </select>
                        </div>

                        { role === 'admin' ? <div>
                            <br />
                            <div className="stations-modal-input-head">{l.usersPage.organization}</div>
                            <select style={{cursor: 'pointer', width: '100%'}} className="users-select" value={editState.organizationId} name="organizationId" onChange={handleEdit}>
                                {organizations.map((key, i) => {
                                    return <option key={i} value={key.id}>{key.name}</option>
                                })}
                            </select>
                        </div> : <></> }

                        <button onClick={() => { SaveUser().then() }} className="users-saveBtn">Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
