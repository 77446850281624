// noinspection JSUnresolvedVariable

import React, { useEffect, useState, useContext } from 'react'
import { useHttp } from '../hooks/http.hook'
import { useMessage } from '../hooks/message.hook'
import { API } from '../api'
import { AuthContext } from '../context/AuthContext'
import { LanguageContext } from '../context/LangContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faStopwatch, faChargingStation, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import './style/Tariffs.css'
import { Loaderr } from '../components/Loaderr'

export const Tariffs = () => {

    const { token, organizations, setOrganizations, role, organizationId } = useContext(AuthContext)
    const [l] = useContext(LanguageContext)
    const { request, error, clearError } = useHttp()
    const message = useMessage()
    const [mes, setMes] = useState(null)
    const [tariffs, setTariffs] = useState(null)
    const [input, setInput] = useState({
        charge: NaN,
        reserve: NaN,
        organizationId: organizationId
    })
    const [pages, setPages] = useState()
    const [currentPage, setCurrentPage] = useState()
    const [page, setPage] = useState(JSON.parse(localStorage.getItem('tariff-page')))
    const [pageArr, setPageArr] = useState()
    const [upd, setUpd] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [dellModal, setDellModal] = useState()
    const [dellId, setDellId] = useState()
    const arr = []

    const handleInput = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value })
    }

    const loadOrganizations = async () => {
        return await request(`${API}/organization/getAll`, token, 'GET');
    }

    if(page === null) {
        localStorage.setItem('tariff-page', '1');
        setPage(JSON.parse(localStorage.getItem('tariff-page')))
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = `${l.pages.tariff}`
        window.M.updateTextFields()
        localStorage.setItem("path", JSON.stringify("/tariffs"))
    })

    useEffect(() => {
        message(mes)
        message(error)
        setMes(null)
        clearError()
    }, [error, message, clearError, mes])

    const getTariffs = async (page) => {

        try {
            const data = await request(`${API}/get/${page}/tariffs`, token)
            setTariffs(data.info)
            setPages(data.pageAmount)
            setCurrentPage(data.currentPage)
            setUpd(false)
        } catch (e) {
            if (e === "pageNotFound") {
                setPage(1)
                localStorage.setItem('tariff-page', '1')
            }
            setMes(setMes(l.errors[e]))
            setUpd(false)
        }
    }

    const addTariff = async () => {
        let charge = input.charge;
        let reserve = input.reserve;
        if (charge !== 0 && reserve !== 0) {
            try {
                const data = await request(`${API}/tariff/create`, token, 'POST', input)
                if(data.message.startsWith('New')) {
                    setMes(l.tariff.created);
                } else {
                    setMes(l.tariff.exists);
                }
                setAddModal(false)
                setUpd(true)
            } catch (e) {
                setMes(l.errors[e])
            }
        }
    }

    const dellTariff = async (id) => {
        try {
            const data = await request(`${API}/tariff/delete/${id}`, token, 'DELETE')
            setMes(data.message)
            setUpd(true)
            setDellModal(null)
            setDellId(null)
        } catch (e) {
            setMes(l.errors[e])
        }
    }

    useEffect(() => {
        if (page > pages) {
            getTariffs(1).then()
            setPage(1)
            localStorage.setItem('tariff-page', '1')
        }
        if (page === null) {
            getTariffs(1).then()
            localStorage.setItem('tariff-page', '1')
        } else {
            getTariffs(page).then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(() => {
        for (let i = 1; i <= pages; i++) {
            arr.push(i)
        }
        if (arr.length > 1) {
            setPageArr(arr)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages])

    const goPage = (i) => {
        setPage(i + 1)
        localStorage.setItem('tariff-page', i + 1)
    }

    useEffect(() => {
        if (upd) {
            getTariffs(page).then()
        }

        if(role === 'admin') {
            loadOrganizations().then(data => {
                setOrganizations(data);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upd])

    // noinspection JSUnresolvedFunction
    return (
        <div className="tariff-page">
            <button className="tariff-add-btn" onClick={() => { setAddModal(true) }}><FontAwesomeIcon icon={faPlusSquare} size="lg" /> {l.tariff.add}</button>
            { tariffs && tariffs.length === 0 ? <p>{l.tariff.noTariffs}</p>: <></>}
            <div className="tariff-body">
                {tariffs ? tariffs.map((key, ignored) => (
                    <div className="tariff-block" key={key.id} >
                        <div className="tariff-item"><span className="tariff-icon"><FontAwesomeIcon icon={faChargingStation} /></span>{key.charge.toFixed(2)}</div>
                        <div className="tariff-item"><span className="tariff-icon"><FontAwesomeIcon icon={faStopwatch} /></span>{key.reserve.toFixed(2)}</div>
                        { role === 'admin' ? <div className="tariff-item" style={{fontSize: "10px"}}>{key.organizationName}</div> : <></> }
                        <button onClick={() => {
                            setDellModal(true)
                            setDellId(key.id)
                        }} className="tariff-item-btn"><FontAwesomeIcon className='close-btn' icon={faTimes} /></button>

                    </div>
                )) : <><div /><div /><Loaderr /><div /><div /></>}
            </div>
            {addModal ? <div className="tariffs-modal">
                <div className="tariffs-modal-body tariff-page-input">
                    <div className="tariffs-modal-box">
                        <FontAwesomeIcon icon={faChargingStation} size='2x' style={{ width: 32 }} />
                        <input
                            name="charge"
                            placeholder={l.tariff.chargeNum}
                            onChange={handleInput}
                            className="tariff-page-input-item" type="number" />
                        {l.points} / {l.connectors.kvt}
                    </div>
                    <div className="tariffs-modal-box">
                        <FontAwesomeIcon icon={faStopwatch} size='2x' style={{ width: 32 }} />
                        <input name="reserve"
                            placeholder={l.tariff.reserveNum}
                            onChange={handleInput}
                            className="tariff-page-input-item"
                            type="number" />
                        {l.points} / {l.connectors.min}
                    </div>

                    { role === 'admin' ? <div>
                        <br />
                        <div className="stations-modal-input-head">{l.usersPage.organization}</div>
                        <select style={{cursor: 'pointer', width: '100%'}} className="users-select" value={input.organizationId} name="organizationId" onChange={handleInput}>
                            {organizations.map((key, i) => {
                                return <option key={i} value={key.id}>{key.name}</option>
                            })}
                        </select>
                    </div> : <></> }

                    <div className="tariff-page-input-buttons">
                        <button onClick={addTariff} className="tariff-page-input-button">{l.tariff.add}</button>
                        <button onClick={() => {
                            setAddModal(false)
                            setInput(null)
                        }} className="tariff-page-input-button" style={{ backgroundColor: "rgb(255, 53, 73)" }}>{l.tariff.cancel}</button>
                    </div>
                </div>
            </div> : null}
            <div className='tariff-page-pagination'>
                <div className='tariff-page-nums' style={{ display: 'flex' }}>

                    {/*TODO remove duplicated code*/}

                    {pageArr ? pageArr.map((key, i) => (
                        key === 1 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='tab-page-num'>{key}</button> :
                            key === 1 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='tab-page-num picked'>{key}</button> :
                                key === 2 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='tab-page-num'>{key}</button> :
                                    key === 2 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='tab-page-num picked'>{key}</button> :
                                        currentPage === key ?
                                            <button key={i} onClick={() => { }} className='tab-page-num picked'>{key}</button> :
                                            key === currentPage - 3 ? <div key={i}>...</div> :
                                                key === currentPage + 3 && key !== pageArr.length - 1 && key !== pageArr.length ? <div key={i}>...</div> :
                                                    key === pageArr.length - 1 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='tab-page-num'>{key}</button> :
                                                        key === pageArr.length - 1 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='tab-page-num picked'>{key}erfl;</button> :
                                                            key === pageArr.length && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='tab-page-num'>{key}</button> :
                                                                key === pageArr.length && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='tab-page-num picked'>{key}erfr</button> :
                                                                    key > currentPage - 3 && currentPage + 3 > key ?
                                                                        <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='tab-page-num'>{key}</button> : null
                    )) : null}
                </div>
            </div>

            {dellModal || dellModal === "0" ? <>
                <div className="tariffs-modal">
                    <div className="tariffs-modal-body">
                        <div className="stations-modal-dell-text">
                            {l.tariff.dellMsg} {dellId}?
                        </div>
                        <div className="stations-modal-dell">
                            <button style={{ backgroundColor: "#FF3549" }} className="stations-modal-dell-btn" onClick={() => { dellTariff(dellId).then() }}>{l.tariff.dell}</button>
                            <button className="stations-modal-dell-btn" onClick={() => {
                                setDellModal(null)
                                setDellId(null)
                            }}>{l.tariff.cancel}</button>
                        </div>
                    </div>
                </div>
            </> : null}
        </div>
    )
}
