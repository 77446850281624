import React, { useEffect, useState, useContext } from 'react'
import { useMessage } from '../hooks/message.hook'
import { NavLink } from "react-router-dom"
import { useHttp } from '../hooks/http.hook'
import { API } from '../api'
import { LanguageContext } from '../context/LangContext'

export const ResetPassword = () => {

    let currentEmail = sessionStorage.getItem('currentEmail')
    const [l] = useContext(LanguageContext)
    const { request, error, clearError } = useHttp()
    const message = useMessage()
    const [mes, setMes] = useState(null)
    const [load, setLoad] = useState(false)
    const [email, setEmail] = useState(currentEmail)

    useEffect(() => {
        message(mes)
        message(error)
        clearError()
        setMes("")
    }, [error, message, clearError, mes])

    useEffect(() => {
        window.M.updateTextFields()
    }, [])

    const emailHandler = event => {
        setEmail(event.target.value)
    }

    const keyHandler = (event) => {
        let key = event.key;
        if (key === "Enter") {
            sentEmail()
        }
    }

    const validate = (email) => {
        // eslint-disable-next-line no-useless-escape
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const sentEmail = async () => {

        try {

            if(email.length > 50 || !validate(email)) {
                setMes(l.LoginPage.emailValidation);
                return;
            }

            const data = await request(`${API}/restorePassword/${email}?language=${l.lang}`, null, 'POST')
            setMes(data.message)
            setLoad(true)
        }
        catch (e) {
            setMes(l.errors[e])
            setLoad(true)
        }

    }

    if (load) {
        return (
            <div className="afterRegBlock">
                <div className="activayionpage-text">
                    <br />
                    {l.changePassword.emailCheck}
                </div>
                <NavLink className="afterRegBlock-text" to='/'>{l.registrationPage.main} </NavLink>
            </div>
        )
    }

    if(!l.changePassword) {
        console.log("l.changePassword is undefined!");
        console.log("l.changePassword = " + l.changePassword);
    }

    return (
        <div className="afterRegBlock">
            <div className="activayionpage-text">
                <input
                    className="input-area"
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={emailHandler}
                    onKeyPress={keyHandler}
                    required />
                {l.changePassword.enterEmail}<button className='pass-fog-btn' onClick={sentEmail}>{l.changePassword.btn}</button>
            </div>
        </div>
    )
}
