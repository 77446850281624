import React, {useEffect, useState, useContext, useRef, useCallback} from 'react'
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import { useMessage } from '../../hooks/message.hook'
import { useHttp } from '../../hooks/http.hook'
import { API } from '../../api'
import { LanguageContext } from '../../context/LangContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faMinusCircle, faPlusCircle, faTimes, faCrosshairs, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { Position } from '../MapPage/icons/Icon'
import './Stations.css'
import { AuthContext } from '../../context/AuthContext'
import useOnClickOutside from '../../hooks/useOnClickOutside.hook';
import { public_center } from "../MapPage/MapPage";

export default function Add({ modal, setModal, setUpdate }) {

    const { token, organizations, role, organizationId } = useContext(AuthContext)
    const [l] = useContext(LanguageContext)
    const { request, error, clearError } = useHttp()
    const message = useMessage()
    const [mes, setMes] = useState(null)
    const [tariffs, settariffs] = useState()
    const [con, setCon] = useState()
    const [map, setMap] = useState()
    const [addMap, setAddMap] = useState(false)
    const mapRef = useRef(null)
    const [zoom, setZoom] = useState(12)
    const [disabled, setDisabled] = useState(false)
    const [center, setCenter] = useState(public_center)
    const addFrame = useRef()

    const [connectors, setConnectors] = useState([
        {
            number: 1,
            type: "Type2",
            power: 50,
            tariffId: undefined,
        }
    ])
    const [connectorsNum, setConnectorsNum] = useState(1)

    const [conAdd, setConAdd] = useState({
        model: '',
        serialNumber: '',
        firmwareVersion: '',
        iccid: '',
        imsi: '',
        tittle: '',
        address: '',
        workTime: '',
        latitude: 0,
        longitude: 0,
        organizationId: organizationId,
        connectors: connectors
    })

    useEffect(() => {
        message(mes)
        message(error)
        clearError()
        setMes(null)
    }, [error, message, clearError, mes])

    useEffect(() => {
        window.M.updateTextFields()
        getTarif().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTarif = async () => {
        try {
            const tariffs = await request(`${API}/get/all/tariffs`, token)
            const con = await request(`${API}/get/connector/types`, token)

            setCon(con)
            settariffs(tariffs)
            console.clear()
        } catch (e) {
            if (e === 'TypeError: Failed to fetch') {
                setMes("No internet")
            }
            setMes(l.errors[e])
        }
    }

    const addStation = async () => {
        try {
            const data = await request(`${API}/chargePoint/create`, token, "POST", conAdd)
            setMes(data.message)
            setModal(false)
            setUpdate(true)
            setMes(l.toast[data.toast])
        } catch (e) {
            setMes(l.errors[e])
        }
    }

    useEffect(() => {
        setConAdd({ ...conAdd, connectors: connectors })
        if (connectors[0].tariffId === undefined) {
            setDisabled(true)
        } else (
            setDisabled(false)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectors])

    const connectorHandler = (e, index) => {
        const { name, value } = e.target
        const con = [...connectors]
        con[index][name] = value
        setConnectors(con)
    }

    const stationHandler = (e) => {
        const { name, value } = e.target
        setConAdd({ ...conAdd, [name]: value })
    }

    const addHandler = (e) => {
        // noinspection JSCheckFunctionSignatures
        setConnectors([...connectors, {
            number: e + 1,
            type: "Type2",
            power: 100,
            tariffId: 0,
        }])
    }

    const handleRemoveClick = index => {
        const list = [...connectors]
        list.splice(index, 1)
        setConnectors(list)
    }

    useOnClickOutside(addFrame, () => {
        if (modal&& !addMap) {
            setModal(false)
        }
    })

    // noinspection JSUnresolvedFunction,HttpUrlsUsage
    return (<>
        <div className="stations-modal">
            <div className="stations-mob-body">
                <div className="stations-modal-body" ref={addFrame}>
                    <button className="stations-modal-close-btn" onClick={() => {
                        setModal(false)
                    }}><FontAwesomeIcon icon={faTimes} size="lg" /></button>
                    <div className="stations-modal-input-block">
                        <div>
                            <div className="stations-modal-input-head">{l.stations.chargePointModel}</div>
                            <input className="stations-modal-input" value={conAdd.model} name="model" onChange={e => stationHandler(e)} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.chargePointSerialNumber}</div>
                            <input className="stations-modal-input" value={conAdd.serialNumber} name="serialNumber" onChange={e => stationHandler(e)} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.firmwareVersion}</div>
                            <input className="stations-modal-input" value={conAdd.firmwareVersion} name="firmwareVersion" onChange={e => stationHandler(e)} /></div>

                        <div>
                            <div className="stations-modal-input-head">{l.stations.tittle}</div>
                            <input className="stations-modal-input" value={conAdd.tittle} name="tittle" onChange={e => stationHandler(e)} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.address}</div>
                            <input className="stations-modal-input" value={conAdd.address} name="address" onChange={e => stationHandler(e)} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.workTime}</div>
                            <input className="stations-modal-input" value={conAdd.workTime} name="workTime" onChange={e => stationHandler(e)} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.lat}</div>
                            <input className="stations-modal-input" value={conAdd.latitude} name="latitude" onChange={e => stationHandler(e)} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.lng}</div>
                            <input className="stations-modal-input" style={{ width: 148 }} value={conAdd.longitude} name="longitude" onChange={e => stationHandler(e)} />
                            <button style={{ margin: "unset" }} className="add-btn-btn" onClick={() => setAddMap(true)}><FontAwesomeIcon icon={faMapMarkedAlt} /></button>
                        </div>
                        { role === 'admin' ? <div>
                            <div className="stations-modal-input-head">{l.usersPage.organization}</div>
                            <select style={{cursor: 'pointer', width: '100%'}} className="users-select" value={conAdd.organizationId} name="organizationId" onChange={stationHandler}>
                                {organizations ? organizations.map((key, i) => {
                                    return <option key={i} value={key.id}>{key.name}</option>
                                }) : <></>}
                            </select>
                        </div> : <></> }
                    </div>
                    <div className="stations-modal-input-head">
                        <div>
                            <div className="stations-modal-connectors-title">{l.stations.connectors}</div>
                            {connectors ? connectors.map((key, i) => (
                                <div className="stations-modal-connectors" key={i}>
                                    <div className="stations-modal-connectors-num">
                                        {key.number === 1 ? <div style={{ padding: 2 }}>{key.number}</div> : <>{key.number}</>}
                                    </div>
                                    <div>
                                        <div className="stations-modal-connectors-pairs">
                                            <div>
                                                <div className="stations-modal-connectors-head">{l.stations.type}</div>
                                                {con ? <select className="stations-modal-type" name="type" value={key.type} onChange={(e) => { connectorHandler(e, i) }}>
                                                    {con.map((key, i) => (
                                                        <option key={i} value={key}>{key}</option>
                                                    ))}
                                                </select> : null}
                                            </div>
                                            <div style={{ margin: "0 10px" }}>
                                                <div className="stations-modal-connectors-head">{l.stations.power}</div>
                                                <input className="stations-modal-connectors-power" name="power" value={key.power} onChange={(e) => { connectorHandler(e, i) }} type="number" />
                                            </div>
                                        </div>
                                        <div className="stations-modal-connectors-pairs">
                                            <div>
                                                <div className="stations-modal-connectors-head">{l.stations.tariff}</div>
                                                {tariffs ? <select className="stations-modal-tariff-id" name="tariffId" onChange={(e) => { connectorHandler(e, i) }}>
                                                    <option selected disabled hidden>id</option>
                                                    {tariffs.map((key, i) => {
                                                        if (key === 0) {
                                                            return (<option key={i} value={key.id}>{key.charge} {key.reserve}</option>)
                                                        }
                                                        return (<option key={i} value={key.id}>{key.charge} {key.reserve}</option>)
                                                    })}
                                                </select> : null}
                                            </div>
                                        </div>
                                    </div>
                                    {key.number === 2 ? <div className="stations-modal-connectors-add-box">
                                        <button className="stations-modal-connectors-add" style={{ color: "#FF3549" }} onClick={() => {
                                            handleRemoveClick(i)
                                            setConnectorsNum(connectorsNum - 1)
                                        }}>
                                            <FontAwesomeIcon icon={faMinusCircle} />
                                        </button>
                                    </div> : null}
                                    {connectorsNum < 2 ? <div className="stations-modal-connectors-add-box">
                                        <button className="stations-modal-connectors-add" style={{ color: "#41A350" }} onClick={() => {
                                            addHandler(connectorsNum)
                                            setConnectorsNum(connectorsNum + 1)
                                        }}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </button>
                                    </div> :
                                        null}
                                </div>
                            )) : null}
                        </div>
                    </div>
                    <div className="stations-modal-connectors-btn-body">
                        <button
                            disabled={disabled}
                            className="stations-modal-connectors-btn"
                            style={disabled ? { backgroundColor: "gray" } : {}}
                            onClick={() => {
                                addStation().then()
                            }}> <FontAwesomeIcon icon={faMapMarkerAlt} />{l.stations.add}</button>
                    </div>
                </div>

            </div>

        </div>
        <div className="stations-modal-bg" onClick={() => setModal(false)} />
        {addMap ?
            <div className="stations-modal-map">
                <div className='map-window-add'>
                    <div ref={mapRef} className='map-body' data-tap-disabled="true">
                        <MapContainer
                            fullscreenControl={true}
                            maxZoom={18}
                            whenCreated={setMap}
                            center={center}
                            zoom={zoom}
                            scrollWheelZoom={window.innerWidth >= 540} >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {map && mapRef ? <MoveToCurrentLocation
                                setZoo={setZoom}
                                setAddMap={setAddMap}
                                map={map}
                                conAdd={conAdd}
                                setConAdd={setConAdd}
                                setCenter={setCenter}
                                rrr={mapRef.current} /> : null}
                        </MapContainer >
                    </div>
                </div>
            </div> : null}
    </>
    )
}


const MoveToCurrentLocation = ({ map, setConAdd, conAdd, setAddMap, setCenter }) => {

    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [marker, setMarker] = useState(false)
    const [position, setPosition] = useState(map.getCenter())
    const [l] = useContext(LanguageContext)

    // TODO understand it
    const onMove = useCallback(() => {
        setPosition(map.getCenter());
    }, [map])

    useEffect(() => {
        if(!map) {
            return;
        }
        map.on('moveend', onMove)
        return () => {
            map.off('moveend', onMove)
        }
    }, [map, onMove])

    const Pos = (pos) => {
        let crd = pos.coords
        setLatitude(crd.latitude)
        setLongitude(crd.longitude)
    }

    useEffect(() => {
        setCenter([position.lat.toFixed(11), position.lng.toFixed(11)])
        setConAdd({ ...conAdd, latitude: position.lat.toFixed(11), longitude: position.lng.toFixed(11) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position, latitude])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(Pos)
    }, [])

    const goToMyPosition = () => {
        if (latitude && longitude) {
            map.flyTo([latitude, longitude], 13)
            console.log('setMarker(true)');
            setMarker(true);
        } else {
            alert("development in process")
        }
    }

    const goToMarker = (e) => {
        const { lat, lng } = e.latlng
        map.flyTo([lat, lng], 18)
    }
    const event = useMapEvents({
        moveEnd() {
            event.locate()
        },
        locationFound() {
            setPosition(map.getCenter())
        },
    })

    return (<>
        <div className="leaflet-top leaflet-left" style={{ marginTop: 70 }}>
            <div className="leaflet-control leaflet-bar">
                <div
                    onClick={goToMyPosition}
                    style={{
                        backgroundColor: "#fff",
                        height: 30,
                        width: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: "pointer",
                        borderRadius: 4
                    }}>
                    <FontAwesomeIcon icon={faCrosshairs} size="lg" />
                </div>
            </div>
            <Marker
                position={[position.lat, position.lng]}
                icon={Position}
                eventHandlers={{ click: (event) => { goToMarker(event) } }}>
            </Marker>

            {marker ? <Marker
                position={[latitude, longitude]}
                icon={Position}
                eventHandlers={{ click: (event) => { goToMarker(event) } }}>
            </Marker> : null}


        </div>
        <div className="add-btn-box">
            <button className="add-btn-btn" onClick={() => setAddMap(false)}>{l.stations.savePosition}</button>
        </div>
    </>
    )
}

