import React, { useEffect, useState, useContext, useRef } from 'react'
import { useMessage } from '../../hooks/message.hook'
import { useHttp } from '../../hooks/http.hook'
import { API } from '../../api'
import { LanguageContext } from '../../context/LangContext'
import { AuthContext } from '../../context/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import './Stations.css'
import useOnClickOutside from '../../hooks/useOnClickOutside.hook';

export default function Edit({edit, data, setEdit, setUpdate }) {

    const { token, organizations, role } = useContext(AuthContext)
    const [l] = useContext(LanguageContext)
    const id = data.id
    const { request, error, clearError } = useHttp()
    const message = useMessage()
    const [mes, setMes] = useState(null)
    const [tariffs, setTariffs] = useState()
    const [con, setCon] = useState()
    const [editConnectors, setEditConnectors] = useState(data.connectors)
    const [connectorsNum, setConnectorsNum] = useState(data.connectors.length)

    const [editState, setEditState] = useState({
        model: data.model,
        serialNumber: data.serialNumber,
        firmwareVersion: data.firmwareVersion,
        iccid: data.iccid,
        imsi: data.imsi,
        tittle: data.tittle,
        address: data.address,
        workTime: data.workTime,
        latitude: data.latitude,
        longitude: data.longitude,
        connectors: editConnectors,
        organizationId: data.organizationId
    })
    const editFrame = useRef()

    const stationHandler = (e) => {
        const { name, value } = e.target
        setEditState({ ...editState, [name]: value })
    }

    useEffect(() => {
        message(mes)
        message(error)
        clearError()
        setMes(null)
    }, [error, message, clearError, mes])

    useEffect(() => {
        window.M.updateTextFields()
        getTariff().then()
        console.clear()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        setEditState({ ...editState, connectors: editConnectors })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editConnectors])

    const getTariff = async () => {
        try {
            const tariffs = await request(`${API}/get/all/tariffs`, token)
            const con = await request(`${API}/get/connector/types`, token)
            setCon(con)
            setTariffs(tariffs)
            setUpdate(true)

        } catch (e) {
        }
    }

    const connectorHandler = (e, index) => {
        const { name, value } = e.target
        const con = [...editConnectors]
        con[index][name] = name === "power" ? Number(value) : name === "tariffId" ? Number(value) : value
        setEditConnectors(con)
    }

    const handleRemoveClick = index => {
        const list = [...editConnectors]
        list.splice(index, 1)
        setEditConnectors(list)
    }

    const editHandler = async () => {
        try {
            const data = await request(`${API}/chargePoint/update/${id}`, token, "POST", editState)
            setTariffs(data.info)
            setEdit()
            setUpdate(true)
            setMes(l.toast[data.toast])
        } catch (e) {
            setMes(l.errors[e])
        }
    }

    // TODO check what happens if there are no tariffs
    const addHandler = (e) => {
        setEditConnectors([...editConnectors, {
            number: e + 1,
            type: "Type2",
            power: 100,
            status: "work",
            tariff: {charge: tariffs[0].charge, reserve: tariffs[0].organizationId},
            tariffId: tariffs[0].id
        }])
    }

    useOnClickOutside(editFrame, () => {
        if (edit) {
            setEdit(false)
        }
    })

    console.log(organizations);

    console.log(editState.organizationId);

    // noinspection JSUnresolvedFunction
    return (
        <div className="stations-modal">
            <div className="stations-mob-body">
                <div className="stations-modal-body" ref={editFrame}>
                    <button className="stations-modal-close-btn" onClick={() => {
                        setEdit()
                    }}><FontAwesomeIcon icon={faTimes} size="lg" /></button>
                    <div className="stations-modal-input-block">
                        <div>
                            <div className="stations-modal-input-head">{l.stations.chargePointModel}</div>
                            <input className="stations-modal-input" value={editState.model} name="model" onChange={stationHandler} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.chargePointSerialNumber}</div>
                            <input className="stations-modal-input" value={editState.serialNumber} name="serialNumber" onChange={stationHandler} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.firmwareVersion}</div>
                            <input className="stations-modal-input" value={editState.firmwareVersion} name="firmwareVersion" onChange={stationHandler} /></div>

                        <div>
                            <div className="stations-modal-input-head">{l.stations.tittle}</div>
                            <input className="stations-modal-input" value={editState.tittle} name="tittle" onChange={stationHandler} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.address}</div>
                            <input className="stations-modal-input" value={editState.address} name="address" onChange={stationHandler} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.workTime}</div>
                            <input className="stations-modal-input" value={editState.workTime} name="workTime" onChange={stationHandler} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.lat}</div>
                            <input className="stations-modal-input" value={editState.latitude} name="latitude" onChange={stationHandler} />
                        </div>
                        <div>
                            <div className="stations-modal-input-head">{l.stations.lng}</div>
                            <input className="stations-modal-input" value={editState.longitude} name="longitude" onChange={stationHandler} />
                        </div>
                        { role === 'admin' ? <div>
                            <div className="stations-modal-input-head">{l.usersPage.organization}</div>
                            <select style={{cursor: 'pointer', width: '100%'}} className="users-select" value={editState.organizationId} name="organizationId" onChange={stationHandler}>
                                {organizations.map((key, i) => {
                                    return <option key={i} value={key.id}>{key.name}</option>
                                })}
                            </select>
                        </div> : <></>}
                    </div>
                    <div className="stations-modal-input-head">
                        <div className="stations-modal-connectors-title">{l.stations.connectors}</div>
                        {editConnectors.map((key, i) => (
                            <div className="stations-modal-connectors" key={i}>
                                <div className="stations-modal-connectors-num">
                                    {key.number === 1 ? <div style={{ padding: 2 }}>{key.number}</div> : <>{key.number}</>}
                                </div>
                                <div>
                                    <div className="stations-modal-connectors-pairs">
                                        <div>
                                            <div className="stations-modal-connectors-head">{l.stations.type}</div>
                                            {con ? <select className="stations-modal-type" name="type" value={key.type} onChange={(e) => { connectorHandler(e, i) }}>
                                                {con.map((key, i) => {
                                                    if (key === 0) {
                                                        return (<option key={i} value={key}>{key}</option>)
                                                    }
                                                    return (<option key={i} value={key}>{key}</option>)
                                                })}
                                            </select> : null}
                                        </div>
                                        <div style={{ margin: "0 10px" }}>
                                            <div className="stations-modal-connectors-head">{l.stations.power}</div>
                                            <input className="stations-modal-connectors-power" name="power" value={key.power} onChange={(e) => { connectorHandler(e, i) }} type="number" />
                                        </div>
                                    </div>
                                    <div className="stations-modal-connectors-pairs">

                                        <div>
                                            <div className="stations-modal-connectors-head">{l.stations.status}</div>
                                            <select className="stations-modal-type" value={key.status} name="status" onChange={(e) => { connectorHandler(e, i) }}>
                                                <option selected value="work">{l.mapOptions.work}</option>
                                                <option value="busy">{l.mapOptions.busy}</option>
                                                <option value="alert">{l.mapOptions.alert}</option>
                                                <option value="connected">{l.mapOptions.connected}</option>
                                                <option value="reserved">{l.mapOptions.reserved}</option>
                                            </select>
                                        </div>

                                        <div style={{ margin: "0 10px" }}>
                                            <div className="stations-modal-connectors-head">{l.stations.tariff}</div>
                                            {tariffs ? <select className="stations-modal-tariff-id" name="tariffId" value={key.tariffId} onChange={(e) => { connectorHandler(e, i) }}>
                                                {tariffs.map((key, i) => {
                                                    if (i === 0) {
                                                        return (<option selected key={i} value={key.id}>{key.charge} {key.reserve}</option>)
                                                    }
                                                    return (<option key={i} value={key.id}>{key.charge} {key.reserve}</option>)
                                                })}
                                            </select> : null}
                                        </div>
                                    </div>
                                </div>
                                {key.number === 2 ? <div className="stations-modal-connectors-add-box">
                                    <button className="stations-modal-connectors-add" style={{ color: "#FF3549" }} onClick={() => {
                                        handleRemoveClick(i)
                                        setConnectorsNum(connectorsNum - 1)
                                    }}>
                                        <FontAwesomeIcon icon={faMinusCircle} />
                                    </button>
                                </div> :
                                    connectorsNum < 2 ? <div className="stations-modal-connectors-add-box">
                                        <button className="stations-modal-connectors-add" style={{ color: "#41A350" }} onClick={() => {
                                            addHandler(connectorsNum)
                                            setConnectorsNum(connectorsNum + 1)
                                        }}>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </button>
                                    </div> :
                                        <div style={{ width: 30 }}/>}
                            </div>
                        ))
                        }
                    </div>
                    <div className="stations-modal-connectors-btn-body">
                        <button className="stations-modal-connectors-btn" onClick={() => editHandler()}>
                            {l.stations.edit}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
