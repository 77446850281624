import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Connectors } from './pages/Connectors/Connectors'
import { AuthPage } from './pages/AuthPage'
import { MainPage } from './pages/MainPage/MainPage'
import { MapPage } from './pages/MapPage/MapPage'
import { RegistrationPage } from './pages/RegistrationPage'
import { ActivatePage, ConfirmNewPassword } from './pages/ActivatePage'
import { User } from './pages/User'
import { ResetPassword } from './pages/ChangePassword'
import { Statistics } from './pages/Statistics/Statistics'
import { MoneyUp } from './pages/MoneyUp'
import { Tariffs } from './pages/Tariffs'
import { Stations } from './pages/Stations/Stations'
import { UsersPage } from './pages/UsersPage'
import {Organizations} from "./pages/Organizations/Organizations";
import {Reports} from "./pages/Reports";
import {Contract} from "./pages/Contract";
import {TariffsAndRules} from "./pages/tariffsAndRules/TariffsAndRules";
import {PrivacyPolicy} from "./pages/PrivacyPolicy";

// TODO optimize routes when isAuthenticated or not
export const useRoutes = ({ isAuthenticated, id, role }) => {

    let loc = JSON.parse(localStorage.getItem("path"))

    // TODO write less lines
    if (isAuthenticated) {
        return (
            <Switch>
                <Route path="/" exact>
                    <MainPage />
                </Route>
                <Route path="/privacy-policy" exact>
                    <PrivacyPolicy />
                </Route>
                <Route path="/getMoney" exact>
                    <MoneyUp />
                </Route>
                <Route path="/map" exact>
                    <MapPage />
                </Route>
                <Route path="/map/station:connectorId" exact>
                    <MapPage />
                    <Connectors />
                </Route>
                <Route path="/contract" exact>
                    <Contract />
                </Route>
                <Route path="/tariffsAndRules" exact>
                    <TariffsAndRules />
                </Route>

                {role === 'admin' || role === 'operator' ?
                    <Route path="/users" exact>
                        <UsersPage />
                    </Route>

                    : null}
                {role === 'admin' || role === 'operator' ?
                <Route path="/reports" exact>
                    <Reports />
                </Route> : null }

                {role === 'admin' || role === 'operator' ?
                    <Route path="/tariffs" exact>
                        <Tariffs />
                    </Route> : null}

                <Route path="/myStatistics" exact>
                    <Statistics />
                </Route>
                {role === 'admin' || role === 'operator' ?
                    <Route path="/userStatistics/:path" exact>
                        <Statistics />
                    </Route> : role === "user" ?
                        <Route path="/userStatistics/:path" exact>
                            <Redirect to="/myStatistics" />
                        </Route> : null}
                {role === 'admin' || role === 'operator' ?
                    <Route path="/stations" exact>
                        <Stations />
                    </Route> : null}
                {role === 'admin' ?
                    <Route path="/organizations" exact>
                        <Organizations />
                    </Route> : null}

                <Route path="/user/:id" exact>
                    <User />
                </Route>
                <Route path="/login" exact>
                    <Redirect to="/" />
                </Route>
                <Route path="/registration" exact>
                    <Redirect to="/" />
                </Route>
                <Route path="/activateEmail/:code" exact>
                    <ActivatePage />
                </Route>
                <Route path="/confirmNewPassword/:id/:code" exact>
                    <ConfirmNewPassword />
                </Route>
                <Route path="/user" exact>
                    <Redirect to={`/user/${id}`} />
                </Route>
                {loc === '/map' ? <Redirect to={loc} /> : <Redirect to="/" />}
            </Switch>
        )
    }

    return (
        <Switch>
            <Route path="/" exact>
                <MainPage />
            </Route>
            <Route path="/privacy-policy" exact>
                <PrivacyPolicy />
            </Route>
            <Route path="/map" exact>
                <MapPage />
            </Route>
            <Route path="/login" exact>
                <AuthPage />
            </Route>
            <Route path="/registration" exact>
                <RegistrationPage />
            </Route>
            <Route path="/activateEmail/:code" exact>
                <ActivatePage />
            </Route>
            <Route path="/confirmNewPassword/:id/:code" exact>
                <ConfirmNewPassword />
            </Route>
            <Route path="/passForgot" exact>
                <ResetPassword />
            </Route>
            <Route path="/contract" exact>
                <Contract />
            </Route>
            <Route path="/tariffsAndRules" exact>
                <TariffsAndRules />
            </Route>
            <Redirect to="/" />
        </Switch>
    )
}
