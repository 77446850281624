import { useState, useCallback } from 'react'


export const useHttp = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const request = useCallback(async (url, token, method = 'GET', body = null,) => {
        setLoading(true)
        let headers = {}
        try {
            if (body) {
                body = JSON.stringify(body)
                headers['Content-Type'] = 'application/json';
            }

            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }

            const response = await fetch(url, {
                method: method,
                body: body,
                headers: headers
            })

            const data = await response.json()

            if (!response.ok) {

                // TODO something with it
                throw (data.error || data.message || 'wrong')
            }
            setLoading(false)

            return data

        } catch (e) {
            if (e === "AccessDenied") {
                // localStorage.setItem("role", JSON.stringify({ role: 'user' }))
                // sessionStorage.setItem("role", JSON.stringify({ role: 'user' }))
            }
            if (e === "Unauthorized") {
                window.location.reload()
                localStorage.removeItem('userData')
                localStorage.removeItem('role')
                sessionStorage.removeItem('userData')
                sessionStorage.removeItem('role')
            }

            setLoading(false)
            throw e
        }

    }, [])

    const clearError = useCallback(() => setError(null), [])

    return { loading, request, error, clearError }
}
