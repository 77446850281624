import React, { useContext, useEffect, useState } from 'react'
import { useHttp } from '../hooks/http.hook'
import { useMessage } from '../hooks/message.hook'
import { AuthContext } from '../context/AuthContext'
import { LanguageContext } from '../context/LangContext'
import jwt from 'jsonwebtoken'
import M from 'materialize-css/dist/js/materialize.min.js'
import './style/AuthPage.css'
import { NavLink } from 'react-router-dom'
import { API } from '../api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const AuthPage = () => {

    const { loginFunction, login, password, setLogin, setPassword } = useContext(AuthContext)
    const [l] = useContext(LanguageContext)
    const message = useMessage()
    const { loading, request, error, clearError } = useHttp()
    const [passwordShow, setPasswordShow] = useState(false)
    const [mes, setMes] = useState(null)
    let loc = JSON.parse(localStorage.getItem("path"))
    const [token, setToken] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const { session, setSession, setOrganization, setOrganizationId } = useContext(AuthContext)
    const { executeRecaptcha } = useGoogleReCaptcha()

    useEffect(() => {
        document.title = `${l.pages.authPage}`
        M.updateTextFields()
    })
    
    useEffect(() => {
        if (phoneNumber) {
            setToken(jwt.sign({ phoneNumber: phoneNumber.replace('+', ''), password: password }, 'chargePoint', { algorithm: 'HS512' }))
        } else {
            setToken(jwt.sign({ email: login, password: password }, 'chargePoint', { algorithm: 'HS512' }))
        }
        if (login) {
            if (login[0] === '+') {
                setPhoneNumber(login)
            } else {
                setPhoneNumber(undefined)
            }

        } else {
            setPhoneNumber(undefined)
        }

    }, [login, password, phoneNumber]);

    useEffect(() => {
        message(mes)
        setMes(null)
        clearError()
        setMes(null)
    }, [error, mes, message, clearError])

    const emailHandler = event => {
        setLogin(event.target.value.trim())
    }
    const passwordHandler = event => {
        setPassword(event.target.value)
    }

    const Session = (e) => {
        setSession(e.target.checked)
    }

    const validate = (email) => {
        // eslint-disable-next-line no-useless-escape
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const phoneValidate = (phone) => {
        let re = /^((\+)[-]?)?(\(?\d{3}\)?[-]?)?[\d\- ]{7,10}$/
        return re.test(phone);
    }

    const checkPhone = (phone, password) => {

        if (!phoneValidate(phone)) {
            setMes(l.LoginPage.phoneValidate)
            return false
        }

        if (password.length < 8) {
            setMes(l.LoginPage.lowPass)
            return false
        }
        setMes(null)
        return true
    }

    const checkEmail = (email, password) => {

        if (!validate(email)) {
            setMes(l.LoginPage.emailValidation)
            return false
        }
        if (password.length < 8) {
            setMes(l.LoginPage.lowPass)
            return false
        }
        setMes(null)
        return true

    }

    const keyHandler = (event) => {
        let key = event.key;
        if(event.target.name === 'email' && key === "Enter") {
            document.querySelector('input[name=password]').focus();
            return '';
        }
        if (key === "Enter") {
            getToken()
        }
    }

    const getToken = () => {
        executeRecaptcha("login").then((value) => {
            loginHandler(value).then();
        }, (reason) => {
            setMes("captcha error")
            console.log("can't get captcha, you are robot!", "err " + reason)
        })
    }

    const loginHandler = async (captcha) => {

        if (phoneNumber) {
            if (checkPhone(login, password)) {
                try {
                    const data = await request(`${API}/authByPhone`, null, 'POST', {
                        token: token,
                        captchaResponse: captcha
                    })
                    setMes(l.toast[data.toast])
                    console.log(data);
                    setOrganization(data.organization);
                    setOrganizationId(data.organizationId);
                    localStorage.setItem('organization', data.organization);
                    localStorage.setItem('organizationId', data.organizationId);
                    loginFunction(data, session);
                } catch (e) {
                    setMes(l.errors[e])
                }
            }
        } else {
            if (checkEmail(login, password)) {
                try {
                    const data = await request(`${API}/authByEmail`, null, 'POST', {
                        token: token,
                        captchaResponse: captcha
                    })
                    setMes(l.toast[data.toast])
                    localStorage.setItem('w', JSON.stringify(data))
                    console.log(data);
                    setOrganization(data.organization);
                    setOrganizationId(data.organizationId);
                    localStorage.setItem('organization', data.organization);
                    localStorage.setItem('organizationId', data.organizationId);
                    loginFunction(data);
                } catch (e) {
                    setMes(l.errors[e])
                }
            }
        }
    }

    sessionStorage.setItem('currentEmail', login)

    return (
        <div className="registratin">
            <div className="cont">
                <div className="wrap-login">
                    <div className="login-form" >
                        <div className="login-form-title">
                            <NavLink to={loc ? `${loc}` : '/'}><FontAwesomeIcon icon={faArrowLeft} size="lg" /></NavLink>&nbsp;&nbsp;{l.LoginPage.tittle}
                        </div>
                        <div className="login-text-button">
                            {l.LoginPage.noAcc}
                            <NavLink className="registration-text" to='/registration'> {l.LoginPage.reg} </NavLink>
                        </div>
                        <div className="wrap-input" data-validate="Email is required">
                            <input
                                className="input-area"
                                type="text"
                                name="email"
                                placeholder={l.LoginPage.emailPh}
                                value={login}
                                onChange={emailHandler}
                                onKeyPress={keyHandler}
                                required
                            />
                            <div className="login-text-button" >
                                {l.LoginPage.forgot}
                                <NavLink className="registration-text" to='/passForgot'> {l.LoginPage.restore} </NavLink>
                            </div>
                            <span className="focus-input"/>
                        </div>

                        <div className="wrap-input" data-validate="Password is required">
                            <button className='showpassword-btn-auth' onClick={() => setPasswordShow(!passwordShow)}>
                                <FontAwesomeIcon icon={passwordShow ? faEye : faEyeSlash} size="lg" />
                            </button>
                            <input
                                className="input-area"
                                type={passwordShow ? "text" : "password"}
                                name="password"
                                placeholder={l.LoginPage.passwordPh}
                                value={password}
                                onChange={passwordHandler}
                                onKeyPress={keyHandler}
                                required
                            />
                            <span className="focus-input"/>
                            <div className='enemy-pc' title={l.LoginPage.anotherDeviceInfo}>{l.LoginPage.anotherDevice}
                                <input
    style={{cursor: 'pointer'}}
    type='checkbox'
    checked={session}
    name="typeFast"
    onChange={Session}/></div>
                        </div>

                        <div className="btns">
                            <button
                                disabled={loading}
                                type="submit"
                                placeholder="login"
                                className="button-login pulse"
                                onClick={getToken}
                                onKeyPress={keyHandler}
                            >{l.LoginPage.button}
                            </button>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}
