import React, { useContext, useState, useEffect } from 'react'
import { API } from '../api'
import { AuthContext } from '../context/AuthContext'
import { useHttp } from '../hooks/http.hook'
import { useMessage } from '../hooks/message.hook'
import './style/EditPage.css'
import {LanguageContext} from "../context/LangContext";
import PhoneInput from 'react-phone-input-2';

const EditPage = ({lang, data, setOpenEdit}) => {

    const [userData, setUserData] = useState(data)
    const { request } = useHttp()
    const {loginFunction, token} = useContext(AuthContext)
    const checkDataChanges = {}
    const message = useMessage()
    const [l] = useContext(LanguageContext);

    const checkData = (param) => {
        switch (param) {
            case 'firstName': {
                if (userData.firstName.length <= 3 || userData.firstName.length > 30) {
                    message(lang.editPage.correctName)
                    return false
                }else {
                    return true;
                }
            }
            case 'lastName': {
                if (userData.lastName.length < 1 || userData.lastName.length > 30) {
                    message(lang.editPage.correctSurname)
                    return false
                }else{
                    return true;
                }
            }
            case 'email': {
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!re.test(userData[param])) message(lang.editPage.correctEmail);
                return re.test(userData[param]);
            }
            case 'phoneNumber': {
                console.log(userData[param])
                let re = /^((\+)[-]?)?(\(?\d{3}\)?[-]?)?[\d\- ]{7,10}$/
                if(!re.test(userData[param])) message(lang.editPage.correctPhone);
                return re.test(userData[param]);
            }
            case 'markAndModel': {
                if (userData.markAndModel.length < 1 || userData.markAndModel.length > 40) {
                    message(lang.editPage.correctMark)
                    return false
                }else{
                    return true;
                }
            }
            default: {
                console.log('unknown case');
            }
        }
    }

    const editData = async () => {
            let check = true;
            checkDataChanges.firstName = userData.firstName !== data.firstName;
            checkDataChanges.lastName = userData.lastName !== data.lastName;
            checkDataChanges.email = userData.email !== data.email;
            checkDataChanges.markAndModel = userData.markAndModel !== data.markAndModel;
            checkDataChanges.phoneNumber = userData.phoneNumber !== data.phoneNumber;

            try {
                for (let key in checkDataChanges){

                    if(checkDataChanges[key]) {
                        check = checkData(key)
                        if(!check) return '';
                        const url = `${API}/api/changeParam?paramName=${key}&paramValue=${userData[key]}&language=${l.lang}`;
                        const data = await request(url, token, 'PUT')
                        check = 'showNewData'

                        const obj = {...JSON.parse(localStorage.getItem('userData')), token: data.token};
                        obj[key] = userData[key];
                        console.log(key)
                        if(key === 'phoneNumber') {
                            obj.phoneConfirmed = false;
                        } else if(key === 'email') {
                            obj.emailConfirmed = false;
                        }
                        loginFunction(obj);
                    }

                }
                message(lang.editPage.msgEdit);

            } catch (e) {
                message(lang.errors.unknownError);
            }
    }

    useEffect(() => {
        let phoneInput = document.querySelector('.input-area.form-control');
        let flag = document.querySelector('.selected-flag');
        switch(flag.title) {
            case 'Ukraine: + 380':
                phoneInput.placeholder = '+380 (50) 123 45 67';
            break;
            case 'Russia: + 7':
                phoneInput.placeholder = '+7 (495) 123 45 67';
            break;
            case 'Belarus: + 375':
                phoneInput.placeholder = '+375 (29) 123 45 67';
            break;
            default:
            return;       
        }
    })

    return (
        <div className='edit_page'>

            <div className='labels'>
                <label>{lang.userPage.name}
                    <input className="input-area" onChange={(e) => setUserData({...userData, firstName: e.target.value})}
                           value={userData.firstName} required/>
                </label>
                <label>{lang.userPage.surname}
                    <input className="input-area" onChange={(e) => setUserData({...userData, lastName: e.target.value})}
                           value={userData.lastName} required/>
                </label>
                <label>{lang.userPage.email}
                    <input className="input-area" onChange={(e) => setUserData({...userData, email: e.target.value})}
                           value={userData.email} required/>
                </label>
                <label>{lang.userPage.phone}
                <div>
                    <PhoneInput
                        inputProps={{
                        name: 'phone',
                        required: true,
                        }}
                        containerClass="label-phone"
                        inputClass="input-area"
                        onlyCountries={['by', 'ru', 'ua']}
                        country={'ua'}
                        value={userData.phoneNumber}
                        onChange={(phoneNumber) => setUserData({...userData, phoneNumber})}
                    />
                </div>
                </label>
                <label>{lang.userPage.markAndModel}
                    <input className="input-area" onChange={(e) => setUserData({...userData, markAndModel: e.target.value})}
                           value={userData.markAndModel} required/>
                </label>
            </div>
            <button className='pagination-btn' onClick={editData}>{lang.editPage.saveChanges}</button>
            <button className='pagination-btn back' onClick={() => setOpenEdit(false)}>{lang.userPage.back}</button>
        </div>
    )
}
// window.location.reload()
export default EditPage;
