import React, {useContext} from "react";
import {AuthContext} from "../context/AuthContext";
import img_11 from './img/img_11.png';
import img_22 from './img/img_22.png';
import mastercard from './img/mastercard.png';
import verified_by_visa from './img/verified_by_visa.png';
import {LanguageContext} from "../context/LangContext";
import {useHistory} from "react-router-dom";

export const Contract = () => {
    

    const {footerContainerHeight, footerBarHeight} = useContext(AuthContext);
    
    const [l] = useContext(LanguageContext);
    let test = useHistory()




    setTimeout(() => {window.scrollTo(0,0)}, 50)

    return (footerContainerHeight && footerBarHeight ?
        <div style={{minHeight: "calc(100vh - " + (footerContainerHeight + footerBarHeight + 201) + "px)", paddingTop: "200px"}}>

            <div onClick={()=> test.goBack()}><button className="pagination-btn">{l.userPage.back}</button></div>

            <div style={{padding: "20px", textAlign: "left"}}>
                <p style={{textAlign: "right"}}><b>{l.contract.phrase_1}</b></p> 
                <p style={{textAlign: "right"}}>{l.contract.phrase_2}</p>
                <h6 style={{textAlign: "center"}}>{l.contract.phrase_55}</h6>
                <h6 style={{textAlign: "center"}}>{l.contract.phrase_56}</h6>
                <h6 style={{textAlign: "center"}}>{l.contract.phrase_57}</h6> 
                <br />
                <p>{l.contract.phrase_3}</p>
                <p>{l.contract.phrase_4}</p>
                <p>{l.contract.phrase_5} </p>
                <p>  <b>{l.contract.phrase_6}</b>{l.contract.phrase_6_1} <b>{l.contract.phrase_6_3}</b> {l.contract.phrase_6_2}</p>
                <b style={{textAlign: "center"}}><h6><b>{l.contract.phrase_7}</b></h6>
                <h6><b>{l.contract.phrase_8}</b></h6>
                <h6><b>{l.contract.phrase_9}</b></h6> </b>
                <br />
                        <h6><b>{l.contract.phrase_10}</b></h6>
                        <p><b>{l.contract.phrase_11}</b> {l.contract.phrase_12}
                            {/* <a href={'https://charging.com.ua'} rel="noreferrer">https://charging.com.ua</a> */}
                            {l.contract.phrase_13}</p>
                        <p><b>{l.contract.phrase_14}</b> {l.contract.phrase_15}</p>
                        <div style={{marginLeft: '20px'}}>
                        <li>{l.contract.phrase_15_1}</li>
                        </div>
                        <p><b>{l.contract.phrase_16}</b> {l.contract.phrase_17}</p>
                        <p><b>{l.contract.phrase_18}</b> {l.contract.phrase_19}</p>
                        <p><b>{l.contract.phrase_20}</b> {l.contract.phrase_21}</p>
                        <p><b>{l.contract.phrase_22}</b> {l.contract.phrase_23}</p>
                        <p><b>{l.contract.phrase_24}</b> {l.contract.phrase_25}</p>
                        <p><b>{l.contract.phrase_26}</b> {l.contract.phrase_26}</p>
                        <p><b>{l.contract.phrase_28}</b> {l.contract.phrase_29}</p>
                        <p><b>{l.contract.phrase_30}</b> {l.contract.phrase_31}</p>
                        <p><b>{l.contract.phrase_32}</b> {l.contract.phrase_33}</p>
                        <p><b>{l.contract.phrase_34}</b> {l.contract.phrase_35}</p>
                        <p><b>{l.contract.phrase_36}</b> {l.contract.phrase_37}</p>
                        <p><b>{l.contract.phrase_38}</b> {l.contract.phrase_39}</p>
                        <p><b>{l.contract.phrase_40}</b> {l.contract.phrase_41}</p>
                        <p><b>{l.contract.phrase_42}</b> {l.contract.phrase_43}</p>
                        <p><b>{l.contract.phrase_44}</b> {l.contract.phrase_45}</p>
                        <p><b>{l.contract.phrase_46}</b> {l.contract.phrase_47}</p>
                        <p><b>{l.contract.phrase_48}</b>{l.contract.phrase_48_1} {l.contract.phrase_49}</p>
                        <p><b>{l.contract.phrase_50}</b> {l.contract.phrase_51}</p>
                        <p><b>{l.contract.phrase_52}</b> {l.contract.phrase_53}</p>
                        <p><b>{l.contract.phrase_53_1}</b> {l.contract.phrase_53_2}</p>
                        <p>{l.contract.phrase_54}</p>
                    
                    
                        <h6><b>{l.contract.phrase_58}</b></h6>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_59}</p>
                            <p>{l.contract.phrase_60}</p>
                            <p>{l.contract.phrase_61}</p>
                            <div style={{marginLeft: '20px'}}>
                            <li>{l.contract.phrase_62}</li>
                            <li>{l.contract.phrase_63}</li>
                            </div>
                            <p>{l.contract.phrase_64}</p>
                        </div>
                        
                        <div style={{marginLeft: '20px'}}>
                            
                            
                            <p>{l.contract.phrase_65}</p>
                            <p>{l.contract.phrase_66}</p>
                            <p>{l.contract.phrase_67}</p>
                        </div>
                        <h6><b>{l.contract.phrase_68}</b></h6>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_69}</p>
                            <p>{l.contract.phrase_69_1}</p>
                            <div style={{marginLeft: '20px'}}>
                                <p>{l.contract.phrase_70}</p>
                                <p>{l.contract.phrase_71}</p>
                                <p>{l.contract.phrase_71_1}</p>
                                <p>{l.contract.phrase_71_2}</p>
                            </div>
                            <p>{l.contract.phrase_72}</p>
                            <p>{l.contract.phrase_72_1}</p>
                            <p>{l.contract.phrase_72_2}</p>
                            <p>{l.contract.phrase_72_3}</p>
                            <div style={{marginLeft: '20px'}}>
                                <p>{l.contract.phrase_73}</p>
                                <p>{l.contract.phrase_74}</p>
                                <p>{l.contract.phrase_75}</p>
                                <p>{l.contract.phrase_76}</p>
                            </div>
                            <p>{l.contract.phrase_77}</p>
                            <p>{l.contract.phrase_78}</p>
                            <p>{l.contract.phrase_79}</p>
                            <div style={{marginLeft: '20px'}}>
                                <p>{l.contract.phrase_80}</p>
                                <p>{l.contract.phrase_81}</p>
                                <p>{l.contract.phrase_82}</p>
                                <p>{l.contract.phrase_83}</p>
                            </div>
                            <p>{l.contract.phrase_84}</p>
                            <div style={{marginLeft: '20px'}}>
                                <p>{l.contract.phrase_85}</p>
                                <p>{l.contract.phrase_86}</p>
                            </div>
                        </div>
                        <h6><b>{l.contract.phrase_87}</b></h6>
                        <div style={{marginLeft: '20px'}}>
                            <li>{l.contract.phrase_88}</li>
                            <li>{l.contract.phrase_89}</li>
                            <li>{l.contract.phrase_90}</li>
                            <li>{l.contract.phrase_90_1}</li>
                            <li>{l.contract.phrase_90_2}</li>
                            <li>{l.contract.phrase_90_3}</li>
                            <li>{l.contract.phrase_90_4}</li>
                            <li>{l.contract.phrase_90_5}</li>
                            <li>{l.contract.phrase_90_6}</li>
                            <li>{l.contract.phrase_90_7}</li>
                            <li>{l.contract.phrase_90_8}</li>
                        </div>
                        <h6><b>{l.contract.phrase_91}</b></h6>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_92}</p>
                            <p>{l.contract.phrase_93}</p>
                            <p>{l.contract.phrase_94}</p>
                            <p>{l.contract.phrase_95}</p>
                            
                            <div> 
                                <a href={'https://www.mastercard.ua/uk-ua/personal/get-support/safety-and-security/securecode.html'} target="_blank" rel="noreferrer"><img style={{verticalAlign: "bottom", textAlign: "left", maxWidth: "200px", display: "inline-block"}} src={mastercard} alt={'mastercard'} /></a>
                                <a href={'https://www.visa.com.ua/uk_UA/partner-with-us/payment-technology/verified-by-visa.html#:~:text=It%20is%20a%20password-protected,details%20into%20an%20eCommerce%20website'} target="_blank" rel="noreferrer"><img style={{textAlign: "left", maxWidth: "200px", maxHeight: "80px", display: "inline-block"}} src={verified_by_visa} alt={'verified_by_visa'} /> </a>   
                            </div>
                            
                            <p>{l.contract.phrase_96}</p>
                            <p>{l.contract.phrase_97}</p>
                            <p>{l.contract.phrase_98}</p>
                            <p>{l.contract.phrase_99}</p>
                        </div>
                        <h6><b>{l.contract.phrase_100}</b></h6>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_101}</p>
                            <div style={{marginLeft: '20px'}}>
                                <p>{l.contract.phrase_101_1}</p>
                                <p>{l.contract.phrase_101_2}</p>
                                <p>{l.contract.phrase_101_3}</p>
                                <p>{l.contract.phrase_101_4}</p>
                                <p>{l.contract.phrase_101_5}</p>
                            </div>
                            <p>{l.contract.phrase_102}</p>
                            <div style={{marginLeft: '20px'}}>
                                <p>{l.contract.phrase_102_1}</p>
                                <p>{l.contract.phrase_102_2}</p>
                                <p>{l.contract.phrase_102_3}</p>
                                <p>{l.contract.phrase_102_4}</p>
                                <p>{l.contract.phrase_102_5}</p>
                                <p>{l.contract.phrase_102_6}</p>
                                <p>{l.contract.phrase_102_7}</p>
                                <p>{l.contract.phrase_102_8}</p>
                                <p>{l.contract.phrase_102_9}</p>
                                <p>{l.contract.phrase_102_10}</p>
                                <p>{l.contract.phrase_102_11}</p>
                                <p>{l.contract.phrase_102_12}</p>
                                <p>{l.contract.phrase_102_13}</p>
                                <p>{l.contract.phrase_102_14}</p>
                                <p>{l.contract.phrase_102_15}</p>
                            </div>
                            <p>{l.contract.phrase_103}</p>
                            <div style={{marginLeft: '20px'}}>
                                <p>{l.contract.phrase_103_1}</p>
                                <p>{l.contract.phrase_103_2}</p>
                                <p>{l.contract.phrase_103_3}</p>
                                <p>{l.contract.phrase_103_4}</p>
                                <p>{l.contract.phrase_103_5}</p>
                                <p>{l.contract.phrase_103_6}</p>
                                <p>{l.contract.phrase_103_7}</p>
                                <p>{l.contract.phrase_103_8}</p>
                                <p>{l.contract.phrase_103_9}</p>
                                <p>{l.contract.phrase_103_10}</p>
                                <p>{l.contract.phrase_103_11}</p>
                                <p>{l.contract.phrase_103_12}</p>
                                <p>{l.contract.phrase_103_13}</p>
                                <p>{l.contract.phrase_103_14}</p>
                            </div>
                            <p>{l.contract.phrase_104}</p>
                            <div style={{marginLeft: '20px'}}>
                                <p>{l.contract.phrase_104_1}</p>
                                <p>{l.contract.phrase_104_2}</p>
                                <p>{l.contract.phrase_104_3}</p>
                                <p>{l.contract.phrase_104_4}</p>
                            </div>
                            
                        </div>
                        <h6><b>{l.contract.phrase_105}</b></h6>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_106}</p>
                        </div>
                        <h6><b>{l.contract.phrase_107}</b></h6>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_108}</p>
                            <p>{l.contract.phrase_109}</p>
                        </div>
                        <h6><b>{l.contract.phrase_110}</b></h6>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_111}</p>
                        </div>
                        <h6><b>{l.contract.phrase_112}</b></h6>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_113}</p>
                            <p>{l.contract.phrase_114}</p>
                            <div style={{marginLeft: '20px'}}>
                                <p>{l.contract.phrase_114_1}</p>
                                <p>{l.contract.phrase_114_2}</p>
                                <p>{l.contract.phrase_114_3}</p>
                                <p>{l.contract.phrase_114_4}</p>
                                <p>{l.contract.phrase_114_5}</p>
                                <p>{l.contract.phrase_114_6}</p>
                            </div>
                            <p>{l.contract.phrase_115}</p>
                        </div>
                        <h6><b>{l.contract.phrase_116}</b></h6>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_117}</p>
                            <p>{l.contract.phrase_118}</p>
                            <p>{l.contract.phrase_119}</p>
                            <p>{l.contract.phrase_120}</p>
                            <p>{l.contract.phrase_121}</p>
                            <p>{l.contract.phrase_122}</p>
                            <p>{l.contract.phrase_123}</p>
                            <p>{l.contract.phrase_124}</p>
                            <p>{l.contract.phrase_125}</p>
                            <p>{l.contract.phrase_126}</p>
                            <p>{l.contract.phrase_127}</p>
                            <p>{l.contract.phrase_128}</p>
                            <p>{l.contract.phrase_129}</p>
                            <p>{l.contract.phrase_130}</p>
                        </div>
                        <h6><b>{l.contract.phrase_131}</b></h6>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_132}</p>
                            <p>{l.contract.phrase_133}</p>
                            <p>{l.contract.phrase_134}</p>
                            <p>{l.contract.phrase_135}</p>
                            <p>{l.contract.phrase_136}</p>
                            <p>{l.contract.phrase_137}</p>
                        </div>
                        <br/>
                        <h6 style={{textAlign: "center"}}><b>{l.contract.phrase_138}</b></h6>
                        {/* <p>{l.contract.phrase_109}</p>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_110}</p>
                            <p>{l.contract.phrase_111}</p>
                            <p>{l.contract.phrase_112}</p>
                            <p>{l.contract.phrase_113}</p>
                            <p>{l.contract.phrase_114}</p>
                            <p>{l.contract.phrase_115}</p>
                            <p>{l.contract.phrase_116}</p>
                            <p>{l.contract.phrase_117}</p>
                            <p>{l.contract.phrase_118}</p>
                            <p>{l.contract.phrase_119}</p>
                            <p>{l.contract.phrase_120}</p>
                            <p>{l.contract.phrase_121}</p>
                            <p>{l.contract.phrase_122}</p>
                            <p>{l.contract.phrase_123}</p>
                            <p>{l.contract.phrase_124}</p>
                        </div> */}
                        {/* <p>{l.contract.phrase_125}</p>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_126}</p>
                            <p>{l.contract.phrase_127}</p>
                            <p>{l.contract.phrase_128}</p>
                            <p>{l.contract.phrase_129}</p>
                            <p>{l.contract.phrase_130}</p>
                            <p>{l.contract.phrase_131}</p>
                            <p>{l.contract.phrase_132}</p>
                            <p>{l.contract.phrase_133}</p>
                            <p>{l.contract.phrase_134}</p>
                            <p>{l.contract.phrase_135}</p>
                            <p>{l.contract.phrase_136}</p>
                            <p>{l.contract.phrase_137}</p>
                            <p>{l.contract.phrase_138}</p>
                            <p>{l.contract.phrase_139}</p>
                        </div> */}
                        {/* <p>{l.contract.phrase_140}</p>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_141}</p>
                            <p>{l.contract.phrase_142}</p>
                            <p>{l.contract.phrase_143}</p>
                            <p>{l.contract.phrase_144}</p>
                        </div> */}
                    
                    {/* <li>
                        <h6><b>{l.contract.phrase_145}</b></h6>
                        <p>{l.contract.phrase_146}</p>
                    </li>
                    <li>
                        <h6><b>{l.contract.phrase_147}</b></h6>
                        <p>{l.contract.phrase_148}</p>
                        <p>{l.contract.phrase_149}</p>
                    </li>
                    <li>
                        <h6><b>{l.contract.phrase_150}</b></h6>
                        <p>{l.contract.phrase_151}</p>
                    </li> */}
                    {/* <li>
                        <h6><b>{l.contract.phrase_152}</b></h6>
                        <p>{l.contract.phrase_153}</p>
                        <p>{l.contract.phrase_154}</p>
                        <div style={{marginLeft: '20px'}}>
                            <p>{l.contract.phrase_155}</p>
                            <p>{l.contract.phrase_156}</p>
                            <p>{l.contract.phrase_157}</p>
                            <p>{l.contract.phrase_158}</p>
                            <p>{l.contract.phrase_159}</p>
                            <p>{l.contract.phrase_160}</p>
                        </div>
                        <p>{l.contract.phrase_161}</p>
                    </li> */}
                    {/* <li>
                        <h6><b>{l.contract.phrase_162}</b></h6>
                        <p>{l.contract.phrase_163}</p>
                        <p>{l.contract.phrase_164}</p>
                        <p>{l.contract.phrase_165}</p>
                        <p>{l.contract.phrase_166}</p>
                        <p>{l.contract.phrase_167}</p>
                        <p>{l.contract.phrase_168}</p>
                        <p>{l.contract.phrase_169}</p>
                        <p>{l.contract.phrase_170}</p>
                        <p>{l.contract.phrase_171}</p>
                        <p>{l.contract.phrase_172}</p>
                        <p>{l.contract.phrase_173}</p>
                        <p>{l.contract.phrase_174}</p>
                        <p>{l.contract.phrase_175}</p>
                        <p>{l.contract.phrase_176}</p>
                    </li> */}
                    {/* <li>
                        <h6><b>{l.contract.phrase_177}</b></h6>
                        <p>{l.contract.phrase_178}</p>
                        <p>{l.contract.phrase_179}</p>
                        <p>{l.contract.phrase_180}</p>
                        <p>{l.contract.phrase_181}</p>
                        <p>{l.contract.phrase_182}</p>
                        <p>{l.contract.phrase_183}</p>
                    </li> */}
                    <br/>
                        <h6>{l.contract.phrase_184}</h6>
                        <h6><b>{l.contract.phrase_185}</b></h6>
                        <h6><b>{l.contract.phrase_186}</b></h6>
                        <h6>{l.contract.phrase_187}</h6>
                        <h6>{l.contract.phrase_188}</h6>
                        <p>{l.contract.phrase_189} {l.contract.phrase_190}</p>
                        <p>{l.contract.phrase_191}</p>
                        {/* <p>{l.contract.phrase_192}</p> */}
                        <p>{l.contract.phrase_193}</p>
                        <br />
                        <p>{l.contract.phrase_193_1}</p>
                        <p>{l.contract.phrase_193_2}</p>
                        <p>{l.contract.phrase_193_3}</p>
                        <p>{l.contract.phrase_193_4}</p>
                        <p>{l.contract.phrase_193_5}</p>
                        <br />
                        <div style={{margin: "auto", width: "fit-content", paddingLeft: "10%"}}>
                            <p style={{textAlign: "right"}}><b>{l.contract.phrase_193_6}</b></p>
                            <p style={{textAlign: "right"}}><b>{l.contract.phrase_193_7}</b></p>
                            <p style={{textAlign: "right"}}><b>{l.contract.phrase_193_8}</b></p>
                        </div>
                        <br />
                
                <h6 style={{textAlign: "center"}}><b>{l.contract.phrase_194}</b></h6>
                <h6 style={{textAlign: "center"}}><b>{l.contract.phrase_194_1}</b></h6>
                <ol>
                    <li>
                        <h6><b>{l.contract.phrase_195}</b></h6>
                        <p>{l.contract.phrase_196}</p>
                        <ul className={'contract'}>
                            <li>{l.contract.phrase_197} <a href={'https://charging.com.ua'} rel="noreferrer">(Charging) </a></li>
                            <li>{l.contract.phrase_198}
                            <div >
                            <div style={{ textAlign: "center", flexDirection: "column"}}> 
                                <p style={{fontWeight: "900", marginBottom: "0"}}>Android</p>
                                <img style={{padding: "20px", textAlign: "left", maxWidth: "200px", display: "inline-block"}} src={img_11} alt={'img_11'} />
                            </div>
                            <div style={{ textAlign: "center", flexDirection: "column"}}>
                                <p style={{fontWeight: "900", marginBottom: "0"}}>IOS</p>
                                <img style={{padding: "20px", textAlign: "left", maxWidth: "200px", display: "inline-block"}} src={img_22} alt={'img_22'} />    
                            </div>
                            </div>
                            </li>
                        </ul>
                        <p>{l.contract.phrase_199}</p>
                        <ul className={'contract'}>
                            <li>{l.contract.phrase_200}</li>
                            <li>{l.contract.phrase_201}</li>
                            <li>{l.contract.phrase_202}</li>
                        </ul>
                        <p>{l.contract.phrase_203}</p>
                        <p>{l.contract.phrase_204}</p>
                        <p>{l.contract.phrase_205}</p>
                        <p>{l.contract.phrase_206}</p>
                        <p>{l.contract.phrase_207}</p>
                    </li>
                    <li>
                        <h6><b>{l.contract.phrase_208}</b></h6>
                        <p>{l.contract.phrase_209}</p>
                        <p>{l.contract.phrase_210}</p>
                        <p>{l.contract.phrase_211}</p>
                        <p>{l.contract.phrase_212}</p>
                        <p>{l.contract.phrase_213}</p>
                    </li>
                    <li>
                        <h6><b>{l.contract.phrase_214}</b></h6>
                        <p>{l.contract.phrase_215}</p>
                        <p>{l.contract.phrase_216}</p>
                        <p>{l.contract.phrase_217}</p>
                        <p>{l.contract.phrase_218}</p>
                        <p>{l.contract.phrase_219}</p>
                        <p>{l.contract.phrase_220}</p>
                        <p>{l.contract.phrase_221}</p>
                        <p>{l.contract.phrase_222}</p>
                        <div style={{marginLeft: '20px'}}>
                        <p>{l.contract.phrase_222_1}</p>
                        <p>{l.contract.phrase_222_2}</p>
                        <p>{l.contract.phrase_222_3}</p>
                        </div>
                    </li>
                </ol>
            </div>
        </div> : null)

}
