import React, {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import img_1 from './img/img_1.jpg';
import img_2 from './img/img_2.jpg';
import img_3 from './img/img_3.jpg';
import img_4 from './img/img_4.jpg';
import img_5 from './img/img_5.jpg';
import {LanguageContext} from "../../context/LangContext";
import {NavLink} from "react-router-dom";

export const TariffsAndRules = () => {

    const {footerContainerHeight, footerBarHeight} = useContext(AuthContext);
    const [l] = useContext(LanguageContext);

    return (footerContainerHeight && footerBarHeight ?
        <div style={{minHeight: "calc(100vh - " + (footerContainerHeight + footerBarHeight + 201) + "px)", paddingTop: "200px", textAlign: "center"}}>

            <div id='tariffsAndRules' style={{padding: "20px", textAlign: "left", maxWidth: "800px", display: "inline-block"}}>

                <NavLink to={'/'}><button className="pagination-btn">{l.userPage.back}</button></NavLink>

                <h5>{l.tariffsAndRules.phrase_1}</h5>
                <h6>{l.tariffsAndRules.phrase_2}</h6>
               
                <h6>{l.tariffsAndRules.phrase_4}</h6>
                <br />
                <h5>{l.tariffsAndRules.phrase_5}</h5>
                <h6>{l.tariffsAndRules.phrase_6}</h6>
                <ul className={'contract'}>
                    <li>{l.tariffsAndRules.phrase_7}</li>
                    <li>{l.tariffsAndRules.phrase_8}</li>
                    <li>{l.tariffsAndRules.phrase_9}</li>
                    <li>{l.tariffsAndRules.phrase_10}</li>
                </ul>
                <h5>{l.tariffsAndRules.phrase_11}</h5>
                <h6>{l.tariffsAndRules.phrase_12}</h6>
                <img src={img_1} alt={'img_1'} />
                <h6>{l.tariffsAndRules.phrase_13}</h6>
                <h6>{l.tariffsAndRules.phrase_14}</h6>
                <img src={img_2} alt={'img_2'} />
                <h6>{l.tariffsAndRules.phrase_15}</h6>
                <img src={img_3} alt={'img_3'} />
                <h6>{l.tariffsAndRules.phrase_16}</h6>
                <h6>{l.tariffsAndRules.phrase_17}</h6>
                <img src={img_4} alt={'img_4'} />
                <h6>{l.tariffsAndRules.phrase_18}</h6>
                <img src={img_5} alt={'img_5'} />
                <h6>{l.tariffsAndRules.phrase_19}</h6>
                <h6>{l.tariffsAndRules.phrase_20}</h6>
                <h6>{l.tariffsAndRules.phrase_21}</h6>
            </div>
        </div> : null)
}
