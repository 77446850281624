let variablesMap = new Map([
    ['charging.com.ua', {
        region: 'Kharkiv' // TODO place default region position in database
    }],
    ['dev.charging.com.ua', {
        region: 'Kharkiv'
    }],
    ['localhost', {
        API: 'https://rest.charging.com.ua',
        // API: 'http://localhost:8080',
        region: 'Kharkiv'
    }],
    ['127.0.0.1', {
        // API: 'https://rest.dev.charging.com.ua'
        // API: 'https://rest.dev.charge.nevozmozhnogo.net.by'
        // API: 'http://127.0.0.1:8080'
        API: 'https://rest.dev.charge.nevozmozhnogo.net.by',
        region: 'Vicebsk'
    }],
    ['192.168.100.2', {
        // API: 'http://192.168.100.2:8080'
    }],
    ['dev.charge.nevozmozhnogo.net.by', {
        region: 'Vicebsk'
    }],
    ]);

const host = window.location.hostname;

let value = variablesMap.get(host);

let InterfaceAddress = 'https://' + host;

let API;
if(!value || !value.API) {
    API = 'https://rest.' + host;
} else {
    API = value.API;
}

let reCAPTCHA_siteKey;
if(!value || !value.reCAPTCHA_siteKey) {
    reCAPTCHA_siteKey = '6LfhVMobAAAAABGwmrqfMmkW_ombjF_O39f05v9d';
} else {
    reCAPTCHA_siteKey = value.reCAPTCHA_siteKey;
}

let region;
if(!value || !value.region) {
    region = 'Vicebsk';
} else {
    region = value.region;
}

console.log(API);
console.log(InterfaceAddress);
console.log(reCAPTCHA_siteKey);
console.log(region);

export { API, InterfaceAddress, reCAPTCHA_siteKey, region }


