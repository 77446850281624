import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { useLocation } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';
import MapOptions from './MapOptions';
import './MapPage.css';
import { Markers } from './Markers';
import { MarkerContext } from '../../context/MarkerContext';
import {API, region} from '../../api';
import { Use } from './Use';
import { Position } from './icons/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs, faCircle as Online } from '@fortawesome/free-solid-svg-icons';
import { faCircle as Ofline } from '@fortawesome/free-regular-svg-icons';
import { LanguageContext } from '../../context/LangContext';
import { useMessage } from '../../hooks/message.hook';
import SockJsClient from 'react-stomp';

const SOCKET_URL = API + '/ws-message';

let public_center;

let lat = parseFloat(localStorage.getItem('lat'));
let lng = parseFloat(localStorage.getItem('lng'));

if (lat && lng) {
    public_center = [lat, lng];
} else {
    if (region === 'Vicebsk') {
        public_center = [55.1904, 30.2049];
    } else {
        public_center = [49.99138434397, 36.22953099184]; // Kharkiv
    }
}


let oneDegreeLatitude = 111.32;

let dataReceived = false;

export const MapPage = () => {


    const message = useMessage()
    const [l] = useContext(LanguageContext)
    const { isAuthenticated, id, chargeId } = useContext(AuthContext)
    const { pathname } = useLocation();
    const [waitingToReconnect, setWaitingToReconnect] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [state, setState] = useState()
    const [newData, setNweData] = useState()
    const [map, setMap] = useState()
    const [options, setOptions] = useState({
        work: false,
        free: undefined,
        typeType2: "Type2",
        typeJ1772: "J1772"
    })
    const [connected, setConnected] = useState(false)
    const [charging, setCharging] = useState(false)
    const [chargingbtn, setChargingbtn] = useState(false)
    const [resevdedbtn, setResevdedbtn] = useState(false)
    const [chargeData, setChargeData] = useState()
    const [use, setUse] = useState(true)
    const [useUpdate, setUseUpdate] = useState(false)
    const [markers, setMarkers] = useContext(MarkerContext)
    const mapRef = useRef(null)
    const [umkaRender, setUmkaRender] = useState(true)
    const [zoom, setZoom] = useState(12)
    const [mes, setMes] = useState(null)
    const clientRef= useRef()

    const sendMessage = (msg) => {

        clientRef.current?.sendMessage('/app/sendMessage', JSON.stringify({ userId: id, message: msg }));

    };

    const onConnected = () => {
        console.log("Connected!!");
        let interval = setInterval(function () {
            if (dataReceived) {
                clearInterval(interval);
                return '';
            }
            sendMessage('onConnect');
        }, 1000);
        setIsOpen(true);
        console.log('socket open');
        setConnected(true);
        getChargingStatus();
    };

    const onDisconnected = () => {
        console.log("Disconnected!");
        if (waitingToReconnect) {
            return;
        }
        setIsOpen(false);
        console.log('ws closed');
        setWaitingToReconnect(true);
        setTimeout(() => setWaitingToReconnect(null), 5000);
    }

    const onMessageReceived = (data) => {

        if (data.features) {
            dataReceived = true;
        }
        if (data.type === 'data') {
            setState(data.features)

        }
        if (data.type === "update") {
            setNweData(data.features)
        }
        if (data.type === "charge" || data.type === "time_charge") {
            setChargeData(data)
            setChargingbtn(true)
            if (!data.userAction.shouldBeFinished) {
                setCharging(true)
                setUseUpdate(true)
            }
            if (data.userAction.shouldBeFinished) {
                setChargingbtn(false)
                setUseUpdate(false)
            }
        }
        if (data.type === "reserve") {
            setChargeData(data)
            setResevdedbtn(true)
            if (!data.userAction.shouldBeFinished) {
                setCharging(true)
                setUseUpdate(true)
            }
            if (data.userAction.shouldBeFinished) {
                setResevdedbtn(false)
                setUseUpdate(false)
            }
        }
    };

    useEffect(() => {
        localStorage.setItem("path", JSON.stringify("/map"))
        document.title = `${l.pages.mapPage}`
        window.M.updateTextFields()
        if (pathname === '/map') {
            window.scrollTo(0, 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (mes) {
            message(mes);
            setMes(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mes])

    useEffect(() => {
        if (!useUpdate && charging && !use) {
            setCharging(false)
            setUse(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useUpdate])

    useEffect(() => {
        if (chargeData) {
            if (chargeData.userAction.shouldBeFinished && !use && charging) {
                setCharging(false)
                setUse(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [use])

    useEffect(() => {
        if (state && newData) {
            setState(state.map((key) => {
                newData.forEach(newdataitem => {
                    if (key.id === newdataitem.id) {
                        key = newdataitem
                    }
                })
                return key
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newData])

    useEffect(() => {
        if (state) {
            setMarkers(state)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    useEffect(() => {
        if (!state && markers)

            setState(markers)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markers])

    useEffect(() => {
        if (chargeId !== undefined && connected) {
            sendMessage(JSON.stringify({ id: id })); // maybe it is useless now
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chargeId])

    const getChargingStatus = () => {
        if (isAuthenticated) {
            sendMessage(JSON.stringify({ id: id })) // may be it is useless
        }
    }

    const getRealDistance = (point1, point2) => {
        let p1_x = point1.geometry.coordinates[0];
        let p1_y = point1.geometry.coordinates[1];
        let p2_x = point2.geometry.coordinates[0];
        let p2_y = point2.geometry.coordinates[1];
        return Math.sqrt((p1_x - p2_x) * (p1_x - p2_x) + (p1_y - p2_y) * (p1_y - p2_y));
    }

    const getMapDistance = (point1, point2, pxPerMetre) => getRealDistance(point1, point2) / pxPerMetre;

    const degreesToRadians = (degrees) => degrees * (Math.PI / 180);

    const stateToClusteredState = (state, options) => {

        let offsetWidth = mapRef.current.offsetWidth;
        let offsetHeight = mapRef.current.offsetHeight;
        let pxPerMetre = 40075016.686 * Math.abs(Math.cos(public_center[0] * Math.PI / 180)) / Math.pow(2, zoom + 8);
        let widthInKh = offsetWidth * pxPerMetre / 1000;
        let heightInKh = offsetHeight * pxPerMetre / 1000;
        let oneDegreeLongitude = 40075 * (Math.cos(degreesToRadians(public_center[0])) / 360);
        let latitude = widthInKh / oneDegreeLatitude;
        let longitude = heightInKh / oneDegreeLongitude;

        // [UMKA] 04.02.2021 visibility filter
        let shown = [];
        for (let i = 0; i < state.length; i++) {
            if (!state[i]) {
                continue;
            }
            let cur_lat = state[i].geometry.coordinates[0];
            let cur_lon = state[i].geometry.coordinates[1];
            if ((cur_lat > public_center[0] + latitude || cur_lat < public_center[0] - latitude ||
                cur_lon > public_center[1] + longitude || cur_lon < public_center[1] - longitude)) {
                shown[i] = undefined;
            } else {
                shown[i] = state[i];
            }
        }

        // TODO be ready to change of connector types. You can request types from server
        // user filters
        let type;
        let free;
        for (let i = 0; i < shown.length; i++) {
            if (!shown[i]) {
                continue;
            }
            if (shown[i].connectors.length === 1) {
                type = (shown[i].connectors[0].type === options.typeType2 || shown[i].connectors[0].type === options.typeJ1772)
                if (options.free === undefined) {
                    free = true
                } else {
                    free = ((shown[i].connectors[0].tariff.charge === options.free && shown[i].connectors[0].status === 'work'))
                }
                if (!type || !free || (options.work && shown[i].connectors[0].status !== 'work')) {
                    shown[i] = undefined;
                }
            } else if (shown[i].connectors.length === 2) {
                type = (
                    (
                        shown[i].connectors[0].type === options.typeType2 || shown[i].connectors[0].type === options.typeJ1772
                    ) || (
                        shown[i].connectors[1].type === options.typeType2 || shown[i].connectors[1].type === options.typeJ1772
                    )
                )
                if (options.free === undefined) {
                    free = true
                } else {
                    free = (((shown[i].connectors[0].tariff.charge === options.free && shown[i].connectors[0].status === 'work') || (shown[i].connectors[1].tariff.charge === options.free && shown[i].connectors[1].status === 'work')))
                }
                if (!type || !free || (options.work && shown[i].connectors[0].status !== 'work' && shown[i].connectors[1].status !== 'work')) {
                    shown[i] = undefined;
                }
            }
        }

        // [UMKA] 04.02.2021 clusters
        for (let j = 0; j < shown.length - 1; j++) {
            for (let i = j + 1; i < shown.length; i++) {
                if (shown[j] && shown[i] && getMapDistance(shown[j], shown[i], pxPerMetre) < 0.0008 && zoom < 17) {
                    shown[i] = undefined;
                    if (!shown[j].count) {
                        shown[j] = { geometry: shown[j].geometry, count: 2 };
                    } else {
                        shown[j] = { geometry: shown[j].geometry, count: shown[j].count + 1 };
                    }
                }
            }
        }

        return shown;
    }

    // noinspection HttpUrlsUsage
    return (
        <>

            <SockJsClient
                url={SOCKET_URL}
                topics={['/topic/general', '/topic/user/' + id]}
                onConnect={onConnected}
                onDisconnect={onDisconnected}
                onMessage={msg => onMessageReceived(msg)}
                debug={false}
                ref={clientRef}
            />
            <div className='map-window'>
                <div ref={mapRef} className='map-body' data-tap-disabled="true">
                    <MapContainer
                        maxZoom={18}
                        whenCreated={setMap}
                        center={public_center}
                        zoom={zoom}
                        scrollWheelZoom={window.innerWidth >= 540} >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {state ? stateToClusteredState(state, options).map((key, i) =>
                            <Markers state={key} key={i} charge={chargeData} map={map} zoom={zoom} setUse={setUse} />
                        ) : <div/>
                        }
                        {map && mapRef ? <MoveToCurrentLocation setZoo={setZoom} map={map} reference={mapRef.current} state={state} setUmkaRender={setUmkaRender} umkaRender={umkaRender} /> : null}
                        <div
                            className="onlineStatus leaflet-bottom leaflet-left"
                            style={{
                                position: 'absolute',
                                bottom: 3,
                                height: 15,
                                width: 50,
                                zIndex: 900
                            }}><FontAwesomeIcon style={{ color: isOpen ? "green" : "gray" }} icon={isOpen ? Online : Ofline} size="lg" />

                            {isOpen ? "online" : "ofline"}</div>
                    </MapContainer >
                    {isAuthenticated && use && charging ? <Use props={chargeData} setUse={setUse} /> : null}
                </div>
                <div className="charge-btn">
                    {chargingbtn ? <div className='charge-status' onClick={() => { setUse(true) }}>Зарядка</div> : null}
                    {resevdedbtn ? <div className='charge-status' style={{ backgroundColor: "rgb(196, 22, 255)" }} onClick={() => { setUse(true) }}>Резерв</div> : null}
                    <MapOptions options={options} setOptions={setOptions} />
                </div>

            </div>
        </>
    )

}

const MoveToCurrentLocation = ({ map, state, setUmkaRender, umkaRender, setZoo }) => {

    const message = useMessage()
    const [l] = useContext(LanguageContext)
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [marker, setMarker] = useState(false)
    // const [btn, setBtn] = useState(false)
    const [position, setPosition] = useState(map.getCenter())
    const [zoom, setZoom] = useState(map.getZoom())
    const [goToCurrentPositionAtStart, setGoToCurrentPositionAtStart] = useState(true)

    const Pos = (pos) => {

        let crd = pos.coords
        setLatitude(crd.latitude)
        setLongitude(crd.longitude)

    }

    const showError = (error) => {
        if(!error) {
            console.log("error is undefined");
            console.log("error = " + error);
        }

        switch (error.code) {
            case error.PERMISSION_DENIED:
                console.log("User denied the request for Geolocation.")
                message(l.locationErrorMessage);
                break;
            case error.POSITION_UNAVAILABLE:
                if(goToCurrentPositionAtStart) {
                    setTimeout(goToMyPosition, 100);
                }
                console.log("Location information is unavailable.")
                break;
            case error.TIMEOUT:
                console.log("The request to get user location timed out.")
                break;
            case error.UNKNOWN_ERROR:
                console.log("An unknown error occurred.")
                break;
            default:
                console.log('error', error)
        }
    }

    // eslint-disable-next-line
    const goToMyPosition = () => {

        navigator.geolocation.getCurrentPosition(Pos, showError, {
            enableHighAccuracy: false, timeout: 10000, maximumAge: 0
        })

        if (latitude && longitude) {
            setGoToCurrentPositionAtStart(false);
            console.log(goToCurrentPositionAtStart);
            map.flyTo([latitude, longitude], 13)
            setMarker(true)
        } else {
            console.log("Location information is unavailable")
        }
    }

    useEffect(() => {

        public_center = [position.lat, position.lng]
        localStorage.setItem('lat', position.lat);
        localStorage.setItem('lng', position.lng);
        if (state) {
            setUmkaRender(!umkaRender);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position.lat, zoom])

    const onMove = useCallback(() => {
        setPosition(map.getCenter())
        setZoom(map.getZoom())
        setZoo(map.getZoom())
    }, [map, setPosition, setZoom, setZoo])

    const goToMarker = (e) => {

        const { lat, lng } = e.latlng
        map.flyTo([lat, lng], 18)
    }

    useEffect(() => {

        map.on('moveend', onMove)
        return () => {
            map.off('moveend', onMove)
        }

    }, [map, onMove])

    useEffect(() => {
            if(goToCurrentPositionAtStart) {
                goToMyPosition();
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
            <div className="leaflet-top leaflet-left" style={{ marginTop: 70 }}>
                <div className="leaflet-control leaflet-bar">
                    <div
                        onClick={goToMyPosition}
                        style={{
                            backgroundColor: "#fff",
                            height: 30,
                            width: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: "pointer",
                            borderRadius: 4
                        }}>
                        <FontAwesomeIcon icon={faCrosshairs} size="lg" />
                    </div>
                </div>

                {marker ? <Marker
                    position={[latitude, longitude]}
                    icon={Position}
                    eventHandlers={{ click: (event) => { goToMarker(event)} }}>
                </Marker> : null}
            </div>
    )
}

export { public_center }
