import React, { useContext, useState, useEffect, useRef } from 'react'
import { Redirect, useParams } from "react-router-dom"
import './Connectors.css'
import M from 'materialize-css/dist/js/materialize.min.js'
import { Image } from './ConnectorsImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { MarkerContext } from '../../context/MarkerContext'
import { AuthContext } from '../../context/AuthContext'
import { Loaderr } from '../../components/Loaderr'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { API } from '../../api'
import { LanguageContext } from '../../context/LangContext'
import { Counter } from '../../components/Counter'
import { getUrl } from '../../hooks/getUrl.hook'

const UnlimKwhCount = 20;

export const Connectors = () => {

    const [l] = useContext(LanguageContext)
    const { connectorId } = useParams()
    const [markers] = useContext(MarkerContext)
    const { id, setChargeId, token} = useContext(AuthContext)
    const {request, error, clearError } = useHttp()
    const message = useMessage()
    const [mes, setMes] = useState(null)
    const [disable, setDisable] = useState(false)

    const [state, setState] = useState(null)

    const [work, setWork] = useState([])
    const [busy, setBusy] = useState([])
    const [alert, setAlert] = useState([])
    const [service, setService] = useState([])
    const [build, setBuild] = useState([])
    const [reserved, setReserved] = useState([])
    const [connected, setConnected] = useState([])
    const [status, setStatus] = useState(false)
    const [plug, setPlug] = useState(false)
    const [money, setMoney] = useState()

    const [charge, setCharge] = useState(false)
    const [reserve, setReserve] = useState(false)
    const [action, setAction] = useState(null)
    const [checked, setChecked] = useState({
        1: false,
        2: false
    })
    const [port, setPort] = useState(0)

    // the multiplier of count
    const [countCharge, setCountCharge] = useState(1)

    const [hour, setHour] = useState(false)
    const [hourMess, sethourMess] = useState(false)
    const [time, setTime] = useState(false)
    const [timeMess, setTimeMess] = useState(false)
    const [unlim, setUnlim] = useState(false)
    const [unlimMess, setunlimMess] = useState(false)
    const [noMoney, setnoMoney] = useState(false)
    let maxKvt = useRef(500);
    let maxMinReserve = useRef(500);
    const url = getUrl();

    useEffect(() => {
        document.title = `${l.pages.connectors} ${connectorId}`
        M.updateTextFields()

        get().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!work.length && !connected.length) {
            setChecked({
                1: false,
                2: false
            })
            setHour(false)
            sethourMess(false)
            setCharge(false)
            setReserve(false)
            setUnlim(false)
            setunlimMess(false)
        }

    }, [work, connected])

    useEffect(() => {
        message(mes);
        message(error);
        setMes(null);
        clearError();
    }, [error, mes, message, clearError])

    const get = async () => {

        try {
            const data = await request(`${API}${url.money}`, token)

            setMoney(data.message)
        } catch (e) {
        }
    }

    useEffect(() => {
        if (work && work.length === 0) {
            setChecked({
                1: false,
                2: false
            })
            setHour(false)
            setCharge(false)
            setReserve(false)
            setCountCharge(0)
        }
    }, [work])

    useEffect(() => {
        if (connected && connected.length === 0) {
            setChecked({
                1: false,
                2: false
            })
            setHour(false)
            setCharge(false)
            setReserve(false)
            setCountCharge(0)
        }
    }, [connected])

    const arrayCreator = (data) => {

        setWork(data.filter(key => key.status === "work"))

        setBusy(data.filter(key => key.status === "busy"))

        setAlert(data.filter(key => key.status === "alert"))

        setService(data.filter(key => key.status === "service"))

        setBuild(data.filter(key => key.status === "build"))

        setReserved(data.filter(key => key.status === "reserved"))

        setConnected(data.filter(key => key.status === "connected"))

    }

    useEffect(() => {
        if (markers) {
            markers.forEach(key => {
                if (String(key.id) === connectorId) {
                    setState(key)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markers])

    useEffect(() => {
        if (state !== null) {
            arrayCreator(state.connectors)
            setPlug(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const changeHandler = event => {
        if (event.target.name === "1" && checked[1] === false) {
            setChecked({
                1: true,
                2: false
            })
            setPort(1)
            setReserve(false)
            setPlug(false)
        } else if (event.target.name === "1" && checked[1] === true) {
            setChecked({
                1: false,
                2: false
            })
            setHour(false)
            setReserve(false)
            setCharge(false)
            setPlug(false)
            console.log('setCountCharge(0)');
            setCountCharge(0)
            // setCountReserve(0)
        } else if (event.target.name === "2" && checked[2] === true) {
            setChecked({
                1: false,
                2: false
            })
            setHour(false)
            setReserve(false)
            setPlug(false)
            setCharge(false)
            setCountCharge(0)

        } else {
            setChecked({
                1: false,
                2: true
            })
            setReserve(false)
            setPort(2)
            setPlug(false)
        }
    }

    const startCharge = async (count = 1) => {

        try {
            const data = await request(`${API}/chargePoint/${connectorId}/start/${action}/${port}?param=${countCharge * count}`, token , 'POST', '')
            setChargeId(connectorId, id)
            setChecked({
                1: false,
                2: false
            })
            setReserve(false)
            setCharge(false)
            setDisable(false)
            if (data.error === 'pleaseConnectTheConnectorAndTryAgain') {
                setPlug(true)
            }
            if (data.status === 'ok') {
                setStatus(true)
            }
            window.scrollTo(0,0);
        } catch (e) {
            console.log(e)
            setMes(l.errors[e])
            setDisable(false)
            if (e === 'not enough money') {
                setChecked({
                    1: false,
                    2: false
                })
                setReserve(false)
                setCharge(false)
                setnoMoney(true)
            }
        }
    }

    const startCharging = () => {
        let cuurentBalance = money;
        let currentAccount = +document.querySelector('.reserve-count')?.innerText;

        if(cuurentBalance < currentAccount){
            setMes(l.errors['notEnoughMoney']);
            return;
        }
        if(money < 10 && time) {
            setMes(l.connectors.noMoneyTime);
        } else if (state.connectors[port - 1] && hour && countCharge > 0) {
            startCharge().then();
            setDisable(true);
        } else if (state.connectors[port - 1] && unlim) {
            startCharge(UnlimKwhCount).then();
            setDisable(true);
        } else if (state.connectors[port - 1] && time) {
            if(countCharge > 0) {
                startCharge(60).then();
                setDisable(true);
            }
            else {
                setMes(l.errors.reserve0)
            }

        } else {
            setMes(l.toast.charge0)
        }
    }

    const startReservation = () => {

        if(countCharge > 0) {
            startCharge(60).then();
            setDisable(true);
        } else {
            setMes(l.toast.reserve0);
        }

    }

    const Connector = ({ props, status, num }) => {

        return (
            <div className="station-connector">
                <div className="station-connector-body">
                    {status === "work" || status === 'connected' ? <div className="station-connector-picker"><input name={`${num}`} className='input-checkbox' type='checkbox' checked={checked[num]} onChange={(e) => { changeHandler(e) }} /></div > : <div style={{ width: "26px" }} />}
                    <div className="station-connector-img">
                        <Image type={props.type} status={status} />

                    </div>
                    <div className="station-connector-prop">
                        <div className="station-connector-bold">{window.innerWidth < 375 ? l.connectors.portLight : l.connectors.port}</div>
                        <div className="station-connector-text">{props.number}</div>
                    </div>
                    <div className="station-connector-prop">
                        <div className="station-connector-bold">{l.connectors.type}</div>
                        <div className="station-connector-text">{props.type}</div>
                    </div>
                    <div className="station-connector-prop">
                        <div className="station-connector-bold">{window.innerWidth < 375 ? l.connectors.powerLight : l.connectors.power}</div>
                        <div className="station-connector-text">{props.power}</div>
                    </div>
                    <div className="station-connector-prop">
                        <div className="station-connector-bold">{l.connectors.tariff}</div>
                        {reserve ? <div className="station-connector-text">{props.tariff.reserve.toFixed(2)} {l.points}/{l.connectors.min}</div> :
                            <div className="station-connector-text">{props.tariff.charge.toFixed(2)} {l.points}/{l.connectors.kvt}</div>}
                    </div>
                </div>
            </div>

        )
    }

    const getMaxAvailable = (tarif, type) => {
        switch(type) {
            case 'kvt':
                maxKvt.current = Math.floor( (+money / +tarif) * 100 ) / 100;
                return maxKvt.current === Infinity ? l.errors.infinity : maxKvt.current;
            case 'min':
                maxMinReserve.current = Math.floor( (+money / +tarif) * 100 ) / 100;
                return maxMinReserve.current === Infinity ? l.errors.infinity : maxMinReserve.current;
            default: return;
        }
    }

    useEffect(() => {
        let mapWrap = document.querySelector('.map-window');
        let footer = document.querySelector('.footer-container').offsetHeight;
        let stationBody = document.querySelector('.station-body');
        if(!stationBody) {
            return;
        }
        let stationWrap = stationBody.scrollHeight;
        mapWrap.style.minHeight = `${stationWrap - footer}px`
        return () => {
            mapWrap.style.minHeight = '100%'
        }
    })

    if (markers === null) {
        return (<Redirect to='/map' />)
    }

    if (status) {
        return (<Redirect to='/map' />)
    }

    return (
        <>
            <div className="station-prop">
                <NavLink to='/map'><div className="station-prob-bg" /></NavLink>

                <div className='station-body'>
                    <NavLink to='/map'><div className="station-prob-close-btn"><FontAwesomeIcon icon={faTimes} /></div></NavLink>
                    {state ?
                        <div className='station-body-head'>
                            <div className="station-title">{state.properties.tittle}</div>
                            <div className="station-info">
                                <div className="station-workTime">
                                    <div className="station-connector-bold">{l.connectors.workTime}</div>
                                    <div className="station-connector-text">{state.properties.workTime}</div>
                                </div>
                                <div className="station-address">
                                    <div className="station-connector-bold">{l.connectors.address}</div>
                                    <div className="station-connector-text">{state.properties.address}</div>
                                </div>
                            </div>
                        </div> : <Loaderr />}
                    <div className="station-connectors">

                        {(state !== null && work.length) ? <div className="station-connector-tittle" style={{ backgroundColor: "#41a350" }}>{l.connectors.statusWork}</div> : <div/>}
                        {work ?
                            work.map((key, ignored) =>
                                <Connector props={key} key={key.number} status={"work"} num={key.number} />)
                            : <div>load</div>}

                        {(state !== null && busy.length) ? <div className="station-connector-tittle" style={{ backgroundColor: "#00B0E6" }}>{l.connectors.statusBusy}</div> : <div/>}
                        {busy ?
                            busy.map((key, ignored) =>
                                <Connector props={key} key={key.number} status={"busy"} num={key.number} />)
                            : <div>load</div>}

                        {(state !== null && reserved.length) ? <div className="station-connector-tittle" style={{ backgroundColor: "#C416FF" }}>{l.connectors.statusReserved}</div> : <div/>}
                        {reserved ?
                            reserved.map((key, ignored) =>
                                <Connector props={key} key={key.number} status={"reserved"} num={key.number} />)
                            : <div>load</div>}

                        {(state !== null && connected.length) ? <div className="station-connector-tittle" style={{ backgroundColor: "#E5BB12" }}>{l.connectors.statusConnected}</div> : <div/>}
                        {connected ?
                            connected.map((key, ignored) =>
                                <Connector props={key} key={key.number} status={"connected"} num={key.number} />)
                            : <div>load</div>}

                        {service ?
                            service.map((key, ignored) =>
                                <Connector props={key} key={key.number} status={"service"} num={key.number} />)
                            : <div>load</div>}

                        {(state !== null && alert.length) ? <div className="station-connector-tittle" style={{ backgroundColor: "#FF3549" }}>{l.connectors.statusAlert}</div> : <div/>}
                        {alert ?
                            alert.map((key, ignored) =>
                                <Connector props={key} key={key.number} status={"alert"} num={key.number} />)
                            : <div>load</div>}
                        {build ?
                            build.map((key, ignored) =>
                                <Connector props={key} key={key.number} status={"build"} num={key.number} />)
                            : <div>load</div>}

                    </div>
                    {money && <div className="current-balance">{`${l.userPage.balance}: ${money} ${l.points}`}</div>}
                    {noMoney ? <NavLink to='/getMoney' className='station-plug'><div className='plug-text'>{l.connectors.noMoney}</div></NavLink> : <></>}
                    {plug ? <div onClick={() => { setPlug(false) }} className='station-plug'><div className='plug-text'>{l.connectors.plugin}</div></div> : <div/>}
                    {((checked[1] && state.connectors[0].status === 'work') || (checked[2] && state.connectors[1].status === 'work')) ?
                        <div className='station-body-buttons'>
                            <button className='station-body-button button-charge'
                                    style={charge ? { border: '2px solid black' } : { }}
                                    onClick={() => {
                                        setCharge(true)
                                        setAction('charge')
                                        setHour(false)
                                        setTime(false)
                                        setUnlim(true)
                                        setCountCharge(1)
                                        setunlimMess(true);
                                        sethourMess(false)
                                        setReserve(false)
                                    }}>{l.connectors.chargeButton}</button>
                            <button className='station-body-button button-reserve'
                                    style={reserve ? { border: '2px solid black' } : { }}
                                    onClick={() => {
                                        setReserve(true)
                                        setCharge(false)
                                        setHour(false)
                                        sethourMess(true)
                                        setAction('reserve')
                                    }}>{l.connectors.reserveButton}</button>
                        </div> :
                        ((checked[1] && state.connectors[0].status === 'connected') || (checked[2] && state.connectors[1].status === 'connected')) ?
                            <div className='station-body-buttons'>
                                <button className='station-body-button button-charge'
                                        style={charge ? { backgroundColor: '#E5BB12' } : { }}
                                        onClick={() => {
                                            setCharge(true)
                                            setAction('charge')
                                            setHour(false)
                                            setunlimMess(true);
                                            setTime(false)
                                            setUnlim(true)
                                            setCountCharge(1)
                                            setReserve(false)
                                        }}>{l.connectors.chargeButton}</button>
                            </div> : <div/>}
                    {reserve ?
                        <div className='reserve-body'>
                            <div className='counter-area'>
                                <span>{l.connectors.min}</span>
                                <Counter
                                    counter={countCharge}
                                    onChange={setCountCharge}
                                    min={0}
                                    max={maxMinReserve.current}
                                    message={setMes}
                                    startTransaction={startReservation}
                                />
                            </div>
                            <p className='connector-charge-btn_max-available'>
                                {`${l.connectors.available}: ${getMaxAvailable(state.connectors[port - 1].tariff.reserve, "min")} ${l.connectors.min}`}
                            </p>
                            <div className='reserve-tittle'>{l.connectors.total}:</div>
                            {state.connectors[port - 1] ? <div className='reserve-count'>{countCharge * Math.round(state.connectors[port - 1].tariff.reserve * 100) / 100}</div> : <div/>}
                            <div className='reserve-UAH'>{l.points}</div>
                            <button
                                className='station-body-button reserve-btn'
                                onClick={startReservation}
                            >{l.connectors.reserveButton}</button>
                        </div> :
                        charge ?
                            <div className='connector-charge-btn'>
                                <button
                                    style={unlim ? { backgroundColor: '#41a350' } : { backgroundColor: '#A3A6AB' }}
                                    className='connector-unlim-btn station-body-button '
                                    onClick={() => {
                                        setUnlim(true)
                                        setunlimMess(true)
                                        setAction('charge')
                                        setCountCharge(1)
                                        setHour(false)
                                        sethourMess(false)
                                        setTime(false)
                                        setTimeMess(false)
                                        // start charging if selected and pressed
                                        if (unlim) {
                                            startCharging();
                                        }
                                    }}>{l.connectors.unlim}</button>
                                <button
                                    style={hour ? { backgroundColor: '#41a350' } : { backgroundColor: '#A3A6AB' }}
                                    className='connector-hour-btn station-body-button nope'
                                    onClick={() => {
                                        setHour(true)
                                        sethourMess(true)
                                        setUnlim(false)
                                        setAction('charge')
                                        setunlimMess(false)
                                        setTime(false)
                                        setTimeMess(false)
                                    }}>{l.connectors.kvt}</button>
                                <button
                                    style={time ? { backgroundColor: '#41a350' } : { backgroundColor: '#A3A6AB' }}
                                    className='connector-hour-btn station-body-button '
                                    onClick={() => {
                                        setHour(false)
                                        sethourMess(false)
                                        setTime(true)
                                        setTimeMess(true)
                                        setAction('timeCharge')
                                        setUnlim(false)
                                        setunlimMess(false)
                                    }}>{l.connectors.time}</button>
                                {hour || time ? <div className='counter-area'>

                                        <span>{hour ? l.connectors.kvt : l.connectors.min}</span>
                                        <Counter
                                            counter={countCharge}
                                            onChange={setCountCharge}
                                            min={0}
                                            max={hour ? maxKvt.current : 500}
                                            message={setMes}
                                            startTransaction={startCharging}
                                        />
                                    </div> :
                                    <div/>}
                                {hour && <p className='connector-charge-btn_max-available'>
                                    {`${l.connectors.available}: ${getMaxAvailable(state.connectors[port - 1].tariff.charge, "kvt") || ''} ${l.connectors.kvt}`}
                                </p>}
                                {hourMess ?
                                    <div>
                                        <div className='connector-hour-message'>
                                            <button className='connector-hour-message-btn' onClick={() => { sethourMess(false) }}>
                                                <FontAwesomeIcon className='close-btn' icon={faTimes} />
                                            </button>
                                            <div className='connector-hour-message-text'>{l.connectors.hourMess}</div>

                                        </div>
                                    </div> :
                                    unlimMess ?
                                        <div>
                                            <div className='connector-hour-message'>
                                                <button className='connector-hour-message-btn' onClick={() => { setunlimMess(false) }}>
                                                    <FontAwesomeIcon className='close-btn' icon={faTimes} />
                                                </button>
                                                <div className='connector-hour-message-text'>{l.connectors.unlimMess}</div>

                                            </div>
                                        </div> :
                                        timeMess ?
                                            <div style={{ margin: "0 0 15px" }}>
                                                <div className='connector-hour-message'>
                                                    <button className='connector-hour-message-btn' onClick={() => { setTimeMess(false) }}>
                                                        <FontAwesomeIcon className='close-btn' icon={faTimes} />
                                                    </button>
                                                    <div className='connector-hour-message-text'>{l.connectors.timeMess}</div>

                                                </div>
                                            </div> :
                                            <div/>}
                                {state.connectors[port - 1] && hour ? <>
                                        <div className='reserve-tittle'>{l.connectors.total}:</div>
                                        <div className='reserve-count'>
                                            {Math.round((countCharge * Math.round(state.connectors[port - 1].tariff.charge * 100) / 100) * 100) / 100}
                                        </div>
                                        <div className='reserve-UAH'>{l.points}</div></> :
                                    state.connectors[port - 1] && unlim ? <>
                                            <div className='reserve-tittle'>{l.connectors.total}:</div>
                                            <div className='reserve-count'>{UnlimKwhCount * Math.round(state.connectors[port - 1].tariff.charge * 100) / 100}</div>
                                            <div className='reserve-UAH'>{l.points}</div></> :
                                        null}


                                <button
                                    className='station-body-button reserve-btn'
                                    disabled={disable}
                                    onClick={startCharging}
                                    style={disable ? { backgroundColor: 'rgb(163, 166, 171)' } : money < 10 && time ? { backgroundColor: 'rgb(163, 166, 171)' } : { backgroundColor: '#41a350' }}>{l.connectors.chargeButton}</button>
                            </div> :
                            <div/>
                    }
                </div>
            </div>
        </>
    )
}
