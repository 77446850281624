// useRef
// noinspection JSUnresolvedVariable

import React, { useEffect, useState, useContext} from 'react'
import { useMessage } from '../../hooks/message.hook'
import { useHttp } from '../../hooks/http.hook'
import { API } from '../../api'
import { LanguageContext } from '../../context/LangContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlusSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import './Stations.css'
import Add from './Add'
import Edit from './Edit'
import { AuthContext } from '../../context/AuthContext'
import nodata from '../style/images/nodata.png'
import DateForm from '../../components/DateForm/DateForm'

export const Stations = () => {

    const { token, startDate, setStartDate, endDate, setEndDate, setOrganizations, role } = useContext(AuthContext)
    const [l] = useContext(LanguageContext)
    const { request, error, clearError } = useHttp()
    const message = useMessage()
    const [mes, setMes] = useState(null)
    const [data, setData] = useState(null)
    const [pages, setPages] = useState()
    const [currentPage, setCurrentPage] = useState()
    const [page, setPage] = useState(JSON.parse(localStorage.getItem('stationPage')))
    const [pageArr, setPageArr] = useState()
    const [modal, setModal] = useState()
    const [edit, setEdit] = useState()
    const [update, setUpdate] = useState(false)
    const [dellModal, setDellModal] = useState()
    const [totalMilliseconds, setTotalMilliseconds] = useState()
    const [totalMoney, setTotalMoney] = useState()
    const [usedEnergy, setUsedEnergy] = useState()
    const [statistics, setStatistics] = useState()
    const arr = []

    useEffect(() => {
        document.title = `${l.pages.stations}`
        localStorage.setItem("path", JSON.stringify("/stations"))
    })

    const loadOrganizations = async () => {
        return await request(`${API}/organization/getAll`, token, 'GET');
    }

    const millisToTimeString = millis => {
        const min = Math.floor(millis / (1000 * 60));
        const hour = Math.floor((min / 60) % 24)
        const day = Math.floor(min / 60 / 24)
        return day + '.' + l.statistics.d + ' ' + hour + '.' + l.statistics.h + ' ' + (min % 60) + '.' + l.statistics.m;
    }

    const getStations = async (page) => {
        try {

            // to use a whole time period of the last day
            const dateEndDate = Date.parse(endDate) + 85399999;

            const data = await request(`${API}/chargePoint/get/${page}/charges?startDate=${Date.parse(startDate)}&endDate=${dateEndDate}`, token)
            setData(data.info)
            setPages(data.pageAmount)
            setCurrentPage(data.currentPage)
            setTotalMilliseconds(data.totalMilliseconds);
            setTotalMoney(data.totalMoney);
            setUsedEnergy(data.usedEnergy);
            setStatistics(data.statistics);
            if(role === 'admin') {
                loadOrganizations().then(data => {
                    setOrganizations(data);
                });
            }
        } catch (e) {
            if (e === "pageNotFound") {
                setPage(1)
                localStorage.setItem('stationPage', '1')
            }
            setMes(l.errors[e])
        }
    }

    const defaultStatistics = {totalMoney: 0, usedEnergy: 0, totalMilliseconds: 0};

    const getConnectorStatistics = (connectorId) => {
        return statistics && statistics[connectorId] ? statistics[connectorId] : defaultStatistics;
    }

    const ConnectorTR = ({connectorId}) => {

        let connectorStatistics = getConnectorStatistics(connectorId);
        let usedEnergy = connectorStatistics.usedEnergy;
        let totalMilliseconds = connectorStatistics.totalMilliseconds;
        let totalMoney = connectorStatistics.totalMoney;

        if(!usedEnergy) {
            usedEnergy = 0;
        }
        if(!totalMilliseconds) {
            totalMilliseconds = 0;
        }
        if(!totalMoney) {
            totalMoney = 0;
        }

        return (<tr>
            <td colSpan={2}>{usedEnergy.toFixed(2)}<span> {l.statistics.kvt}</span></td>
            <td colSpan={2}>{millisToTimeString(totalMilliseconds)}</td>
            <td colSpan={2}>{totalMoney.toFixed(2)}<span> {l.points}</span></td>
        </tr>)

    }

    const delStation = async (id) => {
        try {
            const data = await request(`${API}/chargePoint/delete/${id}`, token, "DELETE")
            setMes(data.message)
            setMes(l.toast[data.toast])
            setUpdate(true)
            setDellModal(null)
        } catch (e) {
        }
    }

    useEffect(() => {
        if (page === null) {
            getStations(1).then()
            localStorage.setItem('stationPage', '1')
        } else {
            getStations(page).then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, startDate, endDate])

    useEffect(() => {
        for (let i = 1; i <= pages; i++) {
            arr.push(i)
        }if (arr.length > 1) {
            setPageArr(arr)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages])

    useEffect(() => {
        message(mes)
        message(error)
        clearError()
        setMes(null)
    }, [error, message, clearError, mes])

    useEffect(() => {
        window.M.updateTextFields()
    }, [])

    const goPage = (i) => {
        setPage(i + 1)
        window.scrollTo(0, 0)
        localStorage.setItem('stationPage', i + 1)
    }

    const handleStartDateChange = (date) => {
        setStartDate(date)
    };
    const handleEndDateChange = (date) => {
        setEndDate(date)
    };

    useEffect(() => {
        if (update) {
            getStations(page).then()
            setUpdate(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    // noinspection JSUnresolvedFunction,JSUnresolvedVariable
    return (
        data?<div className="stations-page">

                <div className="dat-input-box">
                    <div className="dateContainer">
                        <DateForm text={l.stations.from} handleDateChange={handleStartDateChange} selectedDate={startDate}/>
                        <DateForm text={l.stations.to} handleDateChange={handleEndDateChange} selectedDate={endDate}/>
                    </div>
                </div>

            <button className="stations-add-btn-top" onClick={() => setModal(true)}><FontAwesomeIcon icon={faPlusSquare} /> {l.stations.add}</button>
            <table className="stations-table">
            {data?<thead className="stations-table-head">
                    <tr>
                        <th>{l.stations.id}</th>
                        <th>{l.stations.chargePointModel}</th>
                        <th>{l.stations.chargePointSerialNumber}</th>
                        <th>{l.stations.firmwareVersion}</th>
                        <th>{l.stations.tittle}</th>
                        <th>{l.stations.address}</th>
                        <th>{l.stations.lat}</th>
                        <th>{l.stations.lng}</th>
                        <th>{l.stations.connectors}</th>
                        <th><button className="stations-add-btn" onClick={() => setModal(true)}><FontAwesomeIcon icon={faPlusSquare} size="2x" /></button></th>

                    </tr>
                </thead>:null}

                    {data ? data.map((key, i) => (<tbody key={i}>
                    <tr>
                        <td colSpan={10}>{key.organizationName}</td>
                    </tr>
                        <tr className="stations-table-body-block" key={i}>
                            <td className="stations-table-body-item" aria-label={l.stations.id}>{key.id}</td>
                            <td className="stations-table-body-item" aria-label={l.stations.chargePointModel}>{key.model}</td>
                            <td className="stations-table-body-item" aria-label={l.stations.chargePointSerialNumber}>{key.serialNumber}</td>
                            <td className="stations-table-body-item" aria-label={l.stations.firmwareVersion}>{key.firmwareVersion}</td>
                            <td className="stations-table-body-item" aria-label={l.stations.tittle}>{key.tittle}</td>
                            <td className="stations-table-body-item" aria-label={l.stations.address}>{key.address}</td>
                            <td className="stations-table-body-item" aria-label={l.stations.lat}>{key.latitude.toFixed(3)}</td>
                            <td className="stations-table-body-item" aria-label={l.stations.lng}>{key.longitude.toFixed(3)}</td>
                            <td className="stations-table-body-item" aria-label={l.stations.connectors}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{l.stations.num}</th>
                                            <th>{l.stations.status}</th>
                                            <th>{l.stations.type}</th>
                                            <th>{l.stations.power}</th>
                                            <th>{l.stations.t_charge}</th>
                                            <th>{l.stations.t_reserve}</th>
                                        </tr>

                                    </thead>
                                        {key.connectors.map((key, i) => (
                                            <tbody key={i}><tr>
                                                <td aria-label={l.stations.num}>{key.number}</td>
                                                <td aria-label={l.stations.status}>{l.stations[key.status]}</td>
                                                <td aria-label={l.stations.type}>{key.type}</td>
                                                <td aria-label={l.stations.power}>{parseFloat(key.power).toFixed(2)}</td>
                                                <td aria-label={l.stations.t_charge}>{key.tariff.charge.toFixed(2)}</td>
                                                <td aria-label={l.stations.t_reserve}>{key.tariff.reserve.toFixed(2)}</td>
                                            </tr>

                                            <tr>
                                                <th colSpan={2}>{l.statistics.totalPower}</th>
                                                <th colSpan={2}>{l.statistics.totalTime}</th>
                                                <th colSpan={2}>{l.statistics.totalCost}</th>
                                            </tr>
                                            <ConnectorTR connectorId={key.connectorId}/>
                                            </tbody>))}
                                </table>

                            </td>
                            <td style={{ paddingTop: 5 }} className="table-body-btn-block">
                                <div className="table-body-btn-body">
                                    <button className="table-body-btn"
                                        onClick={() => { setEdit(data[i]) }}>
                                        <FontAwesomeIcon icon={faEdit} size="lg" /></button>
                                    <button
                                        className="table-body-btn"
                                        onClick={() => setDellModal(data[i].id)}>
                                        <FontAwesomeIcon icon={faTrashAlt} size="lg" /></button>
                                </div>

                            </td>
                        </tr>
                    </tbody>)) : null}

            </table>
            <div className='station-page-pagination'>
                <div className='tariff-page-nums' style={{ display: 'flex' }}>
                    {pageArr ? pageArr.map((key, i) => (
                        key === 1 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='station-page-num'>{key}</button> :
                            key === 1 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='station-page-num picked'>{key}</button> :
                                key === 2 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='station-page-num'>{key}</button> :
                                    key === 2 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='station-page-num picked'>{key}</button> :
                                        currentPage === key ?
                                            <button key={i} onClick={() => { }} className='station-page-num picked'>{key}</button> :
                                            key === currentPage - 3 ? <div key={i}>...</div> :
                                                key === currentPage + 3 && key !== pageArr.length - 1 && key !== pageArr.length ? <div key={i}>...</div> :
                                                    key === pageArr.length - 1 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='station-page-num'>{key}</button> :
                                                        key === pageArr.length - 1 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='station-page-num picked'>{key}erfl;</button> :
                                                            key === pageArr.length && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='station-page-num'>{key}</button> :
                                                                key === pageArr.length && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='station-page-num picked'>{key}erfr</button> :
                                                                    key > currentPage - 3 && currentPage + 3 > key ?
                                                                        <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='station-page-num'>{key}</button> : null
                    )) : null}
                </div>
            </div>

                <div className="informer">
                    <div className="informer-box">
                        <div className="title" style={{ backgroundColor: 'rgb(74, 181, 235)' }}>{l.statistics.totalPower}</div>
                        <div className="value"><div className="inf-value" data-ui-group="informer" data-ui-profile="power">{usedEnergy !== undefined ? usedEnergy.toFixed(2) : usedEnergy}</div><span>{l.statistics.kvt}</span></div>
                    </div>
                    <div className="informer-box">
                        <div className="title" style={{ backgroundColor: 'rgb(252, 104, 104)' }}>{l.statistics.totalTime}</div>
                        <div className="value">
                            <div className="inf-value">{totalMilliseconds !== undefined ? millisToTimeString(totalMilliseconds) : ''}</div>
                        </div>
                    </div>
                    <div className="informer-box">
                        <div className="title" style={{ backgroundColor: 'rgb(222, 207, 63)' }}>{l.statistics.totalCost}</div>
                        <div className="value"><div className="inf-value" >{totalMoney !== undefined ? totalMoney.toFixed(2) : totalMoney}</div><span>{l.points}</span></div>
                    </div>
                </div>

            {modal ? <Add modal={modal} setModal={setModal} setUpdate={setUpdate} /> : null}

            {edit ? <Edit edit={edit} data={edit} setEdit={setEdit} setUpdate={setUpdate} /> : null}
            {dellModal ? <>

                <div className="stations-modal">
                    <div className="stations-modal-body">
                        <div className="stations-modal-dell-text">
                            {l.stations.confirm}
                        </div>
                        <div className="stations-modal-dell">
                            <button style={{ backgroundColor: "#FF3549" }} className="stations-modal-dell-btn" onClick={() => { delStation(dellModal).then() }}>{l.stations.ok}</button>
                            <button className="stations-modal-dell-btn" onClick={() => setDellModal(null)}>{l.stations.cancel}</button>
                        </div>
                    </div>
                </div>
            </> : null}
                {data.length === 0 ? <span>{l.stations.noStations}</span> : <></>}
        </div>:
        <div className="users-block">
                <div className="loader-body">
                    <img className="loader-body-img" src={nodata} alt='nodata'/>
                </div>
            </div>
    )
}

