/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef} from 'react'
import './style/Language.css'
import ukraine from './style/images/ukraine.png'
import russia from './style/images/russia.png'
import usa from './style/images/usa.png'
import { EN } from '../languages/locale_EN'
import { UA } from '../languages/locale_UA'
import { RU } from '../languages/locale_RU'
import useOnClickOutside from '../hooks/useOnClickOutside.hook';
import { region } from '../api';

import { LanguageContext } from '../context/LangContext'

const options = [
    {
        key: "en",
        value: "EN",
        image: usa
    },
    {
        key: "ru",
        value: "RU",
        image: russia
    },
];

if(region === 'Kharkiv') {
    options.push({
        key: "ua",
        value: "UA",
        image: ukraine
    });
}

export const Language = () => {

    let lang = localStorage.getItem('lang')
    // eslint-disable-next-line no-unused-vars
    const [ignored, sL] = useContext(LanguageContext)

    if(lang === 'ua' && region === 'Vicebsk') {
        lang = 'ru';
    }

    let buttonOption = options.find(key => {
        return key.key === lang
    })

    const node = useRef();

    const [option, setOption] = useState(buttonOption)
    const [drop, setDrop] = useState(false)

    const langChanger = (event) => {
        if (event === 'ru') {
            sL(RU)
        } else if (event === 'ua' && region === 'Kharkiv') {
            sL(UA)
        } else {
            sL(EN)
        }
    }

    useOnClickOutside(node, () => {
        if (drop) {
            setDrop(false)
        }
      })

    useEffect(() => {
        langChanger(lang)
    }, [lang])

    if (buttonOption) {
        return (
            <>
                <button className="dropMenu-button"
                    onClick={() => setDrop(!drop)}>
                    <img className="dropOption-image" src={buttonOption.image} alt={`img${option.key}`} />
                </button>
                <div ref={node} className="dropMenu">
                    {drop ?
                        options.map((key, i) =>
                            <button className="dropOption"
                                key={i}
                                onClick={() => {
                                    setOption(key)
                                    localStorage.setItem('lang', key.key)
                                    setDrop(!drop)
                                }}>
                                <img className="dropOption-image" src={key.image} alt={`img${key.key}`} />
                            </button>
                        )
                        : <></>}
                </div>

            </>
        )
    }
}



