import React, {useContext} from "react";
import {AuthContext} from "../context/AuthContext";
import {LanguageContext} from "../context/LangContext";
import {useHistory} from "react-router-dom";
export const PrivacyPolicy = () => {
    const {footerContainerHeight, footerBarHeight} = useContext(AuthContext);

    const [l] = useContext(LanguageContext);
    let locationState = useHistory()

    setTimeout(() => {window.scrollTo(0,0)}, 50)

    return <>{footerContainerHeight && footerBarHeight &&
        <div style={{minHeight: "calc(100vh - " + (footerContainerHeight + footerBarHeight + 201) + "px)", paddingTop: "200px"}}>

            <div onClick={()=> locationState.goBack()}><button className="pagination-btn">{l.userPage.back}</button></div>

            <div style={{padding: "20px", textAlign: "left"}}>
                {l.privacyPolicy.content}
            </div>

        </div>}
    </>
}
