import React, { useEffect, useState, useContext } from 'react'
import { useParams, NavLink } from "react-router-dom";
import { useMessage } from '../hooks/message.hook'
import { Loaderr } from '../components/Loaderr';
import { useHttp } from '../hooks/http.hook'
import { API } from '../api'
import './style/ActivatePage.css'
import { LanguageContext } from '../context/LangContext'
import { AuthContext } from '../context/AuthContext'
import notok from './style/images/notok.png'
import okok from './style/images/okok.png'


export const ActivatePage = () => {

    const auth = useContext(AuthContext)
    const { id, token, phoneNumber, email, firstName, lastName, markAndModel, role } = useContext(AuthContext)
    const [l] = useContext(LanguageContext)
    const { request, error, clearError } = useHttp()
    const message = useMessage()
    const [mes, setMes] = useState(null)
    const [load, setLoad] = useState(0)

    let { code } = useParams()

    useEffect(() => {
        message(mes)
        message(error)
        clearError()
    }, [error, message, clearError, mes])

    useEffect(() => {
        window.M.updateTextFields()
    }, [])

    const Confirmation = async () => {
        try {
            const data = await request(`${API}/activateEmail/${code}`)
            setMes(l.toast[data.toast])
            const newUserData = {
                phoneNumber: phoneNumber,
                email: email,
                firstName: firstName,
                lastName: lastName,
                markAndModel: markAndModel,
                id: id,
                token: token,
                emailConfirmed: true,
                role: role
            }
            auth.loginFunction({...newUserData, ...(await request(`${API}/api/get/user/`, token))})
            setLoad(1)
        } catch (e) {
            setMes(l.errors[e])
            setLoad(2)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            Confirmation()
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(!l.activatePage) {
        console.log("l.activatePage is undefined");
        console.log("l.activatePage = " + l.activatePage);
    }

    if (load === 0) {
        return (
            <div className="afterRegBlock">
                <Loaderr />
                <div className="activayionpage-text" style={{ textAlign: "center" }}>{l.activatePage.code}: {code}</div>
            </div>
        )
    }
    if (load === 2) {
        return (
            <div className="afterRegBlock">
                <div className="activayionpage-text">
                    <img style={{ height: 200, width: 200 }} src={notok} alt="notok" />
                    <div className="activayionpage-links">{l.activatePage.wrong}</div>
                    <NavLink className="activayionpage-links" to='/'>{l.registrationPage.main} </NavLink>
                </div>
            </div>
        )
    }
    return (
        <div className="afterRegBlock">
            <div className="activayionpage-text">
                <img style={{ height: 200, width: 200 }} src={okok} alt="ok" />
                <div className="activayionpage-links">{l.activatePage.ok}</div>
                <NavLink className="activayionpage-links" to='/'>{l.registrationPage.main} </NavLink>
            </div>
        </div>
    )
}


export const ConfirmNewPassword = () => {

    const [l] = useContext(LanguageContext)
    const { request, error, clearError } = useHttp()
    const message = useMessage()
    const [mes, setMes] = useState(null)
    const [load, setLoad] = useState(0)

    let { code } = useParams()
    let { id } = useParams()

    useEffect(() => {
        message(mes)
        message(error)
        setMes(null)
        clearError()
    }, [error, message, clearError, mes])

    useEffect(() => {
        window.M.updateTextFields()
    }, [])

    const Confirmation = async () => {
        try {
            console.log("trying to make a request");
            console.log(`${API}/confirmNewPassword/${id}/${code}`);
            const data = await request(`${API}/confirmNewPassword/${id}/${code}`)
            console.log(data);
            console.log(id);
            console.log(code);
            setMes(l.toast[data.toast]);
            setMes(data.message);
            setLoad(1);
        } catch (e) {
            setMes(l.errors[e]);
            setLoad(2);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            Confirmation();
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
            { load === 0 ?
                <div className="afterRegBlock">
                    <Loaderr />
                    <div className="activayionpage-text">{l.activatePage.id}: {id} {l.activatePage.andCone}: {code}</div>
                </div> : load === 2 ? <div className="afterRegBlock">
                        <div className="activayionpage-text">
                            {l.activatePage.wrong}
                            <NavLink className="afterRegBlock-text" to='/'>{l.registrationPage.main} </NavLink>
                            <NavLink className="afterRegBlock-text" to='/login'>{l.registrationPage.login} </NavLink>
                        </div>
                    </div>
                    :
                    <div className="afterRegBlock">
                        <div className="activayionpage-text">
                            {l.activatePage.passChange}
                            <NavLink className="afterRegBlock-text" to='/'>{l.registrationPage.main} </NavLink>
                            <NavLink className="afterRegBlock-text" to='/user'>{l.registrationPage.login} </NavLink>
                        </div>
                    </div>
            }</>
    )

}

