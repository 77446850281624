import React, { useEffect, useState, useContext } from 'react'
import { useHttp } from '../hooks/http.hook'
import { useMessage } from '../hooks/message.hook'
import { API } from '../api'
import { AuthContext } from '../context/AuthContext'
import { Counter } from "../components/Counter"
import moneyImg from "./style/images/moneyimg.png"
import { LanguageContext } from '../context/LangContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import './style/MoneyUp.css'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import loadingImg from "./style/images/loading.gif"
import { getUrl } from '../hooks/getUrl.hook'

export const MoneyUp = () => {

    const { id, token } = useContext(AuthContext)
    const [l] = useContext(LanguageContext)
    const { loading, request, error, clearError } = useHttp()
    const message = useMessage()
    const [mes, setMes] = useState(null)
    const [count, setCount] = useState(50)
    const [money, setMoney] = useState(null)
    const [upd, setUpd] = useState(false)
    const [invoiceUrl, setInvoiceUrl] = useState('')
    const { executeRecaptcha } = useGoogleReCaptcha()
    const url = getUrl();

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = `${l.pages.money}`
        window.M.updateTextFields()
        localStorage.setItem("path", JSON.stringify("/getMoney"))
        get().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        message(mes)
        message(error)
        clearError()
        setMes(null)
    }, [error, message, clearError, mes])

    const get = async () => {
        try {
            const data = await request(`${API}${url.money}`, token)
            setMoney(data.message)
            setUpd(false)
        } catch (e) {
            console.log(e)
        }
    }

    const requestNewPayment = async (captcha) => {
        return await request(`${API}/way_for_pay/newPayment?userId=${id}&amount=${count}&captcha=${captcha}&language=${l.lang}`, token, 'POST');
    }

    const newPay = async () => {

        executeRecaptcha("add_money").then((captcha) => {

            requestNewPayment(captcha).then((data) => {

                console.log('data');
                console.log(data);
                setInvoiceUrl(data.invoiceUrl)
            }).catch((error) => {

                console.log(error);
                setMes(l.errors.error);
            });

        }, (reason) => {
            setMes("captcha error " + reason)
            console.log("can't get captcha, you are robot!", "err" + reason)
        })
    }

    useEffect(() => {
        if (upd) {
            get().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upd])

    return (
        <div className="moneyUp-body">
            {!invoiceUrl ? <>
            <br />
            <img className="cash-img" src={moneyImg} alt='money' />
            <div className='user-point'>
                <div className='user-tittle'>{l.getMoney.cash}:</div>
                {money ? <div className='user-text'> {money} {l.points}</div> :
                    <div className='rotation-icon'>
                        <FontAwesomeIcon
                            onClick={get}
                            style={{ cursor: "pointer" }}
                            icon={faSync}
                            size="lg" />
                    </div>}
            </div>

            <Counter
                counter={count}
                min={0}
                max={50000}
                message={setMes}
                onChange={setCount}
            />

            {loading ? <img alt={'loading'} src={loadingImg}/> : <></>}

            <button className='pagination-btn pay-btn' disabled={loading} onClick={newPay}>{l.getMoney.payBtn}</button>

            <div style={{ display: 'flex' }}>
                <button className='pagination-btn pay-btn' onClick={() => { setCount(100) }}>100</button>
                <button className='pagination-btn pay-btn' onClick={() => { setCount(200) }}>200</button>
                <button className='pagination-btn pay-btn' onClick={() => { setCount(500) }}>500</button>
                <button className='pagination-btn pay-btn' onClick={() => { setCount(1000) }}>1000</button>
            </div></> :

                <>

                    <div className='user-tittle'>{l.getMoney.emailSent}</div>
                    <br />
                    <br />
                    <a href={invoiceUrl}  rel="noreferrer">
                        <button className='pagination-btn pay-btn'>{l.getMoney.goToPay}</button>
                    </a>
                    
                     {/* <button className='pagination-btn pay-btn'><a href={invoiceUrl} rel="noreferrer">{l.getMoney.goToPay}</a></button> */}
                        {/* <a href={invoiceUrl} rel="noreferrer"> <button className='pagination-btn pay-btn'>{l.getMoney.goToPay}</button></a> */}
                </>
            }
        </div>
    )
}
