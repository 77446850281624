import React, { useContext } from 'react'
import { LanguageContext } from '../context/LangContext'
import house from './style/images/house.svg'
import call from './style/images/call.svg'
import email from './style/images/mail.svg'

import './style/Footer.css'
import packageJson from '../../package.json';
import {Link} from "react-router-dom";

const Footer = () => {

    const [l] = useContext(LanguageContext)

    return (
        <>
            <div className='footer-container'>
                <div className='footer-cols'>
                    <div className='footer-col'>
                        <div className='fc-title'>
                            <img className='fc-icon' src={house} alt='address' />
                        </div>
                        <div className='fc-body'>
                            <a href="https://goo.gl/maps/Q6ujxEDvE5k41vA9A" className='fc-item'>{l.footer.address}</a>
                            <a href="https://radmirtech.com.ua/" className='fc-item'>radmirtech.com.ua</a>
                        </div>
                    </div>
                    <div className='footer-col'>
                        <div className='fc-title'>
                            <img className='fc-icon' src={call} alt='address' />
                        </div>
                        <div className='fc-body'>
                            <div className='fc-item'>+38 (057) 727-53-35 </div>
                            <div className='fc-item'>+38 (057) 727-53-36 </div>
                            <div className='fc-item'>+38 (098) 400-97-17 </div>
                            <div className='fc-item'>+38 (067) 562-78-63 </div>
                        </div>

                    </div>
                    <div className='footer-col'>
                        <div className='fc-title'>
                            <img className='fc-icon' src={email} alt='address' />
                        </div>
                        <div className='fc-body'>
                            <div className='fc-item'>
                                <a style={{ color: "#fff" }} href="mailto:kommerc@radmirtech.com.ua?subject=Заправки">kommerc@radmirtech.com.ua</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-bar'>
                <div className='fb-block'>
                    <div className='fb-text'>
                        © 2021 - {new Date().getFullYear()} Radmirtech. {l.footer.rights} v. {packageJson.version} | <Link to={'/privacy-policy'}>{l.privacyPolicy.linkText}</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
