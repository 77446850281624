// noinspection JSUnresolvedVariable

import React, { useContext, useEffect, useState } from 'react'
import '../style/User.css'
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import { API } from '../../api'
import { useMessage } from '../../hooks/message.hook'
import { LanguageContext } from '../../context/LangContext'
import { Loaderr } from '../../components/Loaderr'
import Char from './Char'
import "./Statistics.css"
import noData from './no-data-label.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStopwatch, faChargingStation } from '@fortawesome/free-solid-svg-icons'
import DateForm from '../../components/DateForm/DateForm';

export const Statistics = () => {

    let { path } = useParams()

    const sum__ = (arr) => {
        let count = 0
        for (let i = 0; i < arr.length; i++) {
            count = count + Number(arr[i].secondary)
        }
        return count
    }

    const { token, startDate, endDate, setStartDate, setEndDate } = useContext(AuthContext)
    const { loading, request, error, clearError } = useHttp()
    const message = useMessage()
    const [mes, setMes] = useState(null)
    const [l] = useContext(LanguageContext)
    const [pages, setPages] = useState()
    const [currentPage, setCurrentPage] = useState()
    const [page, setPage] = useState(JSON.parse(localStorage.getItem('tab-page')))
    const [char, setChar] = useState()
    const [table, setTable] = useState()
    const [pageArr, setPageArr] = useState()
    const arr = []
    // const [startDate1, setStartDate1] = useState(new Date().toLocaleDateString());
    // const [endDate2, setEndDate2] = useState(new Date().toString());

    const [time, setTime] = useState({
        day: null,
        hour: null,
        min: null
    })
    const [totalCost, settotalCost] = useState()
    const [totalPower, settotalPower] = useState()
// console.log(startDate)
// console.log(endDate)
    useEffect(() => {
        window.scrollTo(0, 0)
        window.M.updateTextFields()
        document.title = `${l.pages.statistics}`

        localStorage.setItem("path", JSON.stringify(path ? `/userStatistics/${path}` : "myStatistics"))
    })

    useEffect(() => {
        getChar().then()
        if (page === null) {
            getTable(1).then()
            localStorage.setItem('tab-page', '1')
        }
        getTable(1).then()
        localStorage.setItem('tab-page', '1')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, l])

    useEffect(() => {
        getTable(page).then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(() => {
        for (let i = 1; i <= pages; i++) {
            arr.push(i)
        }
        setPageArr(arr)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages])

    useEffect(() => {
        message(mes)
        message(error)
        clearError()
        setMes(null)
    }, [error, message, clearError, mes])

    const getChar = async () => {
        try {
            const startDatePhrase = l.statistics.startDate;
            const language = startDatePhrase === 'Начальная дата' ? 'ru' : startDatePhrase === 'Початкова дата' ? 'ua' : 'en';
            // noinspection JSCheckFunctionSignatures
            const data = await request(`${API}/statistics?${path ? 'userId=' + path + '&' : ''}startDate=${Date.parse(startDate)}&endDate=${Date.parse(endDate)}&language=${language}`, token)
            const totalPower = sum__(data[0].data)
            let totalTime = sum__(data[1].data)
            console.log(totalTime)
            const min = Math.floor(totalTime % 60)
            const hour = Math.floor(totalTime / 60 % 24)
            const day = Math.floor(totalTime / 60 / 24)
            const totalCost = sum__(data[2].data)
            // noinspection JSCheckFunctionSignatures
            setTime({
                day: day,
                hour: hour,
                min: min
            })
            settotalCost(totalCost)
            settotalPower(totalPower)
            setChar(data)
        } catch (e) {
            setMes(l.errors[e])
            setChar(undefined)
        }
    }

    const getTable = async (page = 1) => {
        try {
            // noinspection JSCheckFunctionSignatures
            const data = await request(`${API}/get/${page}/statistics?${path ? 'userId=' + path + '&' : ''}startDate=${Date.parse(startDate)}&endDate=${Date.parse(endDate)}`, token)
            setTable(data.info)
            // noinspection JSUnresolvedVariable
            setPages(data.pageAmount)
            // noinspection JSUnresolvedVariable
            setCurrentPage(data.currentPage)
        } catch (e) {
        }
    }

    const goPage = (i) => {
        setPage(i + 1)
        window.scrollTo(0, 755)
        localStorage.setItem('tab-page', i + 1)
    }

    const handleStartDateChange = (date) => {
        setStartDate(date)
    };
    const handleEndDateChange = (date) => {
        setEndDate(date)
    };
    // noinspection JSUnresolvedFunction
    return (
        <div className='statistics-block'>
            <div className="dat-input-box">
                <div className="dateContainer">
                    <DateForm text={l.statistics.from} handleDateChange={handleStartDateChange} selectedDate={startDate}/>
                    <DateForm text={l.statistics.to} handleDateChange={handleEndDateChange} selectedDate={endDate}/>
                </div>
            </div>
            {char && <div className="informer">
                <div className="informer-box">
                    <div className="title" style={{ backgroundColor: 'rgb(74, 181, 235)' }}>{l.statistics.totalPower}</div>
                    <div className="value"><div className="inf-value" data-ui-group="informer" data-ui-profile="power">{totalPower ? totalPower.toFixed(2) : totalPower}</div><span>{l.statistics.kvt}</span></div>
                </div>
                <div className="informer-box">
                    <div className="title" style={{ backgroundColor: 'rgb(252, 104, 104)' }}>{l.statistics.totalTime}</div>
                    <div className="value">
                        <div className="inf-value">{time.day}.{l.statistics.d} {time.hour}.{l.statistics.h} {time.min ? time.min.toFixed() : time.min}.{l.statistics.m}</div>
                    </div>
                </div>
                <div className="informer-box">
                    <div className="title" style={{ backgroundColor: 'rgb(222, 207, 63)' }}>{l.statistics.totalCost}</div>
                    <div className="value"><div className="inf-value" >{totalCost ? totalCost.toFixed(2) : totalCost}</div><span>{l.points}</span></div>
                </div>
            </div>}
            {char ? <Char data={char} /> : loading ? null : <div className="loader-body"><img src={noData} alt='no-data' /></div>}
            {loading ? <div className="loader-body"><Loaderr /></div> : <>
                {char && <table className="statistics-table">
                    {table ? <thead className="table-head">
                        <tr>
                            <th>{l.statistics.type}</th>
                            <th>{l.statistics.port}</th>
                            <th>{l.statistics.address}</th>
                            <th>{l.statistics.time}</th>
                            <th>{l.statistics.cost}</th>
                            <th>{l.statistics.tariff}</th>
                            <th>{l.statistics.start}</th>
                            <th>{l.statistics.finish}</th>
                            <th>{l.statistics.consumption}</th>
                        </tr>
                    </thead> : null}
                    <tbody>
                        {table ? table.map((key, i) => (
                            <tr className="table-body-block" key={i}>
                                <td className="table-body-item" aria-label={l.statistics.id}>
                                    {/*{key.id}&nbsp;*/}
                                    <FontAwesomeIcon icon={key.type === "reserve" ? faStopwatch : faChargingStation}  size="lg"/>
                                </td>
                                <td className="table-body-item" aria-label={l.statistics.port}>{key.port}</td>
                                <td className="table-body-item" aria-label={l.statistics.address}>{key.address}</td>
                                <td className="table-body-item" aria-label={l.statistics.time}>
                                    {Math.floor((key.time / 1000) / 60 / 60)} {l.statistics.h}&nbsp;

                                    {Math.floor((key.time / 1000) / 60 % 60)} {l.statistics.m}&nbsp;

                                    {Math.floor((key.time / 1000) % 60)} {l.statistics.s}&nbsp;
                                </td>
                                <td className="table-body-item" aria-label={l.statistics.cost}>{key.cost ? key.cost.toFixed(2) : "none"}</td>
                                <td className="table-body-item" aria-label={l.statistics.tariff}>{key.tariff ? key.tariff.toFixed(2) : "none"}</td>
                                <td className="table-body-item" aria-label={l.statistics.start}>{key.start ? (key.start.slice(0, 10) + ', ' + key.start.slice(11, key.start.length - 4)) : 'none'}</td>
                                <td className="table-body-item" aria-label={l.statistics.finish}>{key.finish ? (key.finish.slice(0, 10) + ', ' + key.finish.slice(11, key.finish.length - 4)) : "none"}</td>
                                <td className="table-body-item" aria-label={l.statistics.consumption}>{Math.round(key.consumption * 100) / 100}</td>
                            </tr>
                        )) : null}
                    </tbody>
                </table>}</>}
            {char && <div className="statistics-pages">

                {/*TODO remove duplicated code*/}

                {pageArr ? pageArr.map((key, i) => (
                    key === 1 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='tab-page-num'>{key}</button> :
                        key === 1 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='tab-page-num picked'>{key}</button> :
                            key === 2 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='tab-page-num'>{key}</button> :
                                key === 2 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='tab-page-num picked'>{key}</button> :
                                    currentPage === key ?
                                        <button key={i} onClick={() => { }} className='tab-page-num picked'>{key}</button> :
                                        key === currentPage - 3 ? <div key={i}>...</div> :
                                            key === currentPage + 3 && key !== pageArr.length - 1 && key !== pageArr.length ? <div key={i}>...</div> :
                                                key === pageArr.length - 1 && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='tab-page-num'>{key}</button> :
                                                    key === pageArr.length - 1 && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='tab-page-num picked'>{key}erfl;</button> :
                                                        key === pageArr.length && key !== currentPage ? <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='tab-page-num'>{key}</button> :
                                                            key === pageArr.length && key === currentPage ? <button key={i} style={{ cursor: 'pointer' }} className='tab-page-num picked'>{key}erfr</button> :
                                                                key > currentPage - 3 && currentPage + 3 > key ?
                                                                    <button key={i} style={{ cursor: 'pointer' }} onClick={() => { goPage(i) }} className='tab-page-num'>{key}</button> : null
                )) : null}
            </div>}
        </div>
    )
}
