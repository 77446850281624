// useState,
import React, { useEffect,  useContext } from 'react'
import './MainPage.css'
import { useLocation } from "react-router-dom"
import intro from './video/header.png'
import t1 from './images/t1.png'
import t2 from './images/t2.png'
import t3 from './images/t3_transparent.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { LanguageContext } from '../../context/LangContext'

import home from './images/home.svg'
import internationalSearchForMoney from './images/international-search-for-money.svg'
import shoppingCart from './images/shopping-cart.svg'

import deal from './images/deal.svg'
import infrastructure from './images/infrastructure.svg'
import motherboard from './images/motherboard.svg'
import security from './images/security.svg'
import server from './images/server.svg'
import settings from './images/settings.svg'
import value from './images/value.svg'
import warranty from './images/warranty.svg'

import taxi from './images/taxi.svg'
import stats from './images/stats.svg'
import loupe from './images/loupe.svg'
import levels from './images/levels.svg'
import invoices from './images/invoices.svg'
import tariffsAndRulesIcon from './images/tariffsAndRulesIcon.png'

export const MainPage = () => {
    const [l] = useContext(LanguageContext)
    const { pathname } = useLocation()

    useEffect(() => {
        document.title = `${l.pages.mainPage}`
        localStorage.setItem("path", JSON.stringify("/"))
        if (pathname === '/') {
            window.scrollTo(0, 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className='content'>
                <div className='bung-block'/>
                <div className='video_block' style={{textAlign: 'center'}}>
                        <div className="elementor-background">
                        <img className='intro_image' src={intro} alt='video_load_image' style={{ opacity: 1 }} />
                        </div>
                    <div className='charge_car_box'><NavLink to={'/map'}><button className='charge_car_btn pulse'>ЗАРЯДИТЬ МАШИНУ</button></NavLink></div>
                    <div className="under_video_blocks">                  
                    <a className='under_video_block' href="https://radmirtech.com.ua/product-category_energy/elektrozapravki/">
                        
                            <div className='uvb-icon'><img src={internationalSearchForMoney} alt='internationalSearchForMoney' /></div>
                            <div className='uvb-tittle'>{l.mainPage.uvbLeftTitle}</div>
                            <div className='uvb-text'>
                                {l.mainPage.uvbLeftText}
                            </div>
                            
                        </a>
                        
                        <a className='under_video_block' href="https://radmirtech.com.ua/product-category_energy/elektrozapravki/">
                                <div className='uvb-icon'><img src={shoppingCart} alt='shoppingCart' /></div>
                                <div className='uvb-tittle'>{l.mainPage.uvbCentreTitle}</div>
                                <div className='uvb-text'>
                                    {l.mainPage.uvbCentreText}
                                </div>
                        </a>
                        <a className='under_video_block' href="https://radmirtech.com.ua/product-category_energy/elektrozapravki/">
                        
                            <div className='uvb-icon'><img src={home} alt='home' /></div>
                            <div className='uvb-tittle'>{l.mainPage.uvbRightTitle}</div>
                            <div className='uvb-text'>
                                {l.mainPage.uvbRightText}
                            </div>                 
                        </a>
                        <NavLink className='under_video_block' to={'/tariffsAndRules'}>
                            <div className='uvb-icon'><img src={tariffsAndRulesIcon} alt='shoppingCart' /></div>
                            <div className='uvb-tittle'>{l.mainPage.tariffs}</div>
                            <div className='uvb-text'>
                                {l.mainPage.andRules}
                            </div>
                        </NavLink>
                    </div>
                   
                </div>

                {/* configuration block */}
                <div className='configuration-container'>
                    <div className='configuration-block'>
                        <div className='configuration-tittle'>
                            <div className='ct-body'>
                                <div className='ct-header'>
                                    {l.mainPage.ccTitle}
                                </div>
                                <div className='ct-line'>
                                    <div className='ct-dot'/>
                                    <div className='ct-dot'/>
                                    <div className='ct-dot'/>
                                    <div className='ct-dot_2'/>
                                </div>
                                <div className='ct-text'>
                                    {l.mainPage.ccText}

                                </div>
                            </div>
                        </div>
                        <div className='configuration-body'>
                            <div className='cb-body'>
                                <div className='cb-block'>
                                    <div className='cb-block-body'>
                                        <div className='cb-block-img'>
                                            <img src={t1} height="266" alt="ch-centre" />
                                        </div>
                                        <div className='cb-block-text'>
                                            <span className='uvb-strong-text'>{l.mainPage.ccStanton1Head}</span>
                                            <br />
                                            <br />
                                            {l.mainPage.ccStanton1Text1}
                                            <br />
                                            {l.mainPage.ccStanton1Text2}
                                            <br />
                                            {l.mainPage.ccStanton1Text3}
                                            <br />
                                            {l.mainPage.ccStanton1Text4}
                                        </div>
                                    </div>
                                </div>
                                <div className='cb-block'>
                                    <div className='cb-block-body'>
                                        <div className='cb-block-img'>
                                            <img src={t2} height="266" alt="ch-centre" />
                                        </div>
                                        <div className='cb-block-text'>
                                            <span className='uvb-strong-text'>{l.mainPage.ccStanton2Head}</span>
                                            <br />
                                            <br />
                                            {l.mainPage.ccStanton2Text1}
                                            <br />
                                            {l.mainPage.ccStanton2Text2}
                                            <br />
                                            {l.mainPage.ccStanton2Text3}
                                            <br />
                                            {l.mainPage.ccStanton2Text4}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* model block */}

                <div className='model-container'>
                    <div className='model-block'>
                        <div className='model-tittle'>
                            <div className='mb-block-img'>
                                <img className='mc-img' height="px" src={t3} alt="ch-centre" />
                            </div>
                        </div>
                        <div className='model-body'>
                            <div className='mb-body'>

                                {l.mainPage.modelHead}
                                <br />

                                <br />
                                {l.mainPage.modelTextHead}
                                <br />
                                {l.mainPage.modelText1}
                                <br />
                                {l.mainPage.modelText2}
                                <br />
                                {l.mainPage.modelText3}
                                <br />
                                {l.mainPage.modelText4}

                            </div>
                        </div>

                    </div>
                </div>

                {/* Приемущества  */}

                <div className='advantages-container'>
                    <div className='advantages-block'>
                        <div className='at-body'>
                            <div className='at-tittle'>
                                {l.mainPage.adTitle}
                            </div>
                        </div>
                        <div className='advantages-blocks'>
                            <div className='ab-block'>
                                <div className='ad-block-body'>
                                    <div className='ad-block-icon'><img src={server} alt='server' /></div>
                                    <div className='ad-block-text'>{l.mainPage.adBlockText1}</div>
                                </div>
                            </div>
                            <div className='ab-block'>
                                <div className='ad-block-body'>
                                    <div className='ad-block-icon'><img src={value} alt='value' /></div>
                                    <div className='ad-block-text'>{l.mainPage.adBlockText2}</div>
                                </div>
                            </div>
                            <div className='ab-block'>
                                <div className='ad-block-body'>
                                    <div className='ad-block-icon'><img src={warranty} alt='warranty' /></div>
                                    <div className='ad-block-text'>{l.mainPage.adBlockText3}</div>
                                </div>
                            </div>
                            <div className='ab-block'>
                                <div className='ad-block-body'>
                                    <div className='ad-block-icon'><img src={motherboard} alt='motherboard' /></div>
                                    <div className='ad-block-text'>{l.mainPage.adBlockText4}</div>
                                </div>
                            </div>
                            <div className='ab-block'>
                                <div className='ad-block-body'>
                                    <div className='ad-block-icon'><img src={security} alt='security' /></div>
                                    <div className='ad-block-text'>{l.mainPage.adBlockText5}</div>
                                </div>
                            </div>
                            <div className='ab-block'>
                                <div className='ad-block-body'>
                                    <div className='ad-block-icon'><img src={deal} alt='deal' /></div>
                                    <div className='ad-block-text'>{l.mainPage.adBlockText6}</div>
                                </div>
                            </div>
                            <div className='ab-block'>
                                <div className='ad-block-body'>
                                    <div className='ad-block-icon'><img src={infrastructure} alt='infrastructure' /></div>
                                    <div className='ad-block-text'>{l.mainPage.adBlockText7}</div>
                                </div>
                            </div>
                            <div className='ab-block'>
                                <div className='ad-block-body'>
                                    <div className='ad-block-icon'><img src={settings} alt='settings' /></div>
                                    <div className='ad-block-text'>{l.mainPage.adBlockText8}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='mt-button-block'>
                        <a href="https://radmirtech.com.ua/product-category_energy/elektrozapravki/"><button className='mt-button buy-btn'>{l.mainPage.uvbCentreTitle}</button></a>
                    </div>
                </div>


                <div className='business-request-container'>
                    <div className='business-request-block'>
                        <div className='business-request-block-head'>{l.mainPage.brHead}</div>
                        <div className='business-request-block-text'>{l.mainPage.brText}</div>
                    </div>
                </div>


                <div className='mobile-app-container'>
                    <div className='mobile-app-block'>
                        <div className='mobile-app-block-head'>{l.mainPage.maHeadTitle}</div>
                        <div className='mobile-app-block-text'>{l.mainPage.maHeadText}</div>
                        <div className='mobile-app-icons'>
                            <div className='mobile-app-icon-block'>
                                <div className='mobile-app-icon'>
                                    <img className="mobile-app-icon-icon" src={loupe} alt='loupe' />
                                </div>
                                <div className='mobile-app-icon-text'>
                                    {l.mainPage.maIconText1}
                                </div>
                            </div>
                            <div className='mobile-app-icon-block'>
                                <div className='mobile-app-icon'>
                                    <img className="mobile-app-icon-icon" src={taxi} alt='taxi' />
                                </div>
                                <div className='mobile-app-icon-text'>
                                    {l.mainPage.maIconText2}
                                </div>
                            </div>
                            <div className='mobile-app-icon-block'>
                                <div className='mobile-app-icon'>
                                    <img className="mobile-app-icon-icon" src={levels} alt='levels' />
                                </div>
                                <div className='mobile-app-icon-text'>
                                    {l.mainPage.maIconText3}
                                </div>
                            </div>
                            <div className='mobile-app-icon-block'>
                                <div className='mobile-app-icon'>
                                    <img className="mobile-app-icon-icon" src={invoices} alt='invoices' />
                                </div>
                                <div className='mobile-app-icon-text'>
                                    {l.mainPage.maIconText4}
                                </div>
                            </div>
                            <div className='mobile-app-icon-block'>
                                <div className='mobile-app-icon'>
                                    <img className="mobile-app-icon-icon" src={stats} alt='stats' />
                                </div>
                                <div className='mobile-app-icon-text'>
                                    {l.mainPage.maIconText5}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mapinfo-container'>
                    <div className="mapinfo-elementor">
                        <div className='mapinfo-text-box'>
                            <div className='mapinfo-text'>
                                {l.mainPage.map}
                            </div>
                        </div>

                        <div className='mapinfo-box'>
                            <NavLink to='/map'><FontAwesomeIcon icon={faMapMarkerAlt} /></NavLink>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
}
