import React, { useContext, useEffect, useState } from 'react'
import './style/User.css'
import { AuthContext } from '../context/AuthContext'
import { useHttp } from '../hooks/http.hook'
import { API } from '../api'
import { useMessage } from '../hooks/message.hook'
import { NavLink, useLocation, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCar, faTimesCircle, faEye, faEyeSlash, faSync } from '@fortawesome/free-solid-svg-icons'
import { LanguageContext } from '../context/LangContext'
import ReactCodeInput from 'react-verification-code-input'
import EditPage from './EditPage'
import { getUrl } from '../hooks/getUrl.hook'

export const User = () => {

    const { id, role, emailConfirmed, phoneConfirmed, phoneNumber, email, firstName, lastName, markAndModel, token,
        organization } = useContext(AuthContext)

    const { request, error, clearError } = useHttp()
    const message = useMessage()
    const history = useHistory()
    const [mes, setMes] = useState(null)
    const auth = useContext(AuthContext)
    const [money, setMoney] = useState()
    const [change, setChange] = useState()
    const [oldPass, setOldPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [repeatPass, setRepeatPass] = useState("")
    const [oldPassShow, setOldPassShow] = useState(false)
    const [newPassShow, setNewPassShow] = useState(false)
    const [repeatPassShow, setRepeatPassShow] = useState(false)
    const { pathname } = useLocation()
    const [l] = useContext(LanguageContext)
    const [confirmation, setConfirmation] = useState('')
    const [confirmonComplete, setConfirmonComplete] = useState(null)
    const [confirmStatus, setConfirmStatus] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [updateMoney, setUpdateMoney] = useState('no-update-money')
    const url = getUrl();

    useEffect(() => {
        document.title = `${l.pages.userPage}`
        if (pathname === `/user/${id}`) {
            window.scrollTo(0, 0);
            getMoney().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    useEffect(() => {

        message(mes)
        message(error)
        clearError()
        // setMes()
    }, [error, message, clearError, mes])

    useEffect(() => {
        window.M.updateTextFields()
    })

    const verifyEmail = async () => {
        try {
            const data = await request(`${API}/verify/${email}?language=${l.lang}`, token, 'POST')
            setMes(l.toast[data.toast])
        } catch (e) {
            setMes(l.errors[e])
        }
    }

    const verifyPhone = async () => {
        try {
            const data = await request(`${API}/api/confirmPhone/${id}?phone=${phoneNumber}`, token, 'POST')
            setConfirmStatus(true);
            setMes(l.toast[data.toast])
        } catch (e) {
            message(l.errors.phoneChangesNotAvailable)
        }
    }

    const getMoney = async () => {
        setUpdateMoney('update-money');
        try {
            const data = await request(`${API}${url.money}`, token)
            setUpdateMoney('no-update-money');
            setMoney(data.message)
        } catch (e) {
        }
    }

    const changePassword = async () => {
        try {

            if (oldPass.length > 100 || newPass.length > 100 || repeatPass.length > 100) {
                setMes(l.errors.tooLongPassword);
                return;
            }

            if (newPass === repeatPass) {
                const data = await request(`${API}/changePassword/${email}?oldPassword=${oldPass}&newPassword=${newPass}&language=${l.lang}`, token, 'POST')
                if (data) {
                    setMes(l.toast.pleaseCheckYourEmail)
                    setChange(false)
                }
            } else {
                setMes(l.errors.repeatPassChange)
            }
        }
        catch (e) {
            setMes(l.errors[e])
        }
    }

    const updateConfirmData = async() => {
        auth.loginFunction({...JSON.parse(localStorage.getItem('userData')), ...(await request(`${API}/api/get/user/`, token))});
    }

    const logoutHandler = event => {
        event.preventDefault()
        auth.logout()
        history.push('/')
    }

    const confirm = async () => {

        if (confirmation.length === 4) {
            try {
                const data = await request(`${API}/changePhone/${id}`, null, 'POST', { "code": confirmation, 'phone': `${phoneNumber}` })
                console.log(data);
                setMes(l.toast[data.toast]);
                console.log(data);
                setConfirmonComplete(false);
                console.log(data);
                setConfirmStatus(false)
                console.log(data);
                await updateConfirmData();
                console.log(data);
            } catch (e) {
                setMes(l.errors.yourCodeIsInvalid)
                setConfirmStatus(true)
            }
        }
    }

    const confirmecomplite = () => {
        setConfirmonComplete(true)
    }

    useEffect(() => {
        if (confirmation.length === 4 && confirmonComplete) {
            confirm(confirmation).then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmation, confirmonComplete])

    if (confirmStatus) {
        return (
            <div className="afterRegBlock">
                <div className="afterRegBlock-tittle">{l.registrationPage.sms}</div>
                <div>
                    <ReactCodeInput
                        fields={4}
                        type={'number'}
                        onChange={(num) => {setConfirmation(num) }}
                        onComplete={confirmecomplite}
                    />
                </div>
                <p onClick={() => {setConfirmStatus(false)}}>{l.userPage.back}</p>
            </div>
        )
    }

    if(openEdit) {
        let userInfo = {id, phoneNumber, email, firstName, lastName, markAndModel, token}
        return (
            <EditPage
                lang={l}
                data={userInfo}
                setOpenEdit={setOpenEdit}
            />
        )
    }

    return (
        <>
            <div className='userPage'>
                <div className='user-body'>
                    <div className='user-row'>
                        <div className='user-point'>
                            <div className='user-tittle'>{l.userPage.name}:</div>
                            <div className='user-text' style={role === 'admin' ? { fontWeight: '600', cursor: 'help' } : {}} title={role === 'admin' ? 'Admin' : 'user'}>{firstName}</div>
                        </div>
                        <div className='user-point' >
                            <div className='user-tittle'>{l.userPage.surname}:</div>
                            <div className='user-text'>{lastName}</div>
                        </div>
                        <div className='user-point'>
                            <div className='user-tittle'>{l.userPage.phone}:</div>
                            <div className='user-text'>+{phoneNumber}</div>
                            <span
                                title={phoneConfirmed ?
                                    l.userPage.titlePhoneConfirm :
                                    l.userPage.titleNotPhoneConfirm}
                            >
                                <FontAwesomeIcon
                                    className='email-confirmation'
                                    alt='email-confirmation'
                                    icon={phoneConfirmed ? faCheckCircle : faTimesCircle}
                                    style={phoneConfirmed ? { color: "green" } : { color: "red" }}
                                    size="lg" />
                            </span>
                        </div>
                        <div className='user-point'>
                            <div className='user-tittle'>{l.userPage.balance}:</div>
                            {money ? <div className='user-text'> {money} {l.points}<FontAwesomeIcon
                                    className={updateMoney}
                                    onClick={() => getMoney()}
                                    style={{ cursor: "pointer" }}
                                    icon={faSync}
                                    size="lg" /></div> :
                                <div className='rotation-icon'><FontAwesomeIcon
                                    onClick={() => getMoney()}
                                    style={{ cursor: "pointer" }}
                                    icon={faSync}
                                    size="lg" /></div>
                            }

                        </div>
                    </div>
                    <div className='user-row'>
                        <div className='user-point'>
                            <div className='user-tittle'>{l.userPage.email}:</div>
                            <div className='user-text'>{email}</div>
                            <span
                                title={emailConfirmed ?
                                    l.userPage.titleEmailConfirm :
                                    l.userPage.titleNotEmailConfirm}
                            >
                                <FontAwesomeIcon
                                    className='email-confirmation'
                                    alt='email-confirmation'
                                    icon={emailConfirmed ? faCheckCircle : faTimesCircle}
                                    style={emailConfirmed ? { color: "green" } : { color: "red" }}
                                    size="lg" />
                            </span>
                        </div>

                        <div className='user-point'>
                            <div className='user-tittle'>{l.userPage.markAndModel}:</div>
                            <div className='user-text'>
                                <FontAwesomeIcon
                                    className='email-confirmation'
                                    alt='email-confirmation'
                                    icon={faCar}
                                    size="lg" />
                                {markAndModel}

                            </div>
                        </div>

                        { role === 'operator' ? <div className='user-point'>
                            <div className='user-tittle'>{l.userPage.organization}:</div>
                            <div className='user-text'>{organization}</div>
                        </div> : <></> }

                    </div>
                    {emailConfirmed ? null :
                        <button className="pagination-btn" onClick={verifyEmail}>{l.userPage.emailConfirm}</button>}
                    {phoneConfirmed ? null :
                        <button className="pagination-btn" onClick={verifyPhone}>{l.userPage.phoneConfirm}</button>}
                    { (emailConfirmed && phoneConfirmed) ? 
                    <NavLink to='/getMoney'>
                        <button 
                            className="pagination-btn" 
                            >
                            {l.userPage.add}
                        </button>
                    </NavLink> 
                    : 
                    <button 
                        className="pagination-btn" 
                        onClick={() => alert(l.userPage.alert)} 
                        style={{ background: "gray" }}
                        >
                        {l.userPage.add}
                    </button>
                    }
                    <button className="pagination-btn" onClick={() => {
                        setChange(!change)
                        setOldPass(null)
                        setNewPass(null)
                        setRepeatPass(null)
                    }}>
                        {change ? l.userPage.changePassClose : l.userPage.changePass}</button>
                        <button onClick={() => setOpenEdit(true)} className="pagination-btn">{l.userPage.edit}</button>
                    {change ? <div className="change-input-block">
                        <div className="change-input-block-btn">
                            <input placeholder={l.userPage.oldPass}
                                value={oldPass}
                                onChange={(e) => { setOldPass(e.target.value) }}
                                className="change-input"
                                type={oldPassShow ? "text" : "password"} />
                            <button className="change-input-btn" onClick={() => { setOldPassShow(!oldPassShow) }}>
                                <FontAwesomeIcon
                                    icon={oldPassShow ? faEyeSlash : faEye}
                                    size="lg" />
                            </button>
                        </div>
                        <div className="change-input-block-btn">
                            <input
                                placeholder={l.userPage.newPass}
                                value={newPass}
                                onChange={(e) => { setNewPass(e.target.value) }}
                                className="change-input"
                                type={newPassShow ? "text" : "password"} />
                            <button className="change-input-btn" onClick={() => { setNewPassShow(!newPassShow) }}>
                                <FontAwesomeIcon
                                    icon={newPassShow ? faEyeSlash : faEye}
                                    size="lg" />
                            </button>
                        </div >
                        <div className="change-input-block-btn">
                            <input
                                placeholder={l.userPage.rNewPass}
                                value={repeatPass}
                                onChange={(e) => { setRepeatPass(e.target.value) }}
                                className="change-input"
                                type={repeatPassShow ? "text" : "password"} />
                            <button className="change-input-btn" onClick={() => { setRepeatPassShow(!repeatPassShow) }}>
                                <FontAwesomeIcon
                                    icon={repeatPassShow ? faEyeSlash : faEye}
                                    size="lg" />
                            </button>
                        </div>
                        <button className="pagination-btn" onClick={() => {
                            changePassword().then()
                        }

                        }>{l.userPage.changeButton}</button>
                    </div> : null}
                </div>
                <NavLink to={`/contract`} rel="noreferrer">{l.registrationPage.checkboxEnd}</NavLink>
            </div>
            <button className="pagination-btn logout-btn" onClick={logoutHandler}>{l.userPage.exit}</button>
        </>
    )
}
