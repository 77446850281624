import React, { useState, useEffect, useContext}  from 'react'
import { Marker, Popup, Tooltip, CircleMarker} from "react-leaflet"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {MarkerContext} from '../../context/MarkerContext'
import { faClock  } from '@fortawesome/free-solid-svg-icons'
import {LanguageContext} from '../../context/LangContext'
import './Markers.css'
import {
    Work,
    Busy,
    Reserved,
    Connected,
    Alert,

    WorkWork,
    WorkBusy,
    WorkReserved,
    WorkConnected,
    WorkAlert,

    BusyWork,
    BusyBusy,
    BusyAlert,
    BusyConnected,
    BusyReserved,

    AlertWork,
    AlertBusy,
    AlertAlert,
    AlertConnected,
    AlertReserved,

    ConnectedWork,
    ConnectedBusy,
    ConnectedAlert,
    ConnectedConnected,
    ConnectedReserved,

    ReservedWork,
    ReservedBusy,
    ReservedAlert,
    ReservedConnected,
    ReservedReserved,
    
    } from './icons/Icon'

import { Link } from 'react-router-dom';
import {AuthContext} from '../../context/AuthContext'

const statusColor = new Map([
    ['work', "#41a350"],
    ['busy', "#00B0E6"],
    ['alert', "#FF3549"],
    ['connected', "#E5BB12"],
    ['reserved', "#C416FF"],
])

const  Popups = ({props, charge, setUse}) => {
    const [l] = useContext(LanguageContext)
    const [markers] = useContext(MarkerContext)
    const {isAuthenticated} = useContext(AuthContext)


    const [state, setState] = useState(props)
    const [work, setWork] = useState(false)
    const [c, setC] = useState()

    useEffect(() => {
        if(charge !== undefined) {
            setC(charge.type)
            if (charge.userAction.shouldBeFinished) {
                setC(undefined)
            }
        }
    }, [charge])

        useEffect(() => {
            setState(props)
        }, [props])

        useEffect(() => {
            setWork(false)
            if (state.connectors.length  === 2 ){
                if (state.connectors[0].status === 'work' || state.connectors[0].status === 'connected'){
                    setWork(true)
                } else if (state.connectors[1].status === 'work' || state.connectors[1].status === 'connected') {
                    setWork(true)
                } else {
                    setWork(false)
                }
            } else if (state.connectors.length === 1 ){
                if (state.connectors[0].status === 'work' || state.connectors[0].status === 'connected'){
                    setWork(true)
                }
            } else {
                setWork(false)
            }
        }, [markers, state.connectors])

    return (
        <>
            <Popup closeButton={true} closeOnClick={true} minWidth={250}>
                <div className='popup-tittle'>{props.properties.tittle}</div>
                <div className='popup-address'>{props.properties.address}</div>
                <div className='popup-workTime'><FontAwesomeIcon className='popup-icon' icon={faClock} size="lg"/>{props.properties.workTime}</div>
                <div className='popup-connectors'>
                    {state.connectors.map((key, i) => {
                            return (
                            <div className='popup-connector' key={i} style={{padding: '10px'}}>
                                    <div className='popup-connector-type'>{key.type}</div>
                                    <div className='popup-connector-power'>{key.power}</div>
                                    <div className='popup-connector-status' style={{border:`8px solid ${statusColor.get(key.status)}`}}>
                                        <div className='popup-connector-status-status'>
                                            {i + 1}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </div>

                {(isAuthenticated && work && !c)?
                <Link to={`map/station${state.id}`}> <button className='popup-btn'>{l.markers.chargeButton}</button></Link>:
                    (isAuthenticated && !work)?<div>{l.markers.noAvailable}</div>:
                    (isAuthenticated && work && (c === 'charge' || c === 'time_charge'))?<div className='charge-status-modal' onClick={() => { setUse(true) }}>{l.markers.charge}</div>:
                    (isAuthenticated && work && c === 'reserve')?<div className='charge-status-modal' style={{ backgroundColor: "rgb(196, 22, 255)" }} onClick={() => { setUse(true) }}>{l.markers.reserve}</div>:
                <Link to='/login'> <button className='popup-btn'>{l.markers.login}</button></Link>}
            </Popup>
        </>
    )
}

const statusToIcon = new Map([
    ['work', Work],
    ['busy', Busy],
    ['alert', Alert],
    ['connected', Connected],
    ['reserved', Reserved],

    ['work_work', WorkWork],
    ['work_busy',WorkBusy],
    ['work_reserved',WorkReserved],
    ['work_connected',WorkConnected],
    ['work_alert',WorkAlert],

    ['busy_work', BusyWork],
    ['busy_busy', BusyBusy],
    ['busy_alert', BusyAlert],
    ['busy_connected', BusyConnected],
    ['busy_reserved', BusyReserved],

    ['alert_work', AlertWork],
    ['alert_busy', AlertBusy],
    ['alert_alert', AlertAlert],
    ['alert_connected', AlertConnected],
    ['alert_reserved', AlertReserved],

    ['connected_work', ConnectedWork],
    ['connected_busy', ConnectedBusy],
    ['connected_alert', ConnectedAlert],
    ['connected_connected', ConnectedConnected],
    ['connected_reserved', ConnectedReserved],

    ['reserved_work', ReservedWork],
    ['reserved_busy', ReservedBusy],
    ['reserved_alert', ReservedAlert],
    ['reserved_connected', ReservedConnected],
    ['reserved_reserved', ReservedReserved],
]);

export const Markers = ({state, charge, map, zoom, setUse}) => {

    const goToCluster = (e) => {
        if (state.count <= 3 ?? zoom <10){
            const { lat, lng } = e.latlng
                map.flyTo([lat, lng], zoom+4>18?18:zoom+4)
            
        } else {
            const { lat, lng } = e.latlng
            map.flyTo([lat, lng], zoom+2>18?18:zoom+2)
        }
    }

    if(!state)
        return(<span/>)

    const geo = state.geometry.coordinates

    if(!state.connectors){
        return(
            <CircleMarker center={geo} radius={20} pathOptions={{ color: '#41a350' }} eventHandlers={{click: (e) => {goToCluster(e)}}}>
                <Tooltip permanent direction="right" offset={[0, 0]} opacity={1}>
                    <div>{state.count}</div>
                  </Tooltip>
            </CircleMarker>
        )
    } else {

        if (state.connectors.length === 1) {
            return (
                <Marker position={geo} icon={statusToIcon.get(state.connectors[0].status)}>
                    <Popups props={state} charge={charge} setUse={setUse}/>
                </Marker>
            )
        } else if (state.connectors.length === 2) {
            return (
                <Marker position={geo}
                        icon={statusToIcon.get(state.connectors[0].status + '_' + state.connectors[1].status)}>
                    <Popups props={state} charge={charge} setUse={setUse}/>
                </Marker>
            )
        }
    }

    return(
        <Marker position={geo}>
             <Popups props = {state} charge={charge} setUse={setUse}/>
        </Marker>
        )
}
