import React, {useState, useContext } from 'react';
import {NavLink} from 'react-router-dom'
import './SwipeMenu.css';
import { AuthContext } from '../../context/AuthContext'
import { LanguageContext } from '../../context/LangContext'
import { useMessage } from '../../hooks/message.hook';

export const SwipeMenu = () => {

    const [l] = useContext(LanguageContext)
    const { token, role, id } = useContext(AuthContext)
    const [isChecked, setIsChecked] = useState(false);
    const message = useMessage();

    const controlNavLinkReports = (action) => {

        switch(action) {
            case 'to':
                let href = '/reports';
                if(+window.screen?.width <= 768 || +window.innerWidth <= 768) {
                    href = '#';
                }
                return href;
            case 'click':
                if(+window.screen?.width <= 768 || +window.innerWidth <= 768) {
                    message(l.navbar.closeReports);
                }
                setIsChecked(!isChecked);
                return;
            case 'showReportsLink':          
                if(+window.screen?.width <= 768 || +window.innerWidth <= 768) {
                    return false;
                }
                return true;
            default: return;
        }
    }

    return (
        <div className="hamburger-menu">
            <input id="menu-toggle" type="checkbox" onChange={(event) => setIsChecked(event.currentTarget.checked)} checked={isChecked} />
            <label className="menu-btn" htmlFor="menu-toggle">
            <span/>
            </label>
            <div className='cover' onClick={() => setIsChecked(!isChecked)} />

            <ul className="menu-box">
                <ul className="menu-box_r_r">
                    <li><NavLink to='/' className="h-menu-item" onClick={() => setIsChecked(!isChecked)}>{l.navbar.mainLink}</NavLink></li>
                    {token ?
                        <li><NavLink to={`/user/${id}`} className="h-menu-item" onClick={() => setIsChecked(!isChecked)}>{l.navbar.accountLink}</NavLink></li> :
                        <li><NavLink to='/login' className="h-menu-item" onClick={() => setIsChecked(!isChecked)}>{l.navbar.userAccount}</NavLink></li>}
                    <li><NavLink to='/map' className="h-menu-item" onClick={() => setIsChecked(!isChecked)}>{l.navbar.mapLink}</NavLink></li>
                    {token ?
                    <li><NavLink to='/myStatistics' className="h-menu-item" onClick={() => setIsChecked(!isChecked)}>{l.navbar.myStatistics}</NavLink></li>:
                    null}

                    {role === 'admin' || role === 'operator' ?<>
                            <li><NavLink to='/users' className="h-menu-item" onClick={() => setIsChecked(!isChecked)}>{l.navbar.usersLink}</NavLink></li>
                            <li><NavLink to='/tariffs' className="h-menu-item" onClick={() => setIsChecked(!isChecked)}>{l.navbar.tariffs}</NavLink></li>
                            <li><NavLink to='/stations' className="h-menu-item" onClick={() => setIsChecked(!isChecked)}>{l.navbar.stations}</NavLink></li>
                            {controlNavLinkReports('showReportsLink') && 
                            <li>
                                <NavLink to={controlNavLinkReports('to')} className="h-menu-item" onClick={() => controlNavLinkReports('click')}>
                                    {l.navbar.reports}
                                </NavLink>
                            </li>}
                    </>
                        : null}

                    {role === 'admin' ?
                            <li><NavLink to='/organizations' className="h-menu-item" onClick={() => setIsChecked(!isChecked)}>{l.navbar.organizations}</NavLink></li>
                        : null}

                </ul>
            </ul>
        </div>
    );
}
