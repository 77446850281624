import React from 'react';
import 'date-fns';

import { Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


const DateForm = ({ selectedDate, handleDateChange, label, text}) => {

    const spanStyle = {
        padding: '5px 10px 0 10px',
        color: '#5DB075',
        fontSize: '16px'
    }

    return (
        <div className="dateContainer__date">
            <MuiPickersUtilsProvider utils={DateFnsUtils}  >
                <span style={spanStyle}>{text}</span>
                <Grid container justify='space-around'>
                    <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='dd/MM/yyy'
                        margin='normal'
                        id='date-picker'
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date'
                        }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default DateForm;
