// noinspection JSUnresolvedFunction

import React, {useContext } from "react";
import {LanguageContext} from "../context/LangContext";
import {AuthContext} from "../context/AuthContext";
import {API} from "../api";
import { useMessage } from "../hooks/message.hook";
import DateForm from "../components/DateForm/DateForm";

let saveBlob = function (blob, fileName) {
    let a = document.getElementById('hiddenLink');
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
}

export const Reports = () => {

    const [l] = useContext(LanguageContext);
    const {footerContainerHeight, footerBarHeight, token, startDate, setStartDate, endDate, setEndDate} = useContext(AuthContext);
    const message = useMessage();
    const reportRequest = async (type) => {
        let url;
        if(type === 'inactiveUsers') {
            url = `${API}/report/${type}?language=${l.lang}`;
        } else {
            url = `${API}/report/${type}?startDate=${Date.parse(startDate)}&endDate=${Date.parse(endDate) + 86399000}&language=${l.lang}`;
        }
        let blob = await fetch(url, {headers: {'Authorization': 'Bearer ' + token}}).then(r => r.blob());
        let json;
        if(!blob || !blob.type || (json = blob.type === 'application/json')) {
            message(l.reports.errorRequest);
            if(json) {
                blob.text().then((text) => {
                    console.log(text)
                });
            }
            return;
        }
        console.log(blob);
        saveBlob(blob, `${type}._from_${startDate}_to_${endDate}.xlsx`);
    }

    const handleStartDateChange = (date) => {
        setStartDate(date)
    };
    const handleEndDateChange = (date) => {
        setEndDate(date)
    };

    return (footerContainerHeight && footerBarHeight ?
        <div style={{minHeight: "calc(100vh - " + (footerContainerHeight + footerBarHeight + 301) + "px)", padding: "200px 30px 100px 30px", textAlign: 'left'}}>
            <div className="dat-input-box">
                <div className="dateContainer">
                    <DateForm text={l.reports.from} handleDateChange={handleStartDateChange} selectedDate={startDate}/>
                    <DateForm text={l.reports.to} handleDateChange={handleEndDateChange} selectedDate={endDate}/>
                </div>
            </div>
            <hr />
            <p><b>{l.reports.point1.header}</b>{l.reports.point1.body}</p>
            <button className="pagination-btn" onClick={async () => {await reportRequest('newUsers')}}>{l.reports.btnDownload}</button>
            <hr />
            <p><b>{l.reports.point2.header}</b>{l.reports.point2.body}</p>
            <button className="pagination-btn" onClick={async () => {await reportRequest('payments')}}>{l.reports.btnDownload}</button>
            <hr />
            <p><b>{l.reports.point3.header}</b></p>
            <button className="pagination-btn" onClick={async () => {await reportRequest('transactions')}}>{l.reports.btnDownload}</button>
            <hr />
            <p><b>{l.reports.point4.header}</b></p>
            <button className="pagination-btn" onClick={async () => {await reportRequest('consolidated')}}>{l.reports.btnDownload}</button>
            <hr />
            <p><b>{l.reports.point5.header}</b>{l.reports.point5.body}</p>
            <button className="pagination-btn" onClick={async () => {await reportRequest('inactiveUsers')}}>{l.reports.btnDownload}</button>
            <hr />
            <a id={'hiddenLink'} style={{display: 'none'}} href={'/azaza'}>azaza</a>
        </div> : null)

}
