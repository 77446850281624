import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { useRoutes } from './routes';
import { useAuth } from './hooks/auth.hook';
import { AuthContext } from './context/AuthContext';
import { MarkerContext } from './context/MarkerContext';
import { Loaderr } from './components/Loaderr';
import { Nav } from './pages/MainPage/Nav';
import Footer from './pages/Footer';
import {useState, useEffect, useLayoutEffect} from 'react';
import { LanguageContext } from './context/LangContext';
import { EN } from './languages/locale_EN';
import { UA } from './languages/locale_UA';
import { RU } from './languages/locale_RU';
import { UseNetwork } from './hooks/network.hook';
import { useMessage } from './hooks/message.hook';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { API, reCAPTCHA_siteKey } from "./api";
import { useHttp } from './hooks/http.hook';
import { useRef } from 'react';
import { getUrl } from './hooks/getUrl.hook';

console.log('test');

function App() {

    const [footerContainerHeight, setFooterContainerHeight] = useState(null);
    const [footerBarHeight, setFooterBarHeight] = useState(null);
    const toDateInputValue = ((day = 0) => {
        const local = new Date()
        local.setMinutes(local.getMinutes() - local.getTimezoneOffset() - day * 24 * 60)
        return local.toJSON().slice(0, 10)
    })

    const message = useMessage()
    const { isOnline } = UseNetwork()
    const [markers, setMarkers] = useState()
    const [chargeId, setChargeId] = useState()
    const [session, setSession] = useState(false)
    const auth = useAuth(session);
    const { id, token, emailConfirmed, phoneConfirmed, phoneNumber, email, firstName, lastName, markAndModel,
        logout, ready, role } = auth;
    let loginFunction = auth.login;
    const isAuthenticated = !!token
    const routes = useRoutes({ isAuthenticated, id, role })
    const [l, sL] = useState(EN)
    const [online] = useState(isOnline)
    const [close, setClose] = useState(false)
    const [mes, setMes] = useState(null)
    const [organization, setOrganization] = useState(localStorage.getItem("organization"))
    const [organizationId, setOrganizationId] = useState(parseInt(localStorage.getItem("organizationId")))
    const [organizations, setOrganizations] = useState(null);
    const [startDate, setStartDate] = useState(toDateInputValue(7))
    const [endDate, setEndDate] = useState(toDateInputValue())
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const onCheckToken = useRef(true);
    const { request } = useHttp()
    const url = getUrl();
    let footerContainer;
    let footerBar;
    let interval = setInterval(function() {

        footerContainer = document.getElementsByClassName("footer-container")[0];
        footerBar = document.getElementsByClassName("footer-bar")[0];

        if(footerContainer && footerBar) {
            clearInterval(interval);
            setFooterContainerHeight(footerContainer.clientHeight);
            setFooterBarHeight(footerBar.clientHeight);
        }

    }, 100);

    const checkToken = async () => {
        try{
            await request(`${API}${url.money}`, auth.token, 'GET', null);
        }
        catch(e) {

        }
    }

    if(auth.token && onCheckToken.current) {
        onCheckToken.current = false;
        checkToken();
    }

    useLayoutEffect(() => {
        function updateSize() {
            if(footerContainer && footerBar) {
                setFooterContainerHeight(footerContainer.clientHeight);
                setFooterBarHeight(footerBar.clientHeight);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [footerBar, footerContainer]);

    const getUserData = async () => {
        let checkPth = window.location.pathname.includes("activateEmail");
        if(JSON.parse(localStorage.getItem('userData')) && token && !checkPth) {
            let role = localStorage.getItem('role');
            try {
                role = JSON.parse(role).role;
            } catch {
                console.log('role = ', role);
            }
            loginFunction({...JSON.parse(localStorage.getItem('userData')), ...(await request(`${API}/api/get/user/`, token))}, role);
        }
    }

    window.onload = () => {
        getUserData();
    }

    // adds units to statistics
    useEffect(() => {

        let interval = setInterval(() => {
            if(!l) {
                return;
            }
            let reactChart = document.getElementsByClassName("ReactChart ")[0];
            if(!reactChart) {
                return;
            }
            let child = reactChart.children[1].children[0].children[0].children[0].children[1];
            if(!child) {
                return;
            }
            let tbody = child.children[1].children[0];
            let timeTr = tbody.children[0];
            let costTr = tbody.children[1];
            let consumptionTr = tbody.children[2];
            let minTd;
            let uahTd;
            let kvtTd;
            if(timeTr.children.length !== 4 ) {
                minTd = document.createElement('td');
                timeTr.appendChild(minTd);
                uahTd = document.createElement('td');
                costTr.appendChild(uahTd);
                kvtTd = document.createElement('td');
                consumptionTr.appendChild(kvtTd);
            } else {
                minTd = timeTr.children[3];
                uahTd = costTr.children[3];
                kvtTd = consumptionTr.children[3];
            }
            minTd.innerText = l.connectors.min;
            uahTd.innerText = l.points;
            kvtTd.innerText = l.statistics.kvt;
            clearInterval(interval);
        }, 1000);
    }, [l]);

    useEffect(() => {
        if ( mes ) {
            message(mes)
            setMes(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mes])

    useEffect(() => {
        if (!isOnline) {
            setTimeout(() => {
                setClose(true)
            }, 1000);

        } else if (isOnline && isOnline !== online) {
            setMes(l.toast.online)
            window.location.reload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnline, close])

    useEffect(() => {
        if(close) {
            setMes(l.toast.noOnline)
            setTimeout(() => {
                setClose(false)
            }, 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close])

    let langOnSite = localStorage.getItem('lang')

    const langChanger = (event) => {
        if (event === 'ru') {
            sL(RU)
        } else if (event === 'ua') {
            sL(UA)
        } else {
            sL(EN)
            localStorage.setItem('lang', 'en')
        }
    }

    useEffect(() => {
        langChanger(langOnSite);
    }, [langOnSite])

    if (!ready) {
        return (
            <>
                <div className="afterRegBlock"><Loaderr /></div>
            </>)
    }

    return (
        <LanguageContext.Provider value={[l, sL]}>
            <MarkerContext.Provider value={[markers, setMarkers]} >
                <AuthContext.Provider value={{
                    id, token, emailConfirmed, phoneConfirmed, phoneNumber, email, firstName, lastName, markAndModel,
                    login, logout, isAuthenticated, setChargeId, chargeId, setSession, role, isOnline,
                    organization, organizationId, setOrganization, setOrganizationId, organizations, setOrganizations,
                    startDate, setStartDate, endDate, setEndDate, footerContainerHeight, footerBarHeight, password, setLogin, setPassword, loginFunction
                }}>
                    <GoogleReCaptchaProvider
                        reCaptchaKey={reCAPTCHA_siteKey}
                        scriptProps={{
                            async: false, // optional, default to false,
                            defer: false // optional, default to false
                        }}>
                        <Router>
                            <div className="App">
                                <Nav />
                                {routes}
                                <Footer />
                            </div>
                        </Router>
                    </GoogleReCaptchaProvider>
                </AuthContext.Provider>
            </MarkerContext.Provider>
        </LanguageContext.Provider>
    )
}

export default App
